import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { UserInfos } from "../../types";

import { actionSlice } from "./slices/action.slice";
import { ArgumentSlice } from "./slices/arguments.slice";
import { BubbleZoneSlice } from "./slices/bubble-zone.slice";
import { DashboardSlice } from "./slices/dashboard.slice";
import { MessageContactSlice } from "./slices/message-contact.slice";
import { MoodsSLice } from "./slices/moods.slice";
import { NoteAvisSlice } from "./slices/note-avis.slice";
import { ScoresSlice } from "./slices/scores.slice";
import { SubjectsSLice } from "./slices/subjects.slice";

import { AnnoncesSlice } from "./slices/annonces.slice";
import { OffersSlice } from "./slices/offers.slice";
import { TypesSLice } from "./slices/types.slice";
import { TypesEtablissement } from "./slices/typesEtablissement.slice";
import { userSlice } from "./slices/user.slice";
import { UsersSLice } from "./slices/users.slice";

const store = configureStore({
  reducer: {
    [actionSlice.name]: actionSlice.reducer,
    [ScoresSlice.name]: ScoresSlice.reducer,
    [OffersSlice.name]: OffersSlice.reducer,
    [NoteAvisSlice.name]: NoteAvisSlice.reducer,
    [UsersSLice.name]: UsersSLice.reducer,
    [BubbleZoneSlice.name]: BubbleZoneSlice.reducer,
    [AnnoncesSlice.name]: AnnoncesSlice.reducer,
    [DashboardSlice.name]: DashboardSlice.reducer,
    [MessageContactSlice.name]: MessageContactSlice.reducer,
    [TypesSLice.name]: TypesSLice.reducer,
    [TypesEtablissement.name]: TypesEtablissement.reducer,
    [userSlice.name]: userSlice.reducer,
    [MoodsSLice.name]: MoodsSLice.reducer,
    [SubjectsSLice.name]: SubjectsSLice.reducer,
    [ArgumentSlice.name]: ArgumentSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export function useAppSelector<T>(cb: (root: RootState) => T) {
  return useSelector(cb);
}

export function useUser() {
  return useAppSelector((s) => s.auth.userInfos as UserInfos);
}
export default store;
