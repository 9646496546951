import XLSX from "sheetjs-style";

const fileExtension = ".xlsx";

export const exportToExcel = async (
  getFileData: () => unknown[],
  fileName: string
) => {
  var workbook = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(getFileData());
  XLSX.utils.book_append_sheet(workbook, ws, "Results");
  XLSX.writeFile(workbook, fileName + fileExtension, { type: "file" });
};
