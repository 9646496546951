import { IoMdAdd } from "react-icons/io";
import { NavLink } from "react-router-dom";
import EmptyBz from "../../../../assets/appImages/empty-bz.svg";
import "./MessageEmptyBubblezone.css";

function MessageEmptyBubblezone() {
  return (
    <div className="empty-bubble-zone-item bg-white">
      <div className="empty-bubble-zone-img-container mb-4">
        <img
          src={EmptyBz}
          alt="Empty BubbleZone"
          className="empty-bubble-zone-img"
        />
      </div>
      <div className="empty-bubble-zone-mes-empty mb-0">Aucune BubbleZone</div>
      <div className="empty-bubble-zone-cta mb-4">
        Merci d’ajouter une BubbleZone
      </div>
      <div className="content-btn-add-theme">
        <NavLink
          to="/proprietaire/ajouter-bubblezone"
          className="btn custom-btn-add-theme"
        >
          <IoMdAdd />
          <span className="ps-2">Ajouter une BubbleZone</span>
        </NavLink>
      </div>
    </div>
  );
}

export default MessageEmptyBubblezone;
