import React from "react";
import { SubmitButton } from "../../auth/Login";
import CloseModalBtn from "../../shared/CloseModalBtn";
import { Input } from "../../common/Input";
import { UserFormData, UserInfos } from "../../../../types";
import { useFormData } from "../../../utils/form";
import { createUserFunction, updateUserAsync } from "../../../utils/api/user.api";
import Swal from "sweetalert2";
import { ORIENTATION_SEXUELLE, SEXE, SWAL_DEFAULT_PROPS } from "../../../utils/data";
import { FormError } from "../../common/Input";

function AddUserModal({
  item,
  onHide,
}: {
  item?: UserInfos;
  onHide: () => void;
}) {
  const {
    errors,
    setErrors,
    onChange,
    onSubmitForm,
    register,
    registerSelect,
  } = useFormData<UserFormData>(
    {
      email: item?.email ?? "",
      pseudo: item?.pseudo ?? "",
      phone: item?.phone ?? "",
      sexe: item?.sexe ?? undefined,
      age: item?.age ?? "",
      orientation_sexuelle: item?.orientation_sexuelle ?? "",
    },
    (Yup) => ({
      email: Yup.string().email().required().label("Email"),
      pseudo: Yup.string().required().label("Pseudo"),
      sexe: Yup.string().required().label("Sexe"),
      orientation_sexuelle: Yup.string()
        .required()
        .label("Orientation sexuelle"),
      age: Yup.string()
        .required()
        .label("Âge")
        .test(
          "age",
          "L'application est réservée aux personnes majeures",
          (value) => {
            if (value) {
              return parseInt(value, 10) >= 18;
            }
            return true;
          }
        ),
    })
  );
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(
    async (data: UserFormData) => {
      setLoading(true);
      try {
        if (item) {
          await updateUserAsync(item.uid, data);
          onHide();
          setTimeout(() => {
            Swal.fire({
              title: "Succès",
              text: "Informations modifiées avec succès",
              icon: "success",
              ...SWAL_DEFAULT_PROPS,
            });
          }, 400);
        } else {
          type Res = { data: { ok: boolean } } | null;
          let res: { data: { ok: boolean } } | null = null;
          
            res = (await createUserFunction(data)) as Res;
          
          if (res?.data?.ok) {
            onHide();
            setTimeout(() => {
              Swal.fire({
                title: "Succès",
                text: `Utilisateur crée avec succès`,
                icon: "success",
                ...SWAL_DEFAULT_PROPS,
              });
              onChange({
                email: "",
                address: "",
                pseudo: "",
                phone: "",
              });
            }, 400);
          } else {
            if (res?.data?.ok === false) {
              setErrors({ email: "Adresse E-Mail déjà utilisée" });
            } else {
              setErrors({ email: "Une erreur est survenue lors de l'ajout" });
            }
          }
        }
      } catch (ex) {
        setErrors({ email: "Une erreur est survenue lors de l'ajout" });
        console.log("add error", ex);
      }
      setLoading(false);
    },
    [onHide]
  );
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header py-2 flex-m">
          <h5 onClick={() => onHide()} className="admin-modal-title text-start">
            Modifier un utilisateur
          </h5>
          <CloseModalBtn />
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm(onSubmit);
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Pseudo"
                    placeholder="Pseudo"
                    customClassLabel={"input-modal-label-form-add"}
                    customClassInput="input-modal-form-add"
                    {...register("pseudo")}
                    error={errors.pseudo}
                  />
                </div>
                {!item && (
                  <div className="col-md-6">
                    <Input
                      type="email"
                      label="Email"
                      placeholder="Email"
                      customClassLabel={"input-modal-label-form-add"}
                      customClassInput="input-modal-form-add"
                      {...register("email")}
                      error={errors.email}
                    />
                  </div>
                )}

                <div className="col-md-6">
                  <Input
                    type="number"
                    label="Age"
                    placeholder="Age"
                    customClassLabel={"input-modal-label-form-add"}
                    customClassInput="input-modal-form-add"
                    {...register("age")}
                    error={errors.age}
                  />
                </div>
                <div className="col-md-6">
                  <div className={`mb-3 w-100`}>
                    <label
                      htmlFor={"sexe"}
                      className={`form-label form-label-modal-custom input-modal-label-form-add`}
                      aria-labelledby={"sexe"}
                    >
                      Sexe
                    </label>
                   
                    <select
                      className={`form-control form-control-modal-custom input-modal-form-add`}
                      {...registerSelect("sexe")}
                      id={"sexe"}
                    >
                      <option value="">Sexe</option>
                      {SEXE.map((s) => (
                        <option value={s?.title} key={s?.title}>
                          {s?.title}
                        </option>
                      ))}
                    </select>

                    {<FormError error={errors?.sexe} />}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={`mb-3 w-100`}>
                    <label
                      htmlFor={"orientation_sexuelle"}
                      className={`form-label form-label-modal-custom input-modal-label-form-add`}
                      aria-labelledby={"orientation_sexuelle"}
                    >
                      Orientation sexuelle
                    </label>
                    <select
                      className={`form-control form-control-modal-custom input-modal-form-add`}
                      {...registerSelect("orientation_sexuelle")}
                      id={"orientation_sexuelle"}
                    >
                      <option value="">Orientation sexuelle</option>
                      {ORIENTATION_SEXUELLE.map((s) => (
                        <option value={s} key={s}>
                          {s}
                        </option>
                      ))}
                    </select>

                    {<FormError error={errors?.orientation_sexuelle} />}
                  </div>
                </div>
              </div>
              <div className="gap-3 flex-l align-center row mt-5 mb-4">
                <div className="col-md-3">
                  <SubmitButton title="Terminer" loading={loading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUserModal;
