import Modal from "react-bootstrap/Modal";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import mood from "../../../../assets/appImages/mood.svg";
import moodF from "../../../../assets/appImages/mood_feeling.svg";
import location from "../../../../assets/appImages/Location.svg";
import call from "../../../../assets/appImages/Call.svg";
import google from "../../../../assets/appImages/Google.svg";
import instagram from "../../../../assets/appImages/Instagram.svg";
import user from "../../../../assets/appImages/fatou.png";
import chat from "../../../../assets/appImages/Chat.svg";
import home from "../../../../assets/appImages/Home.svg";
import profil from "../../../../assets/appImages/Profile.svg";
import vector from "../../../../assets/appImages/Vector.svg";
import { IoClose } from "react-icons/io5";
import { FaChevronLeft } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";

function PreviewModal(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ backgroundColor: "rgba(0, 0, 0, 0.0)" }}
      dialogClassName="custom-dialog-preview"
      className="preview-modal" backdropClassName={"custom-backdrop-preview"}
    >
      <Modal.Body
        style={{ backgroundColor: "rgba(0, 0, 0, 0.0)" }}
        className="custom-previem-modal-body"
      >
        <DeviceFrameset device="iPhone X" zoom={0.8}>
          <div className="preview-mockup-main-screen">
            <div
              className="preview-mockup-screen-bubblezone-img-container"
              style={{backgroundImage: `url(${props.image})`}}
            >
              <div className="preview-mockup-screen-bubblezone-btn-action-container">
                <button
                  className="btn preview-mockup-screen-bubblezone-btn-previeous"
                  onClick={props.onHide}
                >
                  <FaChevronLeft />
                </button>
                <div className="preview-mockup-screen-bubblezone-mood-container">
                  <img src={mood} alt="mood icon" className="" />
                </div>
              </div>
            </div>
            <div className="position-relative">
              <div className="preview-mockup-screen-bubblezone-titre-container">
                <div className="preview-mockup-screen-bubblezone-titre">
                  Solera Paris : Bar à Cocktails
                </div>
                <div className="preview-mockup-screen-bubblezone-btn-social-container">
                  <div className="preview-mockup-screen-bubblezone-btn-social">
                    <img src={call} alt="icon call" />
                  </div>
                  <div className="preview-mockup-screen-bubblezone-btn-social">
                    <img src={location} alt="icon location" />
                  </div>
                  <div className="preview-mockup-screen-bubblezone-btn-social">
                    <img src={google} alt="icon google" />
                  </div>
                  <div className="preview-mockup-screen-bubblezone-btn-social">
                    <img src={instagram} alt="icon instagram" />
                  </div>
                </div>
              </div>
              <hr className="custom-divider-color" />
              <div className="custom-wrapper">
                <div className="preview-mockup-screen-bubblezone-titre-caracteristique-container">
                  <div className="preview-mockup-screen-bubblezone-titre-caracteristique mb-3">
                    Caractéristiques
                  </div>
                  <div className="custom-badge-caracteristique-container">
                    <span className="badge rounded-pill custom-badge-caracteristique">
                      Vin
                    </span>
                    <span className="badge rounded-pill custom-badge-caracteristique">
                      Shatta
                    </span>
                  </div>
                </div>
                <hr className="custom-divider-color" />
                <div className="preview-mockup-screen-bubblezone-titre-caracteristique-container">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="preview-mockup-screen-bubblezone-titre-caracteristique">
                      Membres
                    </div>
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Search"
                        className="preview-mockup-screen-bubblezone-custom-search"
                      />
                      <FiSearch className="preview-mockup-screen-bubblezone-custom-search-icon" />
                    </div>
                  </div>
                  <div className="row">
                    {[...Array(4)]?.map((el, i) => (
                      <div className="col-6 mb-3" key={i}>
                        <div className="custom-container">
                          <img
                            src={user}
                            alt="Avatar"
                            className="membre-bubblezone-custom-img"
                          />
                          <div className="custom-membre-bubblezone-overlay">
                            <div className="custom-membre-bubblezone-overlay-mood">
                              <img src={moodF} alt="mood icon" />
                            </div>
                            <div className="custom-membre-bubblezone-overlay-name-container">
                              <div className="d-flex gap-2">
                                <div className="custom-membre-bubblezone-overlay-name">
                                  Anne
                                </div>
                                <div className="custom-membre-bubblezone-overlay-online-indicator"></div>
                              </div>
                              <div className="custom-membre-bubblezone-overlay-age">
                                24 ans
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="preview-mockup-screen-bubblezone-footer">
                <button className="btn preview-mockup-screen-bubblezone-footer-btn">
                  <img src={home} alt="" />
                </button>
                <button className="btn preview-mockup-screen-bubblezone-footer-btn">
                  <img src={vector} alt="" />
                </button>
                <button className="btn preview-mockup-screen-bubblezone-footer-btn">
                  <img src={chat} alt="" />
                </button>
                <button className="btn preview-mockup-screen-bubblezone-footer-btn">
                  <img src={profil} alt="" />
                </button>
              </div>
            </div>
          </div>
        </DeviceFrameset>
        {/* <button onClick={props.onHide} className="btn custom-preview-btn-close">
          <IoClose /> Close
        </button> */}
      </Modal.Body>
    </Modal>
  );
}

export default PreviewModal;
