import { BsTrashFill } from "react-icons/bs";
import { RiEdit2Fill } from "react-icons/ri";
import Swal from "sweetalert2";
import { Mood } from "../../../../../types";
import { useAppSelector } from "../../../../redux/root";
import { deleteMoodAsync } from "../../../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS, useModalActions } from "../../../../utils/data";
import AddMoodModal from "../../../modal/admin/AddMoodModal";

function MajModal({ modalId, item }: { modalId: string; item?: Mood }) {
  const ma = useModalActions(modalId);
  return (
    <div
      className="modal fade"
      id={modalId}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <AddMoodModal onHide={ma.hide} item={item} />
    </div>
  );
}

export function MoodItem({ item }: { item: Mood }) {
  const modalId = `AddMoodModal_${item.id}`;
  const onDelete = async () => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce mood ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      ...SWAL_DEFAULT_PROPS,
      preConfirm: async () => {
        await deleteMoodAsync(item.id);
        Swal.fire({
          icon: "success",
          title: "Mood supprimé avec succés",
          showConfirmButton: false,
          timer: 5000,
          ...SWAL_DEFAULT_PROPS,
        });
      },
    });
  };
  return (
    <div className="col-md-6 col-lg-3 mb-3">
      <div className="container-mood-item">
        <div className="flex-r">
          <button
            className="btn with-tooltip btn-action-icon-delete"
            data-tooltip-content="Supprimer"
            style={{ color: "#E53E3E", marginRight: 5 }}
            onClick={onDelete}
          >
            <BsTrashFill />
          </button>
          <button
            className="btn btn-action-icon-edit with-tooltip"
            data-tooltip-content="Modifier"
            data-bs-toggle="modal"
            data-bs-target={`#${modalId}`}
          >
            <RiEdit2Fill />
          </button>
        </div>
        <div className="text-center py-3">
          <div>
            <img src={item.image} alt={item.title} className="img-mood" />
          </div>
          <div>
            <p className="name-mood">
              <span
                className="display-color"
                style={{
                  backgroundColor: item.color ?? "#FFF",
                }}
              />
              {item.title}
            </p>
          </div>
        </div>
      </div>
      <MajModal modalId={modalId} item={item} />
    </div>
  );
}
function Moods() {
  const moods = useAppSelector((s) => s.moods);
  return (
    <div className="mood-item-component">
      <div className="row">
        {moods.map((item) => (
          <MoodItem key={item.id} item={item} />
        ))}
      </div>
      <MajModal modalId="AddMoodModal" />
    </div>
  );
}

export default Moods;
