// /* eslint-disable @typescript-eslint/no-use-before-define */
// import { useEffect, useState } from "react";
// import { BiSearchAlt2 } from "react-icons/bi";
// import { FiPlusSquare } from "react-icons/fi";
// import { UserInfos } from "../../../../../types";
// import { useAppSelector } from "../../../../redux/root";
// import { useModalActions } from "../../../../utils/data";
// import AddAdminModal from "../../../modal/admin/AddAdminModal";
// import ListeGerantV2 from "../../../tables/admin/ListeUsersTable/ListeGerantV2";
// import ListeUsersTableV2 from "../../../tables/admin/ListeUsersTable/ListeUsersTableV2";

// function ListeMembre() {
//   const ma = useModalActions("AddGerantModal");
//   return (
//     <div className="dashboard-admin-component">
//       <div className="container-dashboard-page bg-white p-3">
//         <div className="col-left-dashboard col-left-dashboard-responsive">
//           <div className="admin-page-title-header-container">
//             <ul
//               className="nav nav-pills custom-tab-dash-admin-container"
//               id="pills-tab"
//               role="tablist"
//             >
//               <li className="nav-item nav-item-setting" role="presentation">
//                 <button
//                   className="nav-link custom-tab-dash-admin active"
//                   id="users-tab"
//                   data-bs-toggle="tab"
//                   data-bs-target="#users"
//                   type="button"
//                   role="tab"
//                   aria-controls="users"
//                   aria-selected="false"
//                 >
//                   Utilisateurs
//                 </button>
//               </li>
//               <li className="nav-item nav-item-setting" role="presentation">
//                 <button
//                   className="nav-link custom-tab-dash-admin"
//                   id="gerants-tab"
//                   data-bs-toggle="tab"
//                   data-bs-target="#gerants"
//                   type="button"
//                   role="tab"
//                   aria-controls="gerants"
//                   aria-selected="false"
//                 >
//                   Gérants
//                 </button>
//               </li>
//             </ul>
//           </div>

//           <div className="tab-content" id="myTabContent">
//             <UsersTab />
//             <GerantTab />
//           </div>
//         </div>
//       </div>
//       <div
//         className="modal fade"
//         id="AddGerantModal"
//         aria-labelledby="AddGerantModalLabel"
//         aria-hidden="true"
//       >
//         <AddAdminModal
//           admin={false}
//           onHide={() => {
//             ma.hide();
//           }}
//         />
//       </div>
//     </div>
//   );
// }

// export default ListeMembre;

// function UsersTab() {
//   const [search, setSearch] = useState("");
//   const [users, setUsers] = useState<UserInfos[]>([]);
//   const [filtred, setFiltred] = useState<UserInfos[]>([]);
//   const allUsers = useAppSelector((s) => s.users);

//   useEffect(() => {
//     setUsers(
//       allUsers.filter(
//         (f) => !(f.is_admin || f.is_super_admin || f.is_proprietaire)
//       )
//     );
//   }, [allUsers]);
//   useEffect(() => {
//     const s = search.trim().toLowerCase();
//     if (s.length > 2) {
//       setFiltred(
//         users.filter((f) => {
//           return `${f.pseudo} ${f.phone} ${f.code} ${f.email}`
//             .toLocaleLowerCase()
//             .includes(s);
//         })
//       );
//     } else {
//       setFiltred(users);
//     }
//   }, [users, search]);

//   return (
//     <div
//       className="tab-pane fade show active bg-white p-3"
//       id="users"
//       role="tabpanel"
//       aria-labelledby="users-tab"
//     >
//       <div className="flex-sb mt-4">
//         <div className="custom-proprietaire-filter-input-container">
//           <input
//             type="text"
//             className="form-control custom-proprietaire-filter-input"
//             id="filtreLogement"
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//             placeholder="Recherchez par pseudo, mail ou téléphone "
//           />
//           <span className="custom-proprietaire-filter-input-icon-container">
//             <BiSearchAlt2 className="custom-proprietaire-filter-input-icon" />
//           </span>
//         </div>
//       </div>
//       <ListeUsersTableV2 data={filtred} />
//     </div>
//   );
// }

// function GerantTab() {
//   const [search, setSearch] = useState("");
//   const [users, setUsers] = useState<UserInfos[]>([]);
//   const [filtred, setFiltred] = useState<UserInfos[]>([]);
//   const allUsers = useAppSelector((s) => s.users);

//   useEffect(() => {
//     setUsers(allUsers.filter((f) => f.is_proprietaire));
//   }, [allUsers]);
//   useEffect(() => {
//     const s = search.trim().toLowerCase();
//     if (s.length > 2) {
//       setFiltred(
//         users.filter((f) => {
//           return `${f.pseudo} ${f.phone} ${f.code} ${f.email}`
//             .toLocaleLowerCase()
//             .includes(s);
//         })
//       );
//     } else {
//       setFiltred(users);
//     }
//   }, [users, search]);

//   return (
//     <div
//       className="tab-pane fade  bg-white p-3"
//       id="gerants"
//       role="tabpanel"
//       aria-labelledby="gerants-tab"
//     >
//       <div className="flex-sb mt-4">
//         <div className="custom-proprietaire-filter-input-container">
//           <input
//             type="text"
//             className="form-control custom-proprietaire-filter-input"
//             id="filtreLogement"
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//             placeholder="Recherchez par pseudo, mail ou téléphone "
//           />
//           <span className="custom-proprietaire-filter-input-icon-container">
//             <BiSearchAlt2 className="custom-proprietaire-filter-input-icon" />
//           </span>
//         </div>
//         <div className="content-btn-add-theme flex-r mb-4">
//           <button
//             className="btn btn-add-theme"
//             data-bs-toggle="modal"
//             data-bs-target="#AddGerantModal"
//           >
//             <FiPlusSquare />
//             <span className="ps-2">Ajouter un gérant</span>
//           </button>
//         </div>
//       </div>
//       <ListeGerantV2 data={filtred} />
//     </div>
//   );
// }


/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { FiPlusSquare } from "react-icons/fi";
import { UserInfos } from "../../../../../types";
import { useAppSelector } from "../../../../redux/root";
import { useModalActions } from "../../../../utils/data";
import AddAdminModal from "../../../modal/admin/AddAdminModal";
import ListeGerantV2 from "../../../tables/admin/ListeUsersTable/ListeGerantV2";
import ListeUsersTableV2 from "../../../tables/admin/ListeUsersTable/ListeUsersTableV2";

function ListeMembre() {
  const ma = useModalActions("AddGerantModal");
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="admin-page-title-header-container">
            <ul
              className="nav nav-pills custom-tab-dash-admin-container"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item nav-item-setting" role="presentation">
                <button
                  className="nav-link custom-tab-dash-admin active"
                  id="users-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#users"
                  type="button"
                  role="tab"
                  aria-controls="users"
                  aria-selected="false"
                >
                  Utilisateurs
                </button>
              </li>
              <li className="nav-item nav-item-setting" role="presentation">
                <button
                  className="nav-link custom-tab-dash-admin"
                  id="gerants-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#gerants"
                  type="button"
                  role="tab"
                  aria-controls="gerants"
                  aria-selected="false"
                >
                  Gérants
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content" id="myTabContent">
            <UsersTab />
            <GerantTab />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddGerantModal"
        aria-labelledby="AddGerantModalLabel"
        aria-hidden="true"
      >
        <AddAdminModal
          admin={false}
          onHide={() => {
            ma.hide();
          }}
        />
      </div>
    </div>
  );
}

export default ListeMembre;

function UsersTab() {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState<UserInfos[]>([]);
  const [filtred, setFiltred] = useState<UserInfos[]>([]);
  const allUsers = useAppSelector((s) => s.users);

  useEffect(() => {
    setUsers(
      allUsers.filter(
        (f) => !(f.is_admin || f.is_super_admin || f.is_proprietaire)
      )
    );
  }, [allUsers]);
  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s.length > 2) {
      setFiltred(
        users.filter((f) => {
          return `${f.pseudo} ${f.phone} ${f.code} ${f.email}`
            .toLocaleLowerCase()
            .includes(s);
        })
      );
    } else {
      setFiltred(users);
    }
  }, [users, search]);

  return (
    <div
      className="tab-pane fade show active bg-white p-3"
      id="users"
      role="tabpanel"
      aria-labelledby="users-tab"
    >
      <div className="custom-filter-input-btn-add-container mt-4">
        <div className="custom-proprietaire-filter-input-container">
          <input
            type="text"
            className="form-control custom-proprietaire-filter-input"
            id="filtreLogement"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Recherchez par pseudo, mail ou téléphone "
          />
          <span className="custom-proprietaire-filter-input-icon-container">
            <BiSearchAlt2 className="custom-proprietaire-filter-input-icon" />
          </span>
        </div>
      </div>
      <ListeUsersTableV2 data={filtred} />
    </div>
  );
}

function GerantTab() {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState<UserInfos[]>([]);
  const [filtred, setFiltred] = useState<UserInfos[]>([]);
  const allUsers = useAppSelector((s) => s.users);

  useEffect(() => {
    setUsers(allUsers.filter((f) => f.is_proprietaire));
  }, [allUsers]);
  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s.length > 2) {
      setFiltred(
        users.filter((f) => {
          return `${f.pseudo} ${f.phone} ${f.code} ${f.email}`
            .toLocaleLowerCase()
            .includes(s);
        })
      );
    } else {
      setFiltred(users);
    }
  }, [users, search]);

  return (
    <div
      className="tab-pane fade  bg-white p-3"
      id="gerants"
      role="tabpanel"
      aria-labelledby="gerants-tab"
    >
      <div className="custom-filter-input-btn-add-container mt-4">
        <div className="custom-proprietaire-filter-input-container">
          <input
            type="text"
            className="form-control custom-proprietaire-filter-input"
            id="filtreLogement"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Recherchez par pseudo, mail ou téléphone "
          />
          <span className="custom-proprietaire-filter-input-icon-container">
            <BiSearchAlt2 className="custom-proprietaire-filter-input-icon" />
          </span>
        </div>
        <div className="content-btn-add-theme flex-r mb-4">
          <button
            className="btn btn-add-theme"
            data-bs-toggle="modal"
            data-bs-target="#AddGerantModal"
          >
            <FiPlusSquare />
            <span className="ps-2">Ajouter un gérant</span>
          </button>
        </div>
      </div>
      <ListeGerantV2 data={filtred} />
    </div>
  );
}