import { IoMdAdd } from "react-icons/io";
import AddQuestionModal from "../../../modal/admin/AddQuestionModal";
import ArgumentItem from "./ArgumentItem";
import DiscussionItem from "./DiscussionItem";
import "./Messagerie.css";
import MoodItem from "./MoodItem";
import QuestionItem from "./QuestionItem";

function Messagerie() {
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="admin-page-title-header-container">
          <ul
            className="nav nav-tabs nav-tabs-setting"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link nav-link-setting active"
                id="question-tab"
                data-bs-toggle="tab"
                data-bs-target="#question"
                type="button"
                role="tab"
                aria-controls="question"
                aria-selected="true"
              >
                Questions /réponses
              </button>
            </li>
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link nav-link-setting"
                id="arguments-tab"
                data-bs-toggle="tab"
                data-bs-target="#arguments"
                type="button"
                role="tab"
                aria-controls="arguments"
                aria-selected="false"
              >
                Arguments
              </button>
            </li>
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link nav-link-setting"
                id="sujet-tab"
                data-bs-toggle="tab"
                data-bs-target="#sujet"
                type="button"
                role="tab"
                aria-controls="sujet"
                aria-selected="false"
              >
                Passions
              </button>
            </li>
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link nav-link-setting"
                id="mood-tab"
                data-bs-toggle="tab"
                data-bs-target="#mood"
                type="button"
                role="tab"
                aria-controls="mood"
                aria-selected="false"
              >
                Mood
              </button>
            </li>
          </ul>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="tab-content mt-4" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="question"
              role="tabpanel"
              aria-labelledby="question-tab"
            >
              <div className="">
                <div className="content-btn-add-theme flex-r mb-4">
                  <button
                    className="btn btn-add-theme"
                    data-bs-toggle="modal"
                    data-bs-target="#AddQuestionModal"
                  >
                    <IoMdAdd />
                    <span className="ps-2">Ajouter une question</span>
                  </button>
                </div>
                <QuestionItem />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="arguments"
              role="tabpanel"
              aria-labelledby="arguments-tab"
            >
              <ArgumentItem />
            </div>
            <div
              className="tab-pane fade"
              id="sujet"
              role="tabpanel"
              aria-labelledby="sujet-tab"
            >
              <DiscussionItem />
            </div>
            <div
              className="tab-pane fade"
              id="mood"
              role="tabpanel"
              aria-labelledby="mood-tab"
            >
              <div>
                <div className="content-btn-add-theme flex-r mb-4">
                  <button
                    className="btn btn-add-theme"
                    data-bs-toggle="modal"
                    data-bs-target="#AddMoodModal"
                  >
                    <IoMdAdd />
                    <span className="ps-2">Ajouter un mood</span>
                  </button>
                </div>
                <MoodItem />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddQuestionModal"
        aria-labelledby="AddQuestionModalLabel"
        aria-hidden="true"
      >
        <AddQuestionModal />
      </div>
      <div
        className="modal fade"
        id="AddMoodModal"
        aria-labelledby="AddMoodModalLabel"
        aria-hidden="true"
      >
        {/* <AddMoodModal /> */}
      </div>
    </div>
  );
}

export default Messagerie;
