import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import Logo from "../../../../assets/appImages/logo.png";
import { useUser } from "../../../../redux/root";
import { logoutAsync } from "../../../../utils/api/auth.api";
import { getAvatar } from "../../../../utils/data";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import "./HeaderAdmin.css";

const HeaderAdmin: React.FC = () => {
  const user = useUser();

  const [showNotifications, setShowNotifications] = React.useState(false);

  const onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering-admin row">
          {/* <div className="col-md-4"></div> */}
          <div className="col-8">
            <div className="logo-item">
              <div className="logo-container dashboard-header-logo">
                <span className="navbar-brand">
                  <img src={Logo} alt="logo bubblezone" className="app-logo" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="header-others-items">
              <div className="search-form-container"></div>

              <div className="connected-user-container">
                <div className="connected-user-content ml-auto dis-flex gap-2 no-view-mobile">
                  {user?.is_proprietaire && (
                    <div className="header-pro-custom-pseudo-container">
                      <div className="header-pro-custom-pseudo">
                        {user.pseudo}
                      </div>
                      <div className="header-pro-custom-code">{user.code}</div>
                    </div>
                  )}
                  <div className="dropdown dis-flex">
                    <button
                      className="user-acount-dashboard-header dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="user-acount-dashboard-header-display">
                        <img
                          src={getAvatar(user)}
                          width="45"
                          className="user-avatar"
                          alt="User Avatar"
                        />
                        <FiChevronDown />
                      </div>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-custom"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <NavLink
                        to={
                          user?.is_proprietaire
                            ? "/proprietaire/profil"
                            : "/admin/profil"
                        }
                        className="dropdown-item dropdown-item-header-btn"
                      >
                        {!user?.is_proprietaire ? user.pseudo : "Mon profil"}
                      </NavLink>
                      <div className="dropdown-divider"></div>
                      <button
                        type="button"
                        onClick={async () => {
                          logoutAsync();
                        }}
                        className="dropdown-item dropdown-item-header-btn"
                      >
                        {" "}
                        Deconnexion
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hiddenable-nav-mobile-container no-view-desktop">
          <div className="hiddenable-nav-mobile-content">
            <div className="hiddenable-nav-mobile-content">
              <div className="container-nav-mobile">
                <SidebarMobile />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderAdmin;
