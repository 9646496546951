import React from "react";
import { Subject, Type } from "../../../../../../types";
import { RiEdit2Fill } from "react-icons/ri";
import { FaTrash } from "react-icons/fa";
import { IoAddOutline } from "react-icons/io5";
import { useAppSelector } from "../../../../../redux/root";
import { MajModal, SubjectItem } from "../Hobbies";

function CentreInteretTab() {
  const subjects = useAppSelector((s) => s.subjects);
  return (
    <div className="questions-reponses-component">
      <div className="container-questions-reponses">
        <div className="mb-3 py-3">
          <div className="row">
            {subjects.map((subject) => (
              <SubjectItem item={subject} key={subject.id} />
            ))}
          </div>
          <div className="row mt-3">
            <div className="col-md-3">
              <div className="content-btn-add-response">
                <button
                  className="btn btn-add-response custom-btn-add-response"
                  data-bs-toggle="modal"
                  data-bs-target="#AddArgumentModal"
                >
                  <IoAddOutline className="custom-btn-add-response-icon" />
                  <span className="ps-2">Ajouter</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MajModal modalId="AddArgumentModal" />
    </div>
  );
}

export default CentreInteretTab;

// function SubjectItem({ item }) {
//   return (
//     <div className="col-md-4 mb-3">
//       <div className="reponse-item custom-reponse-item">
//         <div>
//           <p className="text-reponse-item custom-text-reponse-item">
//             {item.title}
//           </p>
//         </div>
//         <div className="d-flex gap-3">
//           <div>
//             <button
//               className="btn btn-action-icon-edit custom-size-btn-action with-tooltip"
//               data-tooltip-content="Modifier"
//               data-bs-toggle="modal"
//               data-bs-target={`#`}
//             >
//               <RiEdit2Fill />
//             </button>
//           </div>
//           <div>
//             <button
//               className="btn with-tooltip btn-action-icon-delete custom-size-btn-action"
//               data-tooltip-content="Supprimer"
//               style={{ color: "#C20000" }}
//             >
//               <FaTrash />
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// const subjects = [
//   {
//     id: 1,
//     title: "Passion 1",
//   },
//   {
//     id: 2,
//     title: "Passion 2",
//   },
//   {
//     id: 3,
//     title: "Passion 3",
//   },
//   {
//     id: 4,
//     title: "Passion 4",
//   },
// ];
