import { getAvatar, MemberInBubble, useGetSubject } from "../../../utils/data";
import CloseModalBtn from "../../shared/CloseModalBtn";

const DetailParticipantModal = ({ item }: { item: MemberInBubble }) => {
  const isPro = item.user.is_proprietaire;
  const getSubject = useGetSubject();
  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header b-b-0 justify-content-end">
          <CloseModalBtn />
        </div>
        <div className="form-container px-3">
          <div className="row pb-2">
            <div className="col-md-12 form-group">
              <div className="d-flex flex-column align-items-center py-4 mb-4 bubble-zone-user-avatar-container">
                <div className="mb-auto profile-image-admin">
                  <div className="profile-avatar-participant-container">
                    <img
                      src={getAvatar(item.user)}
                      alt="avatar"
                      className="img-profil-participant"
                    />
                  </div>
                </div>
                <div className="bubblezone-dash-param-title pt-2">
                  {item.user.pseudo}
                  {isPro
                    ? `, ${item.user.address ?? ""}`
                    : `, ${item.user.age} ans`}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4 mt-4">
            <div className="col-md-12 py-2 b-t-1 b-b-1">
              <div className="flex-sb">
                <div>
                  <p className="info-participant-libelle">Adresse mail</p>
                  <p className="info-participant-value">{item.user.email}</p>
                </div>
                {isPro && (
                  <>
                    <div>
                      <p className="info-participant-libelle">Téléphone</p>
                      <p className="info-participant-value">
                        {item.user.phone}
                      </p>
                    </div>
                    <div>
                      <p className="info-participant-libelle">Raison sociale</p>
                      <p className="info-participant-value">
                        {item.user.raison_social}
                      </p>
                    </div>
                    <div>
                      <p className="info-participant-libelle">
                        Numéro de siret
                      </p>
                      <p className="info-participant-value">
                        {item.user.numero_siret}
                      </p>
                    </div>
                  </>
                )}

                {!isPro && (
                  <>
                    <div>
                      <p className="info-participant-libelle">
                        Orientation Sexuelle
                      </p>
                      <p className="info-participant-value">
                        {item.user.orientation_sexuelle}
                      </p>
                    </div>
                    <div>
                      <p className="info-participant-libelle">Sexe</p>
                      <p className="info-participant-value">{item.user.sexe}</p>
                    </div>
                    <div>
                      <p className="info-participant-libelle">Passions</p>
                      <p className="info-participant-value">
                        {item.user.subjects?.map((it) => {
                          const subject = getSubject(it);
                          if (!subject) return null;
                          return (
                            <small key={subject.id}>
                              #{subject.title}
                              <br />
                            </small>
                          );
                        })}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailParticipantModal;
