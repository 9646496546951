/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from "react";
import * as Yup from "yup";
// import { ObjectShape } from "yup/lib/object";
type Value = string | number;
type FormHandler<T> = {
  name: keyof T;
  value?: Value;
  id: keyof T;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
type FormSelectHandler<T> = {
  name: keyof T;
  value?: Value;
  error?: string;
  id: keyof T;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};
export type UserFormDataReturnValue<T> = {
  data: T;
  onChange: (fieldName: Partial<T> | keyof T, value?: Value) => unknown;
  errors: Partial<T>;
  validate: (data: Partial<T>) => Promise<unknown>;
  register: (name: keyof T, file?: boolean) => FormHandler<T>;
  setErrors: (errors: Partial<T>) => void;
  onSubmitForm: (cb: (data: T) => void | Promise<unknown>) => void;
  registerSelect: (name: keyof T, file?: boolean) => FormSelectHandler<T>;
};
export function useFormData<T>(
  formData: T,
  cbErrors: (yup: typeof Yup) => Yup.ObjectShape = () => ({})
): UserFormDataReturnValue<T> {
  const { errors, validate, setErrors } = useFormValidation<T>({}, cbErrors);
  const [data, setData] = React.useState<T>(formData);
  const onDataChange = React.useCallback(
    (fieldName: Partial<T> | keyof T, value?: Value) => {
      setErrors({});
      if (typeof fieldName === "object") {
        setData((old) => ({ ...old, ...fieldName }));
      } else if (typeof fieldName === "string") {
        setData((old) => {
          const newData = { ...old };
          // @ts-ignore
          newData[fieldName] = value;
          return newData;
        });
      }
    },
    []
  );
  const onSubmitForm = React.useCallback(
    (cb: (data: T) => void | Promise<unknown>) => {
      validate(data)
        .then(() => {
          cb(data);
        })
        .catch(() => null);
    },
    [data]
  );
  const register = React.useCallback(
    (name: keyof T, file?: boolean) => {
      const props: FormHandler<T> = {
        name: name,
        id: name,
        // @ts-ignore
        error: errors[name] as string,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          if (file) {
            if (e.target.files) {
              // @ts-ignore
              onDataChange(name, e.target.files[0]);
            }
          } else {
            onDataChange(name, e.target.value);
          }
        },
      };
      if (!file) {
        // @ts-ignore
        props.value = data[name];
      }
      return props;
    },
    [data, errors]
  );
  const registerSelect = React.useCallback(
    (name: keyof T, file?: boolean) => {
      const props: FormSelectHandler<T> = {
        name: name,
        id: name,
        onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
          onDataChange(name, e.target.value);
        },
      };
      if (!file) {
        // @ts-ignore
        props.value = data[name];
      }
      return props;
    },
    [data]
  );
  return {
    data,
    onChange: onDataChange,
    errors,
    register,
    validate,
    setErrors,
    onSubmitForm,
    registerSelect,
  };
}

export type FormValidationProps<T> = {
  errors: UserFormDataReturnValue<T>["errors"];
  validate: UserFormDataReturnValue<T>["validate"];
  setErrors: UserFormDataReturnValue<T>["setErrors"];
};

export function useFormValidation<T>(
  initalErrors: Partial<T>,
  cbRules: (yup: typeof Yup) => Yup.ObjectShape
): FormValidationProps<T> {
  const [errors, setErrors] = React.useState<Partial<T>>(initalErrors);
  const Schema = Yup.object().shape(cbRules(Yup));
  const validate = React.useCallback(
    (data: Partial<T>) =>
      new Promise((resolve, reject) => {
        setErrors({});
        Schema.validate(data, { abortEarly: false })
          .then(() => {
            resolve(data);
          })
          .catch((err: Yup.ValidationError) => {
            const newErrors: Partial<T> = {};
            for (const e of err.inner) {
              if (e.path) {
                const [error] = e.errors;
                // @ts-ignore
                newErrors[e.path] = error;
              }
            }
            setErrors(newErrors);
            reject(newErrors);
          });
      }),
    []
  );
  return { errors, validate, setErrors };
}
