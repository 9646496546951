/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from "moment";
import MoreCircle1 from "../../../../../assets/appImages/MoreCircle1.png";
import MoreCircle2 from "../../../../../assets/appImages/MoreCircle2.png";
import Profile1 from "../../../../../assets/appImages/Profile1.png";
import Profile2 from "../../../../../assets/appImages/Profile2.png";
import Wallet1 from "../../../../../assets/appImages/Wallet1.png";
import Wallet2 from "../../../../../assets/appImages/Wallet2.png";
import { useAppSelector } from "../../../../../redux/root";
import {
  AdminDisplay,
  DashboardAdmin,
  Scores,
  useDashboardAdminB2B,
  useGroupedAges,
  useGroupedSubject,
  useLatestCis,
  useMembersAdmin,
  useScores,
} from "../../../../../utils/data";
import { GenericGraphe } from "../../../Owner/ListeBubbleZone/GraphProprietaire";
import BubbleMap from "../../../Proprietaire/BubbleZoneOwner/BubbleMap";
import { CardSkeleton, PieSkeleton } from "../DashboardAdminV2";
function StatistiqueGeneralesV2({
  isLoading,
  data,
  type,
  date,
}: {
  isLoading: boolean;
  data: DashboardAdmin;
  type: AdminDisplay;
  date?: string;
}) {
  return (
    <div className="bubblezone-tab-statistique-generales-container">
      <div className="bubblezone-dash-admin mt-3">
        <ul
          className="nav nav-pills custom-tab-dash-admin-container mb-4"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link custom-tab-dash-admin active"
              id="pills-b2c-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-b2c"
              type="button"
              role="tab"
              aria-controls="pills-b2c"
              aria-selected="true"
            >
              B2C
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link custom-tab-dash-admin"
              id="pills-b2b-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-b2b"
              type="button"
              role="tab"
              aria-controls="pills-b2b"
              aria-selected="false"
            >
              B2B
            </button>
          </li>
        </ul>

        <div
          className="tab-content bubblezone-dash-tab-content"
          id="pills-tabContent"
        >
          <div
            className="tab-pane fade show active"
            id="pills-b2c"
            role="tabpanel"
            aria-labelledby="pills-b2c-tab"
          >
            <B2CComponent
              type={type}
              date={date}
              data={data}
              isLoading={isLoading}
            />
          </div>
          <div
            className="tab-pane fade"
            id="pills-b2b"
            role="tabpanel"
            aria-labelledby="pills-b2b-tab"
          >
            <B2BComponent type={type} date={date} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatistiqueGeneralesV2;
const B2CComponent = ({
  isLoading,
  data,
  type,
  date,
}: {
  isLoading: boolean;
  data: DashboardAdmin;
  type: AdminDisplay;
  date?: string;
}) => {
  const { connexions, users, avgMoods } = useMembersAdmin(
    type,
    date ? moment(date) : undefined
  );

  const bubbleZones = useAppSelector((s) => s.bubble_zone);

  const grounpedAges = useGroupedAges(users);
  const groupedCis = useGroupedSubject(users);
  const latestCis = useLatestCis(users);

  return (
    <>
      <section className="card-statistique-section mb-3">
        <div className="row">
          {!!isLoading && <CardSkeleton />}
          {!isLoading && (
            <>
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="custom-item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Nbre de téléchargements{" "}
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data.downloads}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Profile1}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="custom-item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Nbre de BubbleZone
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.bubbleZones}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Wallet1}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="custom-item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Nbre de messages
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.messages}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={MoreCircle1}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="custom-item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Nbre de comptes pro
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data.pro}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Profile2}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="custom-item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Nbre de compte supprimes
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.deleteds}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Wallet2}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="custom-item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Nbre d’eyes contact
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data.eyesContact}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={MoreCircle2}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <section className="graph-section mb-3">
        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="proprietaire-detail-bubblezone-card-graph-container">
              <div className="proprietaire-detail-bubblezone-card-graph-title mb-2">
                Caractéristiques
              </div>

              <div className="">
                {isLoading ? (
                  <PieSkeleton />
                ) : (
                  <GenericGraphe
                    renderLabels={(data) => {
                      return (
                        <div className="container-legend-mood-graph mt-4">
                          {data.map((item) => (
                            <div
                              key={item.title}
                              className="content-mood-detail-dashboard"
                            >
                              <img
                                src={item.image}
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderBottom: `3px solid ${item.color}`,
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      );
                    }}
                    data={avgMoods.map((c) => ({
                      title: c.mood.title,
                      color: c.mood.color,
                      image: c.mood.image,
                      count: c.count,
                    }))}
                    Width={200}
                    Heigth={200}
                    customClass="custom-graph-container"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="proprietaire-detail-bubblezone-card-graph-container">
              <div className="proprietaire-detail-bubblezone-card-graph-title mb-2">
                Tranche d’âge
              </div>

              <div className="">
                {isLoading ? (
                  <PieSkeleton />
                ) : (
                  <GenericGraphe
                    renderLabels={(data) => {
                      return (
                        <div className="container-legend-mood-graph mt-4">
                          {data.map((item) => (
                            <div
                              key={item.title}
                              className="content-mood-detail-dashboard"
                            >
                              <p
                                className="pourcentage-mood-detail-dashboard mb-0 pb-2"
                                style={{
                                  borderBottom: `3px solid ${item?.color}`,
                                }}
                              >
                                {item?.title}
                              </p>
                            </div>
                          ))}
                        </div>
                      );
                    }}
                    data={grounpedAges.map((c) => ({
                      title: c?.title,
                      color: c?.color,
                      image: "",
                      count: c.count,
                    }))}
                    Width={200}
                    Heigth={200}
                    customClass="custom-graph-container"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="proprietaire-detail-bubblezone-card-graph-container">
              <div className="proprietaire-detail-bubblezone-card-graph-title mb-2">
                Passions
              </div>

              <div className="">
                {isLoading ? (
                  <PieSkeleton />
                ) : (
                  <GenericGraphe
                    renderLabels={(data) => {
                      return (
                        <div className="container-legend-mood-graph mt-4">
                          {data.map((item) => (
                            <div
                              key={item.title}
                              className="content-mood-detail-dashboard"
                            >
                              <p
                                className="pourcentage-mood-detail-dashboard mb-0 pb-2"
                                style={{
                                  borderBottom: `3px solid ${item.color}`,
                                }}
                              >
                                {item.title}
                              </p>
                            </div>
                          ))}
                        </div>
                      );
                    }}
                    data={groupedCis.map((c) => ({
                      title: c.ci.title,
                      color: c.ci.color,
                      image: "",
                      count: c.count,
                    }))}
                    Width={200}
                    Heigth={200}
                    customClass="custom-graph-container"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-9 mb-3">
            <div className="nombre-connexion-graph-container mb-3">
              {/* <div className="my-2">
                <h4 className="bubble-zone-dash-header-titre custom-bubble-zone-dash-header-titre linkable">
                  Nombre de connexion
                </h4>
              </div>
              <div className="connexion-chart-container">
                <ConnexionChartProprietaire data={connexions} />
              </div> */}
              <BubbleMap data={bubbleZones} mapContainerClassName="bubble-map-container-admin" />
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="meilleure-score-container">
              <div className="my-2">
                <h4 className="bubble-zone-dash-header-titre custom-bubble-zone-dash-header-titre linkable">
                  Lastest passions
                </h4>
              </div>
              <div className="mt-4">
                {latestCis.map((item, index) => (
                  <div
                    className="custom-centre-iteret-item-container mb-3"
                    key={item.ci.id}
                  >
                    <div className="custom-centre-iteret-item-number">
                      {index + 1} <br /> -
                    </div>
                    <div className="custom-centre-iteret-item">
                      {item.ci.title}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const B2BComponent = ({
  isLoading,
  type,
  date,
}: {
  isLoading: boolean;
  type: AdminDisplay;
  date?: string;
}) => {
  const data = useDashboardAdminB2B();
  const scores = useScores();
  return (
    <>
      <div className="row mb-4">
        <div className="col-lg-8 col-content-b2b-component mb-3">
          <div className="content-col-left-b2b-component">
            <div className="row">
              <div className="col-md-6 mb-3 dis-flex px-md-2">
                <div className="custom-item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Nbre de gérants Basic
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data.basic}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Profile1}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3 dis-flex px-md-2">
                <div className="custom-item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Nbre de gérants Pro
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data.pro}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Wallet2}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3 dis-flex px-md-2">
                <div className="custom-item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Nbre de gérants Pro
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data.pre}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={MoreCircle2}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3 dis-flex px-md-2">
                <div className="custom-item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Nbre de gérants total
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data.total}
                        </p>
                      </div>
                    </div>
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Profile2}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DisplayScores data={scores} />
      </div>
      <DisplaySingleScores data={scores} />
    </>
  );
};

export function DisplayScores({
  data,
  showns,
}: {
  data: Scores;
  showns?: string[];
}) {
  return (
    <div className="col-lg-4 col-right-b2b-component mb-3">
      <div className="content-col-right-b2b-component">
        <div className="classement-bubblezone-card-graph-title text-center mb-2">
          CLASSEMENT
        </div>
        <p
          className="text-sub-title-bubblezone-card-graph text-center"
          style={{ textTransform: "uppercase" }}
        >
          {moment().subtract(1, "months").format("MMMM YYYY")}
        </p>
        <div className="content-col-right-b2b-component-wrapped">
          <div className="container-list-classement-bubblezone">
            {data.scores.map((i, index) => {
              const show = showns?.includes(i.id);
              if (index > 4 && !show) {
                return null;
              }
              return (
                <div
                  className={`classement-bubblezone-item ${
                    show && "classement-bubblezone-item-owner"
                  }`}
                  key={i.id}
                >
                  <p
                    className={`content-text-classement-bubblezone-item mb-0 ${
                      show && "content-text-classement-bubblezone-item-owner"
                    }`}
                  >
                    <span style={{ color: "#DB3251", paddingRight: 7 }}>
                      {index + 1}
                    </span>
                    <span>
                      {i.name} {show ? "(vous)" : ""}
                    </span>
                  </p>
                  <div className="content-img-list-classement-bubblezone-item">
                    <img
                      src={
                        i.image || `https://ui-avatars.com/api/?name=${i.name}`
                      }
                      alt="Classement"
                      className="img-list-classement-bubblezone-item"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export function DisplaySingleScores({ data }: { data: Scores }) {
  const list = [
    {
      title: "Ambiance",
      item: data.ambiance,
    },
    {
      title: "Prix",
      item: data.prix,
    },
    {
      title: "Service",
      item: data.service,
    },
    {
      title: "Clientèle",
      item: data.clientele,
    },
  ];

  console.log("data", data);

  return (
    <div className="nombre-connexion-graph-container mb-3">
      <div className="row">
        {list.map((i) =>
          !!i.item ? (
            <div
              className="col-lg-6 col-classement-list-section2 mb-4"
              key={i.title}
            >
              <div className="content-col-classement-list-section2 w-100">
                <h4 className="title-classement-list-section2">
                  Meilleure BubbleZone : {i.title}
                </h4>
                <div className="classement-bubblezone-item mb-3">
                  {!!i.item && (
                    <>
                      <p className="content-text-classement-bubblezone-item mb-0">
                        {i.item.name}
                      </p>
                      <div className="content-img-list-classement-bubblezone-item">
                        <img
                          src={
                            i.item.image ||
                            `https://ui-avatars.com/api/?name=${i.item.name}`
                          }
                          alt="Classement"
                          className="img-list-classement-bubblezone-item"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
}
