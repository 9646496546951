import React, { useState } from "react";
import Swal from "sweetalert2";
import { createBubbleZoneAsync } from "../../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS, locationFromAdresse } from "../../../utils/data";
import { useFormData } from "../../../utils/form";
import { FormError, SubmitButton } from "../../auth/Login";
import CloseModalBtn from "../../shared/CloseModalBtn";
import "./AdminModal.css";

type Form = { file?: File };

const ImportExcelModal = ({ onHide }: { onHide: () => void }) => {
  const [message, setMessage] = useState("");
  const { errors, setErrors, onChange, onSubmitForm, register } =
    useFormData<Form>({}, (Yup) => ({
      file: Yup.mixed()
        .label("Fichier")
        .test(
          "is-image",
          "Veuillez télécharger un fichier excel (XLSX)",
          (value) => {
            if (!value) return false;
            if (!value || !(value instanceof File)) return false;
            return [
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
            ].includes(value.type.toLowerCase());
          }
        ),
    }));
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(
    async (data: Form) => {
      setLoading(true);
      try {
        setMessage("Import du fichier en cours...");
        console.log("file", data.file);
        const form = new FormData();
        if (data.file) {
          form.append("file", data.file);
        }
        const res = await fetch(
          "https://us-central1-bubblezone-c8cf5.cloudfunctions.net/widgets/import-excel",
          {
            body: form,
            method: "POST",
          }
        ).then((res) => res.json());
        if (res.message) {
          setErrors({ file: res.message });
          setMessage("");
        } else if (res.data) {
          const { data, exists } = res;
          let message = `${data.length} nouvelles BubbleZone trouvée<br/>Localisation en cours...`;
          if (exists.length > 0) {
            message += `<br /><br />${exists.length} BubbleZone existantes trouvées et ignorées`;
          }
          setMessage(message);
          for (let item of data) {
            const loc = await locationFromAdresse(item.address);
            if (loc) {
              const { lat, lng } = loc;
              item.latitude = lat;
              item.longitude = lng;
            } else {
              item.latitude = 0;
              item.longitude = 0;
            }
            item.image = `https://ui-avatars.com/api/?name=${item.name}`;
          }
          for (let item of data) {
            setMessage(`Creation de la BubbleZone ${item.name}`);
            await createBubbleZoneAsync(item);
          }
          Swal.fire({
            title: "Succès",
            text: `${data.length} BubbleZone ajoutée avec succès`,
            icon: "success",
            ...SWAL_DEFAULT_PROPS,
          }).then(() => {
            onChange({
              file: undefined,
            });
            const input = document.getElementById(
              "export-file"
            ) as HTMLInputElement;
            if (input) {
              input.value = "";
            }
            onHide();
            setMessage("");
          });
        }
      } catch (ex) {
        // @ts-ignore
        setErrors({ file: "Une erreur est survenue lors de l'ajout" });
        console.log("add error", ex);
      }
      setLoading(false);
    },
    [onHide]
  );
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header py-2 flex-m">
          <h5 className="admin-modal-title text-start">
            Importer un fichier excel
          </h5>
          <CloseModalBtn />
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitForm(onSubmit);
            }}
          >
            <div className="container-form">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="export-file"
                      className="form-label form-label-modal-custom"
                    >
                      Fichier
                    </label>
                    <input
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                      className="form-control form-control-modal-custom"
                      {...register("file", true)}
                      id="export-file"
                    />
                    <FormError error={String(errors.file || "")} />
                  </div>
                </div>
              </div>
              {!!message && (
                <div className="col-md-12 mb-3">
                  <div
                    className="mb-3"
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                </div>
              )}
              <div className="gap-3 flex-r align-center row mt-4">
                <div className="col-md-3">
                  <button
                    type="button"
                    className="btn btn-cancelled"
                    onClick={onHide}
                  >
                    Fermer
                  </button>
                </div>
                <div className="col-md-3">
                  <SubmitButton title="Importer" loading={loading} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ImportExcelModal;
