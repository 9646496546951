import React, { useState } from "react";
import ReactQuill from "react-quill";

const AjouterCgu = () => {
  const [value, setValue] = useState("");
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="bg-white p-3">
            <div className="container-form">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder="Termes et conditions"
                        className="form-control form-control-modal-custom"
                        id="titre"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="mb-3">
                      <ReactQuill
                        theme="snow"
                        value={value}
                        onChange={setValue}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-btn-modal row flex-r mt-4">
                  <div className="col-md-3">
                    <button className="btn btn-cancelled w-100">Annuler</button>
                  </div>
                  <div className="col-md-3">
                    <button className="btn auth-submit-btn">Envoyer</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterCgu;
