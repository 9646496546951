import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Offer } from "../../../types";

export type State = Offer[];

const initialState: State = [];

export const OffersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    onOffers: (state, action: PayloadAction<State>) => {
      let newState = [...state];
      for (const i of action.payload) {
        const index = newState.findIndex((o) => o.id === i.id);
        if (index === -1) {
          newState = [i, ...newState];
        } else {
          newState[index] = i;
        }
      }

      return newState;
    },
  },
});

export const { onOffers } = OffersSlice.actions;

export default OffersSlice.reducer;
