import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import { NavbarData, NavbarDataAdmin } from "./SidebarAdminContent";
import { useAppSelector, useUser } from "../../../../redux/root";
import { logoutAsync } from "../../../../utils/api/auth.api";

const SidebarMobile = () => {
  const user = useUser();
  const count = useAppSelector(
    (s) =>
      s.messages_contact.filter((f) => !f.status || f.status === "Non traité")
        .length
  );
  const isAdmin = user.is_admin || user.is_super_admin;
  return (
    <div>
      <div className="container-nav">
        <input type="checkbox" id="check" />
        <label htmlFor="check">
          <AiOutlineMenuUnfold />
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className="sidebar" id="scrollbar-nav-mobile">
          <div className="content-nav-mobile">
            <ul className="nav nav-items-custom flex-column">
              {isAdmin &&
                NavbarDataAdmin.map((navbar, i) => (
                  <li className={navbar.classLi} key={i}>
                    <NavLink className={navbar.classNav} to={navbar.link}>
                      {navbar.icon}
                      {navbar.link === "/admin/message-contact" ? (
                        <span className="hiddenable-navlink-label">
                          {navbar.title}{" "}
                          <span className="badge badge-primary">{count}</span>
                        </span>
                      ) : (
                        <span className="hiddenable-navlink-label">
                          {navbar.title}
                        </span>
                      )}
                    </NavLink>
                  </li>
                ))}

              {/* Routes propriétaires  */}
              {!isAdmin &&
                NavbarData.map((navbar, i) => (
                  <li className={navbar.classLi} key={i}>
                    <NavLink className={navbar.classNav} to={navbar.link}>
                      {navbar.icon}
                      <span className="hiddenable-navlink-label">
                        {navbar.title}
                      </span>
                    </NavLink>
                  </li>
                ))
                }
              <li className="nav-item nav-item-vertical-custom">
                <NavLink 
                  to={!isAdmin? "/proprietaire/profil" : "/admin/profil"}
                  className="btn nav-link nav-link-vertival-custom-admin"
                >
                  <IoLogOutOutline />
                  <span className="item-nav-menu drop-header">Mon Profil</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom pt-5">
                <button 
                  className="btn nav-link nav-link-vertival-custom-admin sidebar-logout-btn"
                  onClick={async () => {
                    logoutAsync();
                  }}
                >
                  <IoLogOutOutline />
                  <span className="item-nav-menu drop-header">Déconnexion</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
