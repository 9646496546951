import React from 'react'
import { useModalActions } from '../../../../../utils/data';
import AdminTable from '../Tables/AdminTable';
import AddAdminModal from '../../../../modal/admin/AddAdminModal';

function AdministrateurTab({ modalId }) {
    const ma = useModalActions(modalId);
    return (
      <div className="mood-item-component">
        <div className="row">
          <div className="col-md-12">
            <AdminTable />
          </div>
        </div>
        <div
          className="modal fade"
          id="AddAdminModal"
          aria-labelledby="AddAdminModalLabel"
          aria-hidden="true"
        >
          <AddAdminModal
            onHide={() => {
              ma.hide();
            }}
          />
        </div>
      </div>
    );
}

export default AdministrateurTab