/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef } from "react";
import { IoCalendar } from "react-icons/io5";
import Skeleton from "react-loading-skeleton";
import "./DashboardAdmin.css";

import "react-datepicker/dist/react-datepicker.css";

export const CustomInput = forwardRef<any>(({ onClick }: any, ref) => (
  <button className="example-custom-input" onClick={onClick} ref={ref}>
    <IoCalendar
      className="proprietaire-detail-bubblezone-btn-filter-icon"
      // onClick={onClick}
      // ref={ref}
    />
  </button>
));

function DashboardAdminV2() {
  return null;
}

export default DashboardAdminV2;

export const CardSkeleton = () => {
  return (
    <>
      {[...Array(6)]?.map((el, i) => (
        <div className="col-md-4 mb-3 dis-flex px-md-2" key={i}>
          <div className="custom-item-stat-dashboad-view">
            <div className="content-stat-dashboard-view">
              <div className="content-text-item-stat-dashboad-view ms-3">
                <div className="content-item-stat-dashboad-view mt-2">
                  <Skeleton
                    className="titre-item-stat-dashboad-view mb-1"
                    width={200}
                  />
                </div>
                <div className="icon-item-stat-dashboad-view">
                  <Skeleton
                    className="chiffre-item-stat-dashboad-view mb-1"
                    width={150}
                  />
                </div>
              </div>
              <div className="content-item-icon-stat-dashboard-view">
                <Skeleton
                  className="icon-item-stat-dashboad-view"
                  width={65}
                  height={65}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export const PieSkeleton = () => {
  return (
    <div className="custom-graph-container text-center">
      <Skeleton width={200} height={200} circle />
    </div>
  );
};

export const PassionSkeleton = () => {
  return (
    <>
      {[...Array(6)]?.map((el, i) => (
        <div className="custom-centre-iteret-item-container mb-3" key={i}>
          <Skeleton
            className="custom-centre-iteret-item-number"
            width={20}
            height={30}
          />
          <Skeleton className="custom-centre-iteret-item" width={200} />
        </div>
      ))}
    </>
  );
};
