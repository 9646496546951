import { useAppSelector } from "../../../../../redux/root";
import OffreTable from "../Tables/OffreTable";

function Offretab() {
  const offers = useAppSelector((s) => s.offers);
  return (
    <div className="mood-item-component">
      <div className="row">
        <div className="col-md-12">
          <OffreTable data={offers} />
        </div>
      </div>
    </div>
  );
}

export default Offretab;
