import React from "react";
import Modal from "react-bootstrap/Modal";
import indicator from "../../../assets/icons/indicator.svg";
import { NavLink } from "react-router-dom";
import { IoDiamondSharp } from "react-icons/io5";
const UpgradeVersionModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered className="upgrade-modal" backdropClassName={"custom-backdrop-update"}>
      <div className="upgrade-version-modal-container">
        <div className="pt-4 pb-3">
          <img src={indicator} alt="icon indicator" className="" />
        </div>
        <div className="upgrade-version-modal-title mb-3">
          Passer à la version supérieure pour accéder à vos données clients
        </div>
        <NavLink
          className="nav-link nav-link-vertival-custom custom-nav-item-vertical-custom with-tooltip-menu w-100 modal-upgrade"
          to="/proprietaire/version-pro"
        >
          <IoDiamondSharp />
          <span className="hiddenable-navlink-label">{"Version upgrade"}</span>
        </NavLink>
      </div>
    </Modal>
  );
};

export default UpgradeVersionModal;
