import RSInstagramTab from "./RSInstagramTab";

function ReseauxSociauxTab() {
  return (
    <>
      <div className="admin-page-title-header-container mb-4">
        <ul
          className="nav nav-pills custom-tab-dash-admin-container gap-1"
          id="rsTab"
          role="tablist"
        >
          <li className="nav-item nav-item-setting" role="presentation">
            <button
              className="nav-link custom-tab-dash-admin-rs active"
              id="instagramme-tab"
              data-bs-toggle="tab"
              data-bs-target="#instagramme"
              type="button"
              role="tab"
              aria-controls="instagramme"
              aria-selected="false"
            >
              Instagram/Snap/TikTok
            </button>
          </li>
          <li className="nav-item nav-item-setting" role="presentation">
            <button
              className="nav-link custom-tab-dash-admin-rs"
              id="facebook-tab"
              data-bs-toggle="tab"
              data-bs-target="#facebook"
              type="button"
              role="tab"
              aria-controls="facebook"
              aria-selected="false"
            >
              Facebook
            </button>
          </li>
        </ul>
      </div>
      <div className="tab-content" id="rsTabContent">
        <div
          className="tab-pane fade show active bg-white p-3"
          id="instagramme"
          role="tabpanel"
          aria-labelledby="instagramme-tab"
        >
          <RSInstagramTab type="other" />
        </div>

        <div
          className="tab-pane fade  bg-white p-3"
          id="facebook"
          role="tabpanel"
          aria-labelledby="facebook-tab"
        >
          <div>
            <RSInstagramTab type="facebook" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ReseauxSociauxTab;
