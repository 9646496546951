import React, { useState } from "react";
import { FiMail } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Logo from "../../assets/appImages/logo.png";
import { sendPasswordResetLinkAsync } from "../../utils/api/auth.api";
import { SWAL_DEFAULT_PROPS } from "../../utils/data";
import { useFormData } from "../../utils/form";
import { FormError, SubmitButton } from "./Login";
type ForgotPasswordData = {
  email: string;
};
const ForgetPassword = () => {
  const navigate = useNavigate();
  const { register, errors, setErrors, onSubmitForm } =
    useFormData<ForgotPasswordData>(
      {
        email: "",
      },
      (Yup) => ({
        email: Yup.string().email().required().label("Email"),
      })
    );
  const [loading, setLoading] = useState(false);

  const onSubmit = React.useCallback(async (data: ForgotPasswordData) => {
    setLoading(true);
    try {
      await sendPasswordResetLinkAsync(data.email);
      Swal.fire({
        title: "Succès",
        text: "Un lien pour réinitialiser votre mot de passe a été envoyé avec succès, veuillez vérifier votre boîte de réception",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
      navigate("/");
    } catch (ex) {
      setErrors({
        email: "L'utilisateur avec cet e-mail n'a pas été trouvé",
      });
    }
    setLoading(false);
  }, []);
  return (
    <div>
      <div className="">
        <div className="row login-page-row">
          <div className="col-md-6 offset-md-3 right-side-col">
            <div className="login-form-container">
              <div className="login-form-introduction d-flex flex-column align-items-center">
                <div className="mb-4">
                  <img src={Logo} alt="logo" className="logo-auth-login-img" />
                </div>
                <h1 className="auth-form-title">
                  Récupération du Mot de Passe
                </h1>
                <div className="p-4">
                  <h4 className="auth-form-message">
                    Une demande de réinitialisation de mot de passe va être
                    envoyé par e-mail
                  </h4>
                </div>
              </div>
              <div className="mt-4">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmitForm(onSubmit);
                  }}
                  id="auth-form"
                >
                  <div className="row auth-form-row">
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="input-group auth-form-group auth-form-group-login">
                        <span
                          className="input-group-text-custom"
                          id="basic-addon1"
                        >
                          <FiMail />
                        </span>
                        <input
                          type={`email`}
                          className="form-control auth-form-control"
                          {...register("email")}
                          placeholder="Identifiant"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                    <FormError error={errors.email} />
                    <div className="col-md-6 offset-md-3 auth-submit-btn-container mt-5">
                      <SubmitButton
                        title="Reinitialiser le mot de passe"
                        loading={loading}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
