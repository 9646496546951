import React from "react";
import Feeling from "../../../../../assets/appImages/Feeling.png";
import Date from "../../../../../assets/appImages/Date.png";
import Amitie from "../../../../../assets/appImages/Amitie.png";
import NePasderanger from "../../../../../assets/appImages/NePasderanger.png";
import AddMoodModal from "../../../../modal/admin/AddMoodModal";
import { useModalActions } from "../../../../../utils/data";
import { MoodItem } from "../../Messagerie/MoodItem";
import { useAppSelector } from "../../../../../redux/root";

function MajModal({ modalId, item }: { modalId: string; item? }) {
    const ma = useModalActions(modalId);
    return (
      <div
        className="modal fade"
        id={modalId}
        aria-labelledby={`${modalId}Label`}
        aria-hidden="true"
      >
        <AddMoodModal onHide={ma.hide} item={item} />
      </div>
    );
  }

// function MoodItem({ item }) {
//   const modalId = `AddMoodModal_${item.id}`;
//   return (
//     <div className="col-md-6 col-lg-3 mb-3">
//       <div className="container-mood-item">
//         <div className="flex-r">
//           <button
//             className="btn btn-action-icon-edit custom-size-btn-action with-tooltip"
//             data-tooltip-content="Modifier"
//             data-bs-toggle="modal"
//             data-bs-target={`#${modalId}`}
//           >
//             <RiEdit2Fill />
//           </button>
//         </div>
//         <div className="text-center py-3">
//           <div className="mb-2">
//             <img src={item.image} alt={item.title} className="img-mood" />
//           </div>
//           <div>
//             <div className="pourcentage-mood mb-4">
//               {item.pourcentage + "%"}
//             </div>
//             <p className="name-mood">{item.title}</p>
//           </div>
//         </div>
//       </div>
//       <MajModal modalId={modalId} item={item} />
//     </div>
//   );
// }
function MoodTab() {
  const moods = useAppSelector((s) => s.moods);
  return (
    <div className="mood-item-component">
      <div className="row">
        {moods.map((item) => (
          <MoodItem key={item.id} item={item} />
        ))}
      </div>
      <MajModal modalId="AddMoodModal" />
    </div>
  );
}

export default MoodTab;

const moods = [
  {
    id: 1,
    image: Feeling,
    title: "Felling",
    pourcentage: 18,
  },
  {
    id: 2,
    image: Date,
    title: "Date",
    pourcentage: 24,
  },
  {
    id: 3,
    image: Amitie,
    title: "Amitié",
    pourcentage: 36,
  },
  {
    id: 4,
    image: NePasderanger,
    title: "Ne pas déranger",
    pourcentage: 22,
  },
];
