import React from 'react'
import { BtnSubmit } from '../../../common/Button'
import { useNavigate } from 'react-router-dom'

function MessageFelicitationOwner() {
    let navigate = useNavigate()
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
       
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="">
            <div className="flex-sb align-items-center mb-4">
              <div className="tab-dash-admin-filter-container w-65 m-auto">
                <div className='bubblezone-message-felicitation-title my-5'>Merci !</div>
                <div className='bubblezone-message-felicitation-message mb-3'>Félicitations, votre paiement a bien été effectué et vous êtes désormais membre de BubbleZone Pro </div>
                <div className="flex-c pt-5">
                    <BtnSubmit label="Retourner sur  l’application" isLoading={false} onClick={() => navigate(-1)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageFelicitationOwner