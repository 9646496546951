import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AjouterBubblezoneOwner from "../../Proprietaire/BubbleZoneOwner/AjouterBubblezoneOwner";

export default () => {
  return <AjouterBubblezoneOwner />;
};

export function UpdateBubbleZone() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location.state) {
      navigate("/admin/bubblezone");
    }
  }, [location.state]);
  if (!location.state) return null;
  return <AjouterBubblezoneOwner item={location.state} />;
}
