/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Annonce from "../components/TableauDebord/Admin/Annonce/Annonce";
import NewDashboardAdminV2 from "../components/TableauDebord/Admin/Dashboard/NewDashboardAdminV2";
import AjouterBublezone, {
  UpdateBubbleZone,
} from "../components/TableauDebord/Admin/ListeBubbleZone/AjouterBublezone";
import ListeBubbleZoneV2 from "../components/TableauDebord/Admin/ListeBubbleZone/ListeBubbleZoneV2";
import DetailsGerantV2 from "../components/TableauDebord/Admin/ListeUsers/DetailsGerantV2";
import DetailsUserV2 from "../components/TableauDebord/Admin/ListeUsers/DetailsUserV2";
import ListeMembre from "../components/TableauDebord/Admin/ListeUsers/ListeMembre";
import ListeUsers from "../components/TableauDebord/Admin/ListeUsers/ListeUsers";
import MessageContact from "../components/TableauDebord/Admin/MessageContact/MessageContact";
import Messagerie from "../components/TableauDebord/Admin/Messagerie/Messagerie";
import AddOrUpdateOffre from "../components/TableauDebord/Admin/ParametreAdmin/AddOrUpdateOffre";
import AjouterCgu from "../components/TableauDebord/Admin/ParametreAdmin/AjouterCgu";
import AjouterConditionTermes from "../components/TableauDebord/Admin/ParametreAdmin/AjouterConditionTermes";
import AjouterPtc from "../components/TableauDebord/Admin/ParametreAdmin/AjouterPtc";
import ParametreAdminV2 from "../components/TableauDebord/Admin/ParametreAdmin/ParametreAdminV2";
import ProfilAdmin from "../components/TableauDebord/Admin/Profil/ProfilAdmin";
import AdminLayout from "../components/TableauDebord/AdminLayout/AdminLayout";
import AssistanceOwner from "../components/TableauDebord/Proprietaire/AssistanceOwner/AssistanceOwner";
import AjouterBubblezoneOwner from "../components/TableauDebord/Proprietaire/BubbleZoneOwner/AjouterBubblezoneOwner";
import BubbleZoneDetailsOwner from "../components/TableauDebord/Proprietaire/BubbleZoneOwner/BubbleZoneDetailsOwner";
import BubbleZoneOwner from "../components/TableauDebord/Proprietaire/BubbleZoneOwner/BubbleZoneOwner";
import ModifierBubbleZoneOwner from "../components/TableauDebord/Proprietaire/BubbleZoneOwner/ModifierBubblezoneOwner";
import DonneesClient from "../components/TableauDebord/Proprietaire/DonneesClient/DonneesClient";
import MessageFelicitationOwner from "../components/TableauDebord/Proprietaire/VersionPro/MessageFelicitationOwner";
import VersionProOwner from "../components/TableauDebord/Proprietaire/VersionPro/VersionProOwner";
import ForgetPassword from "../components/auth/ForgetPassword";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import { useUser } from "../redux/root";
import { getAndSetOfferStatus } from "../utils/api/user.api";
import { getOffre } from "../utils/data";

// UserRoutes

function GuestComponent({
  component: Component,
}: {
  component: () => JSX.Element;
}) {
  const [params] = useSearchParams();

  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionID = params.get("sessionID");
    const offre = params.get("offre");
    if (sessionID && offre) {
      localStorage.setItem(
        "offre",
        JSON.stringify({
          sessionID,
          offre,
        })
      );
    }
    if (user) {
      if (user.is_proprietaire) {
        navigate("/proprietaire");
        console.log("navigate /proprietaire");
      } else {
        navigate("/admin/dashboard");
        console.log("navigate /admin/dashboard");
      }
    }
  }, [user?.uid]);
  if (user) return null;
  return <Component />;
}

function ProComponent({ component: Component }: { component: () => any }) {
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.is_proprietaire) {
      const offer = getOffre();
      if (offer) {
        type Res = { data: { ok: boolean } } | null;
        getAndSetOfferStatus({ ...offer, userId: user.uid }).then((res) => {
          const response = res as Res;
          if (response?.data?.ok) {
            console.log("version pro success");
            navigate("/proprietaire/version-pro-success");
          }
          localStorage.removeItem("offre");
        });
      }
    }
  }, [user?.uid]);

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else if (!user.is_proprietaire) {
      navigate("/admin/dashboard");
    }
  }, [user?.uid]);

  if (!user) return null;

  return <Component />;
}

function AdminComponent({ component: Component }: { component: () => any }) {
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else if (user.is_proprietaire) {
      navigate("/proprietaire");
    }
  }, [user?.uid]);

  if (!user) return null;

  return <Component />;
}

export const AppRoutes = [
  {
    path: "/",
    element: <GuestComponent component={Login} />,
  },
  {
    path: "/inscription",
    element: <GuestComponent component={Register} />,
  },
  {
    path: "/mot-de-passe-oublie",
    element: <GuestComponent component={ForgetPassword} />,
  },
  {
    path: "admin/",
    element: <AdminComponent component={AdminLayout} />,
    children: [
      { element: <Navigate to="/admin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <NewDashboardAdminV2 />,
      },
      {
        path: "bubblezone",
        element: <ListeBubbleZoneV2 />,
      },
      {
        path: "ajouter-bubblezone",
        element: <AjouterBublezone />,
      },
      {
        path: "modifier-bubblezone",
        element: <UpdateBubbleZone />,
      },
      {
        path: "bubblezone/:slug",
        element: <BubbleZoneDetailsOwner />,
      },
      {
        path: "utilisateurs-old",
        element: <ListeUsers />,
      },
      {
        path: "utilisateurs",
        element: <ListeMembre />,
      },
      {
        path: "utilisateurs/gerant/:id",
        element: <DetailsGerantV2 />,
      },
      {
        path: "utilisateurs/:id",
        element: <DetailsUserV2 />,
      },
      {
        path: "ajouter-cgu",
        element: <AjouterCgu />,
      },
      {
        path: "termes-et-conditions",
        element: <AjouterConditionTermes />,
      },
      {
        path: "politique-de-confidentialite",
        element: <AjouterPtc />,
      },
      {
        path: "profil",
        element: <ProfilAdmin />,
      },
      {
        path: "message-contact",
        element: <MessageContact />,
      },
      {
        path: "messages-predefinis",
        element: <Messagerie />,
      },
      {
        path: "annonce",
        element: <Annonce />,
      },
      {
        path: "parametres",
        element: <ParametreAdminV2 />,
      },
      {
        path: "modifier-offre/:slug",
        element: <AddOrUpdateOffre />,
      },
    ],
  },
  {
    path: "proprietaire/",
    element: <ProComponent component={AdminLayout} />,
    children: [
      {
        element: <Navigate to="/proprietaire/bubblezone" replace />,
        index: true,
      },
      {
        path: "bubblezone",
        element: <BubbleZoneOwner />,
      },
      {
        path: "donnees-client/:slug",
        element: <BubbleZoneDetailsOwner />,
      },
      {
        path: "donnees-client",
        element: <DonneesClient />,
      },
      {
        path: "ajouter-bubblezone",
        element: <AjouterBubblezoneOwner />,
      },
      {
        path: "modifier-bubblezone",
        element: <ModifierBubbleZoneOwner />,
      },
      {
        path: "profil",
        element: <ProfilAdmin />,
        // element: <ProfilOwner />,
      },
      {
        path: "modifier-bubblezone/:slug",
        element: <UpdateBubbleZone />,
      },
      {
        path: "assistance",
        element: <AssistanceOwner />,
      },
      {
        path: "version-pro",
        element: <VersionProOwner />,
      },
      {
        path: "version-pro-success",
        element: <MessageFelicitationOwner />,
      },
    ],
  },
];
