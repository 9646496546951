import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { FaTrash } from "react-icons/fa";
import { RiEdit2Fill } from "react-icons/ri";
import Swal from "sweetalert2";
import { UserInfos } from "../../../../../types";
import { deleteNormalUserFunction } from "../../../../utils/api/user.api";
import {
  DefaultPaginationOptions,
  SWAL_DEFAULT_PROPS,
  getAvatar,
  useModalActions,
} from "../../../../utils/data";
import AddUserModal from "../../../modal/admin/AddUserModal";
import { ToggleUserActive } from "../BubbleZoneTable/BubbleZoneTable";
import { ShowButton } from "../ListeParticipantTable/ListeParticipantTable";

function ListeUsersTableV2({ data }: { data: UserInfos[] }) {
  const onDelete = async (id: string) => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet utilisateur ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      ...SWAL_DEFAULT_PROPS,
      preConfirm: async () => {
        await deleteNormalUserFunction(id);
        Swal.fire({
          icon: "success",
          title: "utilisateur supprimé avec succés",
          showConfirmButton: false,
          timer: 5000,
          ...SWAL_DEFAULT_PROPS,
        });
      },
    });
  };
  const pseudoFormatter = (cell: string, row: UserInfos) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <img src={getAvatar(row)} alt="avatar" className="profil-user-table" />{" "}
        <span className="ps-2">{row.pseudo}</span>
      </div>
    );
  };
  const consulterFormatter = (cell: string, row: UserInfos) => {
    return (
      <div className="table-actions-btn-container-commandes flex-c">
        {/* @ts-ignore */}
        <ShowButton item={{ user: row }} newPage />
      </div>
    );
  };

  const editFormatter = (cell: string, row: UserInfos) => {
    return (
      <div className="table-actions-btn-container-commandes flex-l gap-1">
        {/* @ts-ignore */}
        <UpdateUserButton item={row} />
      </div>
    );
  };
  const actionFormatter = (cell: string, row: UserInfos) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          {/* @ts-ignore */}

          <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              onClick={() => onDelete(row.uid)}
              style={{ color: "#E53E3E" }}
            >
              <FaTrash />
            </button>
          </div>
        </div>
      </>
    );
  };

  const columns: unknown[] = [
    {
      dataField: "pseudo",
      text: "Pseudo",
      formatter: pseudoFormatter,
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "phone",
      text: "Abonnement",
      formatter: (cell: string, row: UserInfos) =>
        row?.subscribed ? "Gold" : "Gratuit",
    },
    {
      dataField: "id",
      text: "Modifier",
      formatter: editFormatter,
    },
    {
      dataField: "",
      text: "Consulter",
      formatter: consulterFormatter,
    },
    {
      dataField: "disabled",
      text: "Bloqué",
      formatter: (cell: string, row: UserInfos) => (
        <ToggleUserActive item={row} />
      ),
    },
    {
      dataField: "id",
      text: "Supprimer",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];
  return (
    <>
      {/* {isLoading && <ListeUsersTableSkeleton />} */}
      {/* {!isLoading && ( */}
      <>
        <BootstrapTable
          keyField="uid"
          data={data}
          columns={columns}
          bordered={true}
          condensed={false}
          responsive={true}
          wrapperClasses="table-responsive admin-table"
          noDataIndication={() => "No data"}
          pagination={paginationFactory(DefaultPaginationOptions)}
          filter={filterFactory()}
        />
      </>
      {/* )} */}
    </>
  );
}

export default ListeUsersTableV2;
function UpdateUserButton({ item }: { item: UserInfos }) {
  const modalId = "UpdateUser" + item.uid;
  const ma = useModalActions(modalId);
  return (
    <>
      <div className="container-btn-action-icon-modal">
        <button
          className="btn btn-action-icon-edit with-tooltip"
          data-tooltip-content="Modifier"
          onClick={() => ma.show()}
        >
          <RiEdit2Fill />
        </button>
      </div>
      <div
        className="modal fade"
        id={modalId}
        aria-labelledby="AddUserModalLabel"
        aria-hidden="true"
      >
        <AddUserModal item={item} onHide={ma.hide} />
      </div>
    </>
  );
}
