import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Argument } from "../../../types";

export type State = Argument[];

const initialState: State = [];

export const ArgumentSlice = createSlice({
  name: "arguments",
  initialState,
  reducers: {
    onArguments: (state, action: PayloadAction<State>) => {
      const newState = [...state];
      for (const i of action.payload) {
        const index = newState.findIndex((o) => o.id === i.id);
        if (index === -1) {
          newState.push(i);
        } else {
          newState[index] = i;
        }
      }
      return newState.sort((a, b) => parseInt(a.created_at) - parseInt(b.created_at));
    },
    onDeleteArguments: (state, action: PayloadAction<string[]>) => {
      state = state.filter((f) => !action.payload.includes(f.id));
      return state;
    },
  },
});

export const { onArguments, onDeleteArguments } = ArgumentSlice.actions;

export default ArgumentSlice.reducer;
