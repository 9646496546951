import { FiMenu } from "react-icons/fi";
import { Outlet } from "react-router-dom";
import { useUser } from "../../../redux/root";
import HeaderAdmin from "../navBar/HeaderAdmin/HeaderAdmin";
import SidebarAdmin from "../navBar/SidebarAdmin/SidebarAdmin";
import "./AdminLayout.css";

const AdminLayout = () => {
  const user = useUser();

  if (!user) return null;
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        <input type="checkbox" name="nav" id="nav_button"></input>
        <label
          htmlFor="nav_button"
          className="on no-view-mobile no-view-desktop"
        >
          <FiMenu className="icon-side-menu" />
        </label>
        {!!user && (
          <div className="nav-container no-view-mobile">
            <SidebarAdmin />
          </div>
        )}
        <div className="container-header">{!!user && <HeaderAdmin />}</div>

        <div className="content-position-after-header px-0 h-90vh position-relative page-content-base-container">
          <div className="admin-page-container-layout admin-page-container-height">
            {!!user && <Outlet />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
