import React from "react";
import CloseModalBtn from "../../shared/CloseModalBtn";

const AddQuestionModal = () => {
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header py-2 flex-m">
          <h5 className="admin-modal-title text-start">Ajouter une question</h5>
          <CloseModalBtn />
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="prenom"
                      className="form-label form-label-modal-custom"
                    >
                      Votre question
                    </label>
                    <textarea
                      className="form-control form-control-modal-custom"
                      rows={6}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="gap-3 flex-r align-center row mt-4">
                <div className="col-md-3">
                  <button
                    type="button"
                    className="btn btn-cancelled"
                    data-bs-dismiss="modal"
                  >
                    Fermer
                  </button>
                </div>
                <div className="col-md-3">
                  <button type="submit" className="btn auth-submit-btn">
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQuestionModal;
