import { useState } from "react";
import { Chat, UserInfos } from "../../../../../../types";
import { getChatsAsync } from "../../../../../utils/api/user.api";
import { useGetMessages } from "../../../../../utils/data";
import { exportToExcel } from "../../../../../utils/export";

function UserMessagesTab({ user }: { user: UserInfos }) {
  const getMessages = useGetMessages();
  const [chats, setChats] = useState<Chat[]>([]);
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    if (chats.length !== 0) return chats;
    setLoading(true);
    const res = await getChatsAsync(user.uid);
    setChats(res);
    setLoading(false);
    return res;
  };
  return (
    <div className="col-left-dashboard col-left-dashboard-responsive">
      <div className="bubblezone-dash-admin-section-edit-profil-item py-2">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Tous les messages
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <button
            onClick={async () => {
              const data = await getData();
              exportToExcel(() => getMessages(data), `Messages_${user.pseudo}`);
            }}
            disabled={loading}
            className="btn btn-download-messages"
          >
            {loading ? "Chargement..." : "Télécharger"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserMessagesTab;
