import {
  getBlob,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import { STORAGE_PATHS } from "./api";

const storage = getStorage();

async function upload(file: File, directory: string, path: string) {
  if (directory === path) {
    path = new Date().getTime().toString();
  }
  const parts = file.name.split(".");
  const ext = parts[parts.length - 1];
  const reference = ref(storage, `${directory}/${path}.${ext}`);
  await uploadBytes(reference, file);
  return getDownloadURL(reference);
}

export async function uploadUserAvatarAsync(file: File, uid: string) {
  return upload(file, STORAGE_PATHS.avatars, uid);
}

export async function uploadBubbleZoneImageAsync(file: File, id: string) {
  return upload(file, STORAGE_PATHS.bubble_zone, id);
}

export async function uploadMoodImageAsync(file: File, id: string) {
  return upload(file, STORAGE_PATHS.moods, id);
}

export async function uploadSocialPictureAsync(file: File) {
  const id = new Date().getTime().toString();
  return upload(file, "socials", id);
}

export function downloadImage(url: string, fileName: string) {
  const httpsReference = ref(storage, url);
  getBlob(httpsReference)
    .then((blob) => {
      let blobUrl = URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.download = url.replace(/^.*[\\\/]/, "");
      a.href = blobUrl;
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch((error) => {
      // Handle any errors
    });
}
