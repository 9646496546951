import React, { useState } from "react";
import { FaLock, FaUser } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/appImages/logo.png";
import { useAppDispatch } from "../../redux/root";
import { onSetAuth } from "../../redux/slices/user.slice";
import { loginAsync } from "../../utils/api/auth.api";
import { useFormData } from "../../utils/form";
import "./Login.css";
import { RiEyeLine } from "react-icons/ri";
import { AiFillEyeInvisible } from "react-icons/ai";
type LoginFormData = {
  email: string;
  password: string;
};

export function FormError({ error }: { error?: string }) {
  if (!error) return null;
  return <div className="alert alert-danger">{error}</div>;
}

export function SubmitButton({
  title,
  loading,
}: {
  title: string;
  loading?: boolean;
}) {
  // if (loading) {
  //   return (
  //     <button type="button" disabled className="btn auth-submit-btn trans-0-2">
  //       Chargement...
  //     </button>
  //   );
  // }
  return (
    <button
      type="submit"
      className="btn auth-submit-btn trans-0-2"
      disabled={loading}
    >
      {!loading ? title : "Chargement..."}
    </button>
  );
}

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [remember, setRemember] = useState(false);
  const { errors, setErrors, onSubmitForm, register } =
    useFormData<LoginFormData>(
      {
        email: "",
        password: "",
      },
      (Yup) => ({
        password: Yup.string().required().label("Mot de passe"),
        email: Yup.string().email().required().label("Adresse mail"),
      })
    );
  const [loading, setLoading] = React.useState(false);

  const onSubmit = React.useCallback(
    async (data: LoginFormData) => {
      setLoading(true);
      try {
        const auth = await loginAsync(data.email, data.password, remember);
        if (auth) {
          if (auth.userInfos.is_admin || auth.userInfos.is_super_admin) {
            dispatch(onSetAuth(auth));
            navigate("/admin/dashboard");
          } else if (auth.userInfos.is_proprietaire) {
            dispatch(onSetAuth(auth));
            navigate("/proprietaire");
          } else {
            setErrors({ email: "Identifiants incorrects" });
          }
        } else {
          setErrors({ email: "Identifiants incorrects" });
        }
      } catch (ex) {
        setErrors({ email: "Identifiants incorrects" });
        console.log("error login", ex);
      }
      setLoading(false);
    },
    [remember]
  );

  const [showPassword, setShowPassword] = useState(false);
		const toggleShowPassword = () => {
			setShowPassword((showPassword) => !showPassword);
		};
  return (
    <div>
      <div className="">
        <div className="row login-page-row">
          <div className="col-md-6 offset-md-3 right-side-col">
            <div className="login-form-container">
              <div className="login-form-introduction d-flex flex-column align-items-center">
                <div className="mb-4">
                  <img src={Logo} alt="logo" className="logo-auth-login-img" />
                </div>
                <h1 className="auth-form-title">Connectez-vous</h1>
              </div>
              <div className="mt-4">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmitForm(onSubmit);
                  }}
                  id="auth-form"
                >
                  <div className="row auth-form-row">
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="input-group auth-form-group auth-form-group-login">
                        <span
                          className="input-group-text-custom"
                          id="basic-addon1"
                        >
                          <FaUser />
                        </span>
                        <input
                          type={`email`}
                          {...register("email")}
                          className="form-control auth-form-control"
                          placeholder="Identifiant"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                    <FormError error={errors.email} />
                    <div className="col-md-12 auth-input-col mb-2">
                      <div className="input-group auth-form-group auth-form-group-login">
                        <span
                          className="input-group-text-custom"
                          id="basic-addon1"
                        >
                          <FaLock />
                        </span>
                        <input
                          type={`${showPassword ? "text" : "password"}`}
                          className="form-control auth-form-control"
                          {...register("password")}
                          placeholder="Mot de passe"
                          aria-label="Password"
                          aria-describedby="basic-addon1"
                        />
                        <span className="span-eye-password-log" onClick={toggleShowPassword}>
                          {!showPassword ? (
                            <RiEyeLine fontSize={20} color="#c4c4c4" />
                          ) : (
                            <AiFillEyeInvisible fontSize={20} color="#c4c4c4" />
                          )}
                        </span>
                      </div>
                    </div>
                    <FormError error={errors.password} />
                    <div className="d-flex justify-content-between mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={remember}
                          onChange={(e) => setRemember((old) => !old)}
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label custom-label-remember-me"
                          htmlFor="flexCheckDefault"
                        >
                          Se souvenir de moi
                        </label>
                      </div>
                      <Link
                        to={"/mot-de-passe-oublie"}
                        className="forget-password-link"
                      >
                        Mot de passe oublié ?
                      </Link>
                    </div>
                    <div className="col-md-6 offset-md-3 auth-submit-btn-container mt-5 mb-2">
                      <SubmitButton title="Connexion" loading={loading} />
                    </div>
                    {/* <div className="col-md-12 flex-c mt-4">
                      <div className="bubblezone-auth-link-register">Pas encore de compte ? <NavLink className={"bubblezone-auth-link-register-span"} to={"/inscription"}>S’inscrire</NavLink></div>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
