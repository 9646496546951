import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { useLocation, useRoutes } from "react-router-dom";
import { setLocale } from "yup";
import { fr } from "yup-locales";
import store, { useAppDispatch, useAppSelector } from "./redux/root";
import { onAnnonces, onDeleteAnnonces } from "./redux/slices/annonces.slice";
import {
  onBubbleZone,
  onDeleteBubbleZone,
} from "./redux/slices/bubble-zone.slice";
import { onMessageContact } from "./redux/slices/message-contact.slice";
import { onDeleteMoods, onMoods } from "./redux/slices/moods.slice";
import { onOffers } from "./redux/slices/offers.slice";
import { onDeleteSubjects, onSubjects } from "./redux/slices/subjects.slice";
import { onDeleteTypes, onTypes } from "./redux/slices/types.slice";
import {
  onDeleteTypesEtablissements,
  onTypesEtablissements,
} from "./redux/slices/typesEtablissement.slice";
import { onLogout, onSetUserInfos } from "./redux/slices/user.slice";
import { onDeleteUsers, onUsers } from "./redux/slices/users.slice";
import { AppRoutes } from "./routes/router";
import { authListener } from "./utils/api/auth.api";
import {
  aonnonesListener,
  bubbleZoneListener,
  messageContactListener,
  moodsListener,
  offersListener,
  subjectsListener,
  typesEtablissementListener,
  typesListener,
  userListener,
  usersListener,
} from "./utils/api/user.api";
setLocale(fr);

function useScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    global.window.scrollTo(0, 0);
  }, [location.pathname, location.state]);
  return null;
}
function App() {
  const routes = useRoutes(AppRoutes);
  const { user, userInfos } = useAppSelector((s) => s.auth);
  const userId = userInfos?.uid;
  const isAdmin = userId && !userInfos?.is_proprietaire;
  const dispatch = useAppDispatch();
  useScrollToTop();
  useEffect(() => {
    authListener((user) => {
      if (!user) {
        dispatch(onLogout());
      }
    });
  }, []);
  useEffect(() => {
    if (user?.uid) {
      // insertOffers();
      const listener = userListener(user.uid, (userInfos) => {
        dispatch(onSetUserInfos(userInfos));
      });
      return () => listener();
    }
  }, [user?.uid]);

  useEffect(() => {
    if (userId) {
      const listener = bubbleZoneListener((bubblesZones, deleteds) => {
        dispatch(onBubbleZone(bubblesZones));
        if (deleteds.length > 0) {
          setTimeout(() => dispatch(onDeleteBubbleZone(deleteds)), 1000);
        }
      });

      const listener1 = offersListener((data) => {
        dispatch(onOffers(data));
      });

      return () => {
        listener();
        listener1();
      };
    }
  }, [userId]);

  useEffect(() => {
    if (isAdmin) {
      const listener2 = aonnonesListener((data, deleteds) => {
        dispatch(onAnnonces(data));
        if (deleteds.length > 0) {
          setTimeout(() => dispatch(onDeleteAnnonces(deleteds)), 1000);
        }
      });
      return () => {
        listener2();
      };
    }
  }, [isAdmin]);

  useEffect(() => {
    if (userId && isAdmin) {
      const listener = messageContactListener((data) => {
        dispatch(onMessageContact(data));
      });
      return () => {
        listener();
      };
    }
  }, [userId, isAdmin]);

  useEffect(() => {
    if (userId) {
      const listener = usersListener((data, deleteds) => {
        dispatch(onUsers(data));
        if (deleteds.length > 0) {
          setTimeout(() => {
            dispatch(onDeleteUsers(deleteds));
          }, 1000);
        }
      });
      return () => {
        listener();
      };
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      const listener = moodsListener((data, deleteds) => {
        dispatch(onMoods(data));
        if (deleteds.length > 0) {
          setTimeout(() => {
            dispatch(onDeleteMoods(deleteds));
          }, 1000);
        }
      });
      return () => {
        listener();
      };
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      const listener = subjectsListener((data, deleteds) => {
        dispatch(onSubjects(data));
        if (deleteds.length > 0) {
          setTimeout(() => {
            dispatch(onDeleteSubjects(deleteds));
          }, 1000);
        }
      });
      return () => {
        listener();
      };
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      const listener = typesListener((data, deleteds) => {
        dispatch(onTypes(data));
        if (deleteds.length > 0) {
          setTimeout(() => {
            dispatch(onDeleteTypes(deleteds));
          }, 1000);
        }
      });
      return () => {
        listener();
      };
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      const listener = typesEtablissementListener((data, deleteds) => {
        dispatch(onTypesEtablissements(data));
        if (deleteds.length > 0) {
          setTimeout(() => {
            dispatch(onDeleteTypesEtablissements(deleteds));
          }, 1000);
        }
      });
      return () => {
        listener();
      };
    }
  }, [userId]);

  return <div className="app-routing-container">{routes}</div>;
}

export default () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
