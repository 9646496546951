import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Scores } from "../../utils/data";

const initialState: Scores = { scores: [] };

export const ScoresSlice = createSlice({
  name: "scores",
  initialState,
  reducers: {
    onScores: (state, action: PayloadAction<Scores>) => {
      return action.payload;
    },
  },
});

export const { onScores } = ScoresSlice.actions;

export default ScoresSlice.reducer;
