import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BubbleZone } from "../../../types";

export type State = BubbleZone[];

const initialState: State = [];

export const BubbleZoneSlice = createSlice({
  name: "bubble_zone",
  initialState,
  reducers: {
    onBubbleZone: (state, action: PayloadAction<State>) => {
      let newState = [...state];
      for (const i of action.payload) {
        const index = newState.findIndex((o) => o.id === i.id);
        if (index === -1) {
          newState = [i, ...newState];
        } else {
          newState[index] = i;
        }
      }
      // @ts-ignore
      return newState.sort(
        (a, b) => parseInt(b.created_at) - parseInt(a.created_at)
      );
    },
    onDeleteBubbleZone: (state, action: PayloadAction<string[]>) => {
      const newState = [...state.filter((f) => !action.payload.includes(f.id))];
      return newState;
    },
  },
});

export const { onBubbleZone, onDeleteBubbleZone } = BubbleZoneSlice.actions;

export default BubbleZoneSlice.reducer;
