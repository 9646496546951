import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getPtc, updatePtc } from "../../../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS } from "../../../../utils/data";
import { SubmitButton } from "../../../auth/Login";
const AjouterPtc = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [content, setContent] = useState(location?.state ?? "");
  useEffect(() => {
    getPtc().then((res) => {
      setContent(res);
    });
  }, []);
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(async () => {
    setLoading(true);
    try {
      await updatePtc(content);
      Swal.fire({
        title: "Succès",
        text: "Politique de confidentialité modifié avec succès",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
      navigate("/admin/parametres");
    } catch (ex) {
      Swal.fire({
        title: "Erreur",
        text: "Une erreur est survenue lors de l'ajout",
        icon: "error",
        ...SWAL_DEFAULT_PROPS,
      });
      console.log("add error", ex);
    }
    setLoading(false);
  }, [content]);
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="bg-white p-3">
            <div className="container-form">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3" />
                  </div>

                  <div className="col-md-12">
                    <div className="mb-3">
                      <ReactQuill
                        className="editor-cgu"
                        value={content}
                        onChange={setContent}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-btn-modal row flex-r mt-4">
                  <div className="col-md-3">
                    <button
                      type="button"
                      onClick={() => navigate("/admin/parametres")}
                      className="btn btn-cancelled w-100"
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="col-md-3">
                    <SubmitButton loading={loading} title="Envoyer" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterPtc;
