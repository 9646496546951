import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BiSearchAlt2 } from "react-icons/bi";
import { useFilterBubbleZones } from "./BubbleZoneOwner";
import BubbleZoneTableModifier from "./BuubleZoneTable/BubbleZoneOwnerModifierTable";
import MessageEmptyBubblezone from "./MessageEmptyBubblezone";
// import ReactDevicePreview from 'react-device-preview'
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import { SocialPicture, TypeSocialPicture } from "../../../../../types";
import {
  DefaultSocialPictures,
  getSocialPictures,
} from "../../../../utils/api/user.api";

function getDisplySize(size?: number) {
  if (!size) return "0 KB";
  const mbs = size / (1024 * 1024);
  if (mbs >= 1) return `${mbs.toFixed(2)} MB`;
  const kbs = size / 1024;
  return `${kbs.toFixed(2)} KB`;
}

function getFileName(pic: string) {
  const parts = pic.split("/");
  const uri = parts[parts.length - 1];
  const index = uri.indexOf("?");
  console.log(uri.slice(0, index));
  return uri.slice(0, index);
}

function ModifierBubbleZoneOwner() {
  const { bubbleZones, filtred, search, setSearch } = useFilterBubbleZones();
  const isEmpty = bubbleZones.length === 0;

  return (
    <div className="dashboard-admin-component pb-5">
      <div className="container-dashboard-page bg-white p-3 mb-3">
        <div className="admin-page-title-header-container">
          <div className="bubble-zone-dash-locataire-requetes-header">
            <h4 className="bubble-zone-dash-header-titre">Mes BubbleZones</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="">
            {!isEmpty && (
              <div className="flex-sb align-items-center mb-4">
                <div className="tab-dash-admin-filter-container w-100">
                  <form className="row g-3 tab-dash-admin-filter-form">
                    <div className="col-md-12 custom-filter-input-btn-add-container">
                      <div className="custom-proprietaire-filter-input-container">
                        <input
                          type="text"
                          className="form-control custom-proprietaire-filter-input custom-width-proprietaire-filter-input"
                          id="filtreLogement"
                          placeholder="Recherchez un nom de l’établissement, le code de l’établissement ou le code du gérant"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <span className="custom-proprietaire-filter-input-icon-container">
                          <BiSearchAlt2 className="custom-proprietaire-filter-input-icon" />
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
            <div className="row mb-4">
              <div className="col-lg-12 col-content-b2b-component mb-3">
                {!isEmpty && (
                  <div className="content-col-left-b2b-component">
                    <div className="row">
                      <div className="col-md-12">
                        <BubbleZoneTableModifier data={filtred} />
                      </div>
                    </div>
                  </div>
                )}
                {isEmpty && <MessageEmptyBubblezone />}
              </div>
              {/* <DisplayScores data={scores} /> */}
            </div>
            {/* <DisplaySingleScores data={scores} /> */}
          </div>
        </div>
      </div>
      {!isEmpty && <SocialPictures />}
    </div>
  );
}

export default ModifierBubbleZoneOwner;

function SocialPictures() {
  const [facebook, setFacebook] = useState<SocialPicture[]>([]);
  const [other, setOther] = useState<SocialPicture[]>([]);
  const [social, setSocial] = useState<TypeSocialPicture>();
  const [day, setDay] = useState<SocialPicture["day"]>();
  useEffect(() => {
    getSocialPictures("facebook").then((res) => {
      setFacebook(res);
    });
    getSocialPictures("other").then((res) => {
      setOther(res);
    });
  }, []);
  function downloadImage(url: string, fileName: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.download = fileName;
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }
  const item =
    social === "facebook"
      ? facebook.find((f) => f.day === day)
      : other.find((f) => f.day === day);
  return (
    <div className="p-3 message-communication-wrapper custom-size-for-preview">
      <div className="row">
        <div className="col-lg-6 col-xl-7 mb-3">
          <div className="message-communication-title mb-4">
            Votre communication à télécharger
            {/* <span>(mis à jour 01/MM/YYYY)</span> */}
          </div>
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="message-communication-label mb-2">
                Réseau social
              </div>
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ height: "46px" }}
                value={social}
                onChange={(e) => {
                  const val = e.target.value;
                  if (!val) setSocial(undefined);
                  else setSocial(val as TypeSocialPicture);
                  setDay(undefined);
                }}
              >
                <option selected={!social}>Choisir</option>
                <option selected={social === "facebook"} value="facebook">
                  Facebook
                </option>
                <option selected={social === "other"} value="other">
                  Instagram/Snap/TikTok
                </option>
              </select>
            </div>
            <div className="col-md-6 mb-4">
              <div className="message-communication-label mb-2">Date</div>
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ height: "46px" }}
                value={day}
                onChange={(e) => setDay(e.target.value as SocialPicture["day"])}
              >
                <option selected={!day}>Choisir</option>
                {DefaultSocialPictures.map((c) => (
                  <option selected={day === c.day} value={c.day}>
                    {c.day}
                  </option>
                ))}
              </select>
              {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-select custom-input-date-select" /> */}
            </div>
            {!!item?.picture && (
              <div className="col-md-8 my-4">
                <div className="message-communication-downloadable-container py-1 px-3">
                  <div>
                    <div className="message-communication-downloadable-title">
                      {item.title}
                    </div>
                    <div className="message-communication-downloadable-size">
                      {getDisplySize(item.size)}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      downloadImage(item.picture, getFileName(item.picture));
                    }}
                    className="btn message-communication-downloadable-btn"
                  >
                    Télécharger
                  </button>
                </div>
              </div>
            )}
            {!item?.picture && day && social && <h4>Pad d'image disponible</h4>}
            {/* <div className="col-md-3 mb-3"></div> */}
          </div>
        </div>
        {!!item?.picture && (
          <div className="col-lg-6 col-xl-5 mb-3 custom-no-view-small-device">
            <div className="custom-communication-preview d-flex align-items-center justify-content-center h-100 w-100 m-auto rounded p-1">
              <DeviceFrameset device="iPhone X" zoom={0.5}>
                <div className="custom-container-img-preview-mockup">
                  <img
                    src={item.picture}
                    alt="communication preview"
                    className="custom-img-preview-mockup"
                  />
                </div>
              </DeviceFrameset>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
