/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserInfos } from "../../../../../types";
import { getUserInfos } from "../../../../utils/api/user.api";
import { getAvatar } from "../../../../utils/data";
import InfosPersonnellesUser from "./DetailUserSTeps/InfosPersonnellesUser";
import UserMessagesTab from "./DetailUserSTeps/UserMessagesTab";

function DetailsUserV2() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [user, setUser] = useState<UserInfos>();

  useEffect(() => {
    if (state?.uid) {
      getUserInfos(state)
        .then((res) => {
          console.log("res", res);
          if (res) setUser(res);
        })
        .catch((err) => setUser(state));
    } else {
      navigate(-1);
    }
  }, [state]);
  if (!user) return null;
  return (
    <div className="dashbord-visiteur-component">
      <div className="bubblezone-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="content-pp-profil-visiteur text-center mb-3">
            <div className="d-flex justify-content-center align-items-center mb-3 leader-auto-user-avatar-container">
              <label htmlFor="input-test" className="input-testt">
                <img
                  src={getAvatar(user)}
                  alt="profil utilisateur"
                  className="pp-profil-user-visiteur"
                />
              </label>
            </div>
            <div className="content-text-name-user">
              <h2 className="custom-name-user-admin mb-0">{user?.pseudo}</h2>
              {/* {!!user?.code && (
                <span className="custom-code-user-admin">{user?.code}</span>
              )} */}
            </div>
          </div>
        </div>
        <div className="mt-3">
          <ul
            className="nav nav-pills custom-tab-dash-admin-container mb-0"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link custom-tab-dash-admin custom-tab-details-user active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Informations personnelles{" "}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link custom-tab-dash-admin custom-tab-details-user"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Messages{" "}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="bubblezone-dash-admin-page-content-container mb-3 pt-0">
        <div
          className="tab-content bubblezone-dash-tab-content"
          id="pills-tabContent"
        >
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <InfosPersonnellesUser user={user} />
          </div>
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <UserMessagesTab user={user} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsUserV2;
