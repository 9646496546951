import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import React from "react";

type BtnPropsType = {
  label: string;
  type?: "submit" | "button" | "reset";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
  isLoading?: boolean;
  disabled?: boolean;
  customBtn?: boolean;
  customClass?: string;
};
type BtnLoadingType = {
  isLoading: boolean;
};
type BtnPropsTypeGoBack = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigation: any;
};

export function BtnSubmit({
  isLoading,
  label,
  disabled = false,
  customClass,
  ...rest
}: BtnPropsType &
  BtnLoadingType &
  React.HtmlHTMLAttributes<HTMLButtonElement>) {
  return !isLoading ? (
    <button
      type="submit"
      className={`btn auth-submit-btn-login-short ${
        customClass && customClass
      }`}
      {...rest}
      disabled={disabled}
    >
      {label}
    </button>
  ) : (
    <button
      type="submit"
      className={`btn auth-submit-btn-login-short ${
        customClass && customClass
      }`}
      disabled={isLoading}
      {...rest}
    >
      <span
        className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
        role="status"
      ></span>
      <span>Chargement...</span>
    </button>
  );
}

export function ButtonSubmit({
  isLoading,
  label,
  ...rest
}: BtnPropsType &
  BtnLoadingType &
  React.HtmlHTMLAttributes<HTMLButtonElement>) {
  return !isLoading ? (
    <button
      type="submit"
      className="btn btn-change-password"
      disabled={isLoading}
      {...rest}
    >
      {label}
    </button>
  ) : (
    <button
      type="submit"
      className="btn btn-change-password"
      disabled={isLoading}
      {...rest}
    >
      <span
        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
        role="status"
      ></span>
      <span>En cours...</span>
    </button>
  );
}

export function BtnBack({
  label,
  navigation,
}: BtnPropsType & BtnPropsTypeGoBack) {
  return (
    <button
      type="button"
      className="btn auth-submit-annuler"
      onClick={() => navigation.previous()}
    >
      {label}
    </button>
  );
}

export function GoBackButton({ label = "Annuler" }: { label?: string }) {
  const navigate = useNavigate();
  return (
    <button
      className="btn auth-submit-annuler"
      type="button"
      onClick={() => navigate(-1)}
    >
      {label}
    </button>
  );
}

export function BtnCloseModal({ label, onClick }: BtnPropsType) {
  return (
    <button
      className="close-modal auth-submit-annuler"
      data-bs-dismiss="modal"
      type="button"
      onClick={onClick}
    >
      {label ? label : <IoClose />}
    </button>
  );
}
export function BtnCloseModalJS({ label, onClick, customBtn }: BtnPropsType) {
  return (
    <button
      className={`close-modal auth-submit-annuler ${
        customBtn && "custom-btn-annuler"
      }`}
      type="button"
      onClick={onClick}
    >
      {label ? label : <IoClose />}
    </button>
  );
}
