import React from 'react'
import Empty from "../../../../assets/appImages/empty-comment.svg"
import "./EmptyComment.css"

function EmptyComments() {
  return (
    <div className='empty-comment mt-3'>
        <div className='empty-comment-title mb-3'>Commentaires</div>
        <div className='empty-comment-wrapper'>
            <div className='empty-comment-message-container'>
                <img src={Empty} alt="empty comment" className='empty-comment-img mb-3' />
                <div className='empty-comment-message'>Pas encore de commentaires</div>
            </div>
        </div>
    </div>
  )
}

export default EmptyComments