import { UserInfos } from "../../../../../../types";
import { ABONNEMENT, isBasic } from "../../../../../utils/data";

function InfosPersonnellesGerant({ user }: { user: UserInfos }) {
  const abn = user.abonnement ?? "Basic";
  const finded = ABONNEMENT.find((a) => a.value === abn);
  const basic = isBasic(user);
  return (
    <div className="bubblezone-dash-admin-section-edit-profil-container pb-4">
      <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Nom
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            {user?.pseudo}
          </div>
        </div>
      </div>
      <hr />
      <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Raison sociale
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            {user?.raison_social}
          </div>
        </div>
      </div>
      <hr />
      <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Mail
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            {user?.email}
          </div>
        </div>
      </div>
      <hr />
      {/* <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Téléphone
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            {user?.phone}
          </div>
        </div>
      </div>
      <hr /> */}

      <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Offre
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            <div
              className={
                !basic
                  ? "details-user-custom-is-pro"
                  : "details-user-custom-is-basic"
              }
            >
              {finded?.title}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfosPersonnellesGerant;
