/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaTrash } from "react-icons/fa";
import { RiEdit2Fill } from "react-icons/ri";
import Swal from "sweetalert2";
import { UserInfos } from "../../../../../../types";
import {
  adminListener,
  deleteUserFunction,
} from "../../../../../utils/api/user.api";
import {
  DefaultPaginationOptions,
  SWAL_DEFAULT_PROPS,
  useModalActions,
} from "../../../../../utils/data";
import AddAdminModal from "../../../../modal/admin/AddAdminModal";
import ListeAdminsTableSkeleton from "../../../../tables/admin/ListeAdminsTable/ListeAdminsTableSkeleton";

function AdminTable() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [users, setUsers] = useState<UserInfos[]>([]);
  const onDelete = async (id: string) => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet admin ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      ...SWAL_DEFAULT_PROPS,
      preConfirm: async () => {
        await deleteUserFunction(id);
        Swal.fire({
          icon: "success",
          title: "Admin supprimé avec succés",
          showConfirmButton: false,
          timer: 5000,
          ...SWAL_DEFAULT_PROPS,
        });
      },
    });
  };
  useEffect(() => {
    const listener = adminListener((users, deleteds) => {
      setIsLoading(false);
      // console.log("data", users);
      setUsers((old) => {
        let newState = [...old];
        for (const i of users) {
          const index = newState.findIndex((o) => o.uid === i.uid);
          if (index === -1) {
            newState = [i, ...newState];
          } else {
            newState[index] = i;
          }
        }

        // @ts-ignore
        return newState
          .filter((f) => !deleteds.includes(f.uid))
          .sort((a, b) => parseInt(b.created_at) - parseInt(a.created_at));
      });
    });
    return () => listener();
  }, []);
  const actionFormatter = (cell: string, row) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          {/* @ts-ignore */}

          <div className="container-btn-action-icon-modal flex-c gap-1">
            <UpdateButton item={row} />
            <button
              className="btn with-tooltip btn-action-icon"
              data-tooltip-content="Supprimer"
              style={{ color: "#ACABAB" }}
              onClick={() => onDelete(row.uid)}
            >
              <FaTrash />
            </button>
          </div>
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "pseudo",
      text: "Prénom & Nom",
    },
    {
      dataField: "phone",
      text: "Téléphone",
    },
    {
      dataField: "email",
      text: "Adresse Mail",
    },
    {
      dataField: "uid",
      text: "Action",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      {isLoading && <ListeAdminsTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="uid"
            data={users}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "No data"}
            pagination={paginationFactory(DefaultPaginationOptions)}
            // filter={filterFactory()}
          />
        </>
      )}
    </>
  );
}

export default AdminTable;
export function UpdateButton({ item }: { item: UserInfos }) {
  const modalId = "UpdateUser" + item.uid;
  const ma = useModalActions(modalId);
  return (
    <>
      <div className="container-btn-action-icon-modal">
        <button
          className="btn with-tooltip btn-action-icon"
          data-tooltip-content="Modifier"
          style={{ color: "#ACABAB" }}
          onClick={() => ma.show()}
        >
          <RiEdit2Fill />
        </button>
      </div>
      <div
        className="modal fade"
        id={modalId}
        aria-labelledby="AddAdminModalLabel"
        aria-hidden="true"
      >
        <AddAdminModal item={item} onHide={ma.hide} />
      </div>
    </>
  );
}
