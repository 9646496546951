import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BubbleZone } from "../../../../../types";
import iconMarker from "../../../../assets/icons/marker.svg";
function getLatLong(item: BubbleZone) {
  const lat = parseFloat(item?.latitude);
  const lng = parseFloat(item?.longitude);
  return {
    lat: isNaN(lat) ? 0 : lat,
    lng: isNaN(lng) ? 0 : lng,
  };
}

function BubbleMap({
  data,
  // mapHeight,
  mapContainerClassName
}: {
  data: BubbleZone[];
  // mapHeight?: string;
  mapContainerClassName?: string;
}) {
  const [center, setCenter] = useState<{ lat: number; lng: number }>();
  const navigate = useNavigate();
  const [changed, setChanged] = useState(true);

  useEffect(() => {
    if (data.length > 0) {
      setCenter(getLatLong(data[0]));
    }
  }, [data.length]);

  useEffect(() => {
    setChanged(true);
    setTimeout(() => setChanged(false), 400);
  }, [data]);

  if (!center) return null;

  return (
    <div style={{ width: "100%", 
    // height: mapHeight || "450px" 
    }}
    className={mapContainerClassName}
    >
      {!changed && (
        <APIProvider apiKey={"AIzaSyDO5Ffu3pFMv-aqH3En3q_SF5bv_O35pWg"}>
          <Map defaultZoom={10} defaultCenter={center}>
            {data.map((c) => {
              const { lat, lng } = getLatLong(c);

              return (
                <Marker
                  onClick={() => {
                    navigate(`/proprietaire/donnees-client/${c.id}`, {
                      state: c,
                    });
                  }}
                  position={{ lat, lng }}
                  key={c.id}
                  icon={{
                    url: iconMarker,
                    scaledSize: new google.maps.Size(34, 34),
                  }}
                />
              );
            })}
          </Map>
        </APIProvider>
      )}
    </div>
  );
}

export default BubbleMap;
