import { BsTrashFill } from "react-icons/bs";
import { IoAddOutline } from "react-icons/io5";
import { RiEdit2Fill } from "react-icons/ri";

function ArgumentItem() {
  return (
    <div className="questions-reponses-component">
      <div className="container-questions-reponses">
        <div className="b-t-1 mb-3 py-3">
          <div>
            <p className="title-page-questions-reponses">Positifs</p>
          </div>
          <div className="d-flex gap-3">
            <div>
              <div className="reponse-item">
                <div>
                  <p className="text-reponse-item">Bonne ambiance</p>
                </div>
                <div className="d-flex gap-3">
                  <div>
                    <button
                      className="btn btn-action-icon-edit with-tooltip"
                      data-tooltip-content="Modifier"
                      data-bs-toggle="modal"
                      data-bs-target="#AddArgumentModal"
                    >
                      <RiEdit2Fill />
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn with-tooltip btn-action-icon-delete"
                      data-tooltip-content="Supprimer"
                      style={{ color: "#E53E3E" }}
                    >
                      <BsTrashFill />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="reponse-item">
                <div>
                  <p className="text-reponse-item">Très confortable</p>
                </div>
                <div className="d-flex gap-3">
                  <div>
                    <button
                      className="btn btn-action-icon-edit with-tooltip"
                      data-tooltip-content="Modifier"
                      data-bs-toggle="modal"
                      data-bs-target="#AddArgumentModal"
                    >
                      <RiEdit2Fill />
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn with-tooltip btn-action-icon-delete"
                      data-tooltip-content="Supprimer"
                      style={{ color: "#E53E3E" }}
                    >
                      <BsTrashFill />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="content-btn-add-response">
                <button
                  className="btn btn-add-response"
                  data-bs-toggle="modal"
                  data-bs-target="#AddArgumentModal"
                >
                  <IoAddOutline />
                  <span className="ps-2">Ajouter un argument</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="b-t-1 py-3">
          <div>
            <p className="title-page-questions-reponses">Questions</p>
          </div>
          <div className="d-flex gap-3">
            <div>
              <div className="content-btn-add-response">
                <button
                  className="btn btn-add-response"
                  data-bs-toggle="modal"
                  data-bs-target="#AddArgumentModal"
                >
                  <IoAddOutline />
                  <span className="ps-2">Ajouter un argument</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddArgumentModal"
        aria-labelledby="AddArgumentModalLabel"
        aria-hidden="true"
      >
        {/* <AddArgumentModal /> */}
      </div>
    </div>
  );
}

export default ArgumentItem;
