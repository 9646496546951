import React, { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Offer } from "../../../../../types";
import DeleteIcon from "../../../../assets/icons/Delete.svg";
import EditIcon from "../../../../assets/icons/EditSquare.svg";
import { useAppSelector } from "../../../../redux/root";
import { updateOffer } from "../../../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { Input, InputWithAddon } from "../../../common/Input";
function AddOrUpdateOffre({ item }: { item: Offer }) {
  const navigate = useNavigate();
  const [newOption, setNewOption] = useState("");
  const { id, options: initOptions, ...formData } = item;
  const [open, setOpen] = useState(false);

  const [options, setOptions] = useState(initOptions ?? []);

  const { errors, setErrors, onSubmitForm, register } = useFormData<Offer>(
    formData,
    (Yup) => ({
      nom: Yup.string().required().label("Nom"),
      price: Yup.string().required().label("Prix"),
      description: Yup.string().required().label("Description"),
    })
  );

  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(
    async (data: Offer) => {
      const form = { ...data };
      form.options = options;
      setLoading(true);
      try {
        await updateOffer(item.id ?? "", form);
        setTimeout(() => {
          Swal.fire({
            title: "Succès",
            text: "Offre modifié avec succès",
            icon: "success",
            ...SWAL_DEFAULT_PROPS,
          });
          navigate(-1);
        }, 400);
      } catch (ex) {
        setErrors({ nom: "Une erreur est survenue lors de l'ajout" });
        console.log("add error", ex);
      }
      setLoading(false);
    },
    [options]
  );

  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3 mb-3">
        <div className="admin-page-title-header-container">
          <div className="bubble-zone-dash-locataire-requetes-header">
            <h4 className="bubble-zone-dash-header-titre linkable">
              <span
                style={{ color: "#DB3251", marginRight: 10 }}
                onClick={() => navigate(-1)}
              >
                <AiOutlineArrowLeft />
              </span>
              Modifier une offre
            </h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitForm(onSubmit);
            }}
          >
            <div className="row">
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Nom de l'offre"
                  placeholder="Ajouter un nom"
                  {...register("nom")}
                  error={errors.nom}
                  customClassLabel={"input-modal-label-form-add"}
                  customClassInput="input-modal-form-add"
                />
              </div>
              <div className="col-md-6 mb-3">
                <InputWithAddon
                  type="text"
                  label="Prix"
                  placeholder="Ajouter un prix"
                  customClassLabel={"input-modal-label-form-add"}
                  customClassInput="input-modal-form-add custom-text-bold no-border-right"
                  addon="Euro"
                  addonPosition="end"
                  {...register("price")}
                  error={errors.price}
                />
              </div>
              <div className="col-md-12 mb-3">
                <Input
                  type="text"
                  label="Description de l'offre"
                  placeholder="Ajouter une description"
                  customClassLabel={"input-modal-label-form-add"}
                  customClassInput="input-modal-form-add"
                  {...register("description")}
                  error={errors.description}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor={"fonctionnalite"}
                  className={`form-label form-label-modal-custom input-modal-label-form-add`}
                  aria-labelledby={"fonctionnalite"}
                >
                  Fonctionnalités
                </label>
                <div className="add-offr-list-fonctionnalite mb-4">
                  <ul className="bubblezone-version-pro-list-item mb-3">
                    {options.map((item, index) => (
                      <OptionItem
                        item={item}
                        key={index}
                        onDelete={() => {
                          setOptions((old) =>
                            old.filter((_, i) => i !== index)
                          );
                        }}
                        onUpdate={(updated) => {
                          setOptions((old) => {
                            const newItems = [...old];
                            newItems[index] = updated;
                            return newItems;
                          });
                        }}
                      />
                    ))}
                    {!open ? (
                      <button
                        className="btn btn-add-fonctionnalite mb-3"
                        onClick={() => setOpen(!open)}
                      >
                        Ajouter
                      </button>
                    ) : (
                      <li>
                        <div className="add-fonctionnalite-item">
                          <Input
                            type="text"
                            placeholder=""
                            customClassLabel={"input-modal-label-form-add"}
                            customClassInput="input-modal-form-add rounded"
                            customWidth100={"100"}
                            value={newOption}
                            onChange={(e) => {
                              setNewOption(e.target.value);
                            }}
                          />
                          <button
                            className="btn btn-add-fonctionnalite-item mb-3"
                            type="button"
                            autoFocus
                            onClick={() => {
                              if (newOption.trim()) {
                                setOptions((old) => [...old, newOption]);
                              }
                              setNewOption("");
                              setOpen(false);
                            }}
                          >
                            Ajouter
                          </button>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center gap-3 mb-4">
                  <button
                    className="btn ajout-offrte-btn-annuler"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Annuler
                  </button>
                  {loading && (
                    <button
                      type="button"
                      className="btn ajout-offrte-btn-submit"
                    >
                      Chargement...
                    </button>
                  )}
                  {!loading && (
                    <button
                      type="submit"
                      className="btn ajout-offrte-btn-submit"
                    >
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default () => {
  const { slug } = useParams<{ slug: string }>();
  const offres = useAppSelector((s) => s.offers);
  const item = offres.find((o) => o.id === slug);
  if (!item) {
    if (offres.length > 0) return <Navigate to={"/"} />;

    return null;
  }
  return <AddOrUpdateOffre item={item} />;
};

type OptionItemProps = {
  item: string;
  onDelete: () => void;
  onUpdate: (item: string) => void;
};
function OptionItem({ item, onDelete, onUpdate }: OptionItemProps) {
  const [update, setUpdate] = useState(false);
  const [val, setVal] = useState(item);
  if (update) {
    return (
      <div className="add-fonctionnalite-item mb-3">
        <Input
          type="text"
          placeholder=""
          customClassLabel={"input-modal-label-form-add"}
          customClassInput="input-modal-form-add rounded"
          customWidth100={"100"}
          value={val}
          autoFocus
          onChange={(e) => {
            setVal(e.target.value);
          }}
        />
        <button
          className="btn btn-add-fonctionnalite-item mb-3"
          type="button"
          onClick={() => {
            if (val.trim()) {
              onUpdate(val);
            }
            setUpdate(false);
          }}
        >
          Modifier
        </button>
      </div>
    );
  }
  return (
    <div className="position-relative custom-fonctionnalite-item mb-3">
      <li>{item}</li>
      <div className="custrom-pos-btn-actions">
        <button
          type="button"
          onClick={onDelete}
          className="btn btn-edit-fonctionnalite"
        >
          <img
            src={DeleteIcon}
            alt="delete icon"
            className="icon-edit-fonctionnalite"
          />
        </button>
        <button
          onClick={() => {
            setUpdate(true);
          }}
          type="button"
          className="btn btn-edit-fonctionnalite"
        >
          <img
            src={EditIcon}
            alt="edit icon"
            className="icon-edit-fonctionnalite"
          />
        </button>
      </div>
    </div>
  );
}
