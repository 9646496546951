import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { NavLink, useNavigate } from "react-router-dom";
import { BubbleZone } from "../../../../../../types";
import { DefaultPaginationOptions } from "../../../../../utils/data";
function BubbleZoneTableModifier({ data }: { data: BubbleZone[] }) {
  const navigate = useNavigate();
  const nameFormatter = (cell: string, row: BubbleZone) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <img
          src={row.image || `https://ui-avatars.com/api/?name=${row.name}`}
          alt="type document"
          className="custom-photo-bubblezone-table"
        />{" "}
        <div className="d-flex flex-column gap-1">
          <span className="ps-2">{row.name}</span>
          <span className="ps-2 edit-bubble-code-span">{row.code}</span>
        </div>
      </div>
    );
  };

  const actionFormatter = (cell: string, row: BubbleZone) => {
    return (
      <div className="table-actions-btn-container-commandes d-flex gap-3">
        <NavLink
          state={row}
          to={`/proprietaire/donnees-client/${row.id}`}
          className="btn custom-btn-action-bubble-see"
        >
          Consulter
        </NavLink>
        <NavLink
          state={row}
          to={`/proprietaire/modifier-bubblezone/${row.id}`}
          className="btn custom-btn-action-bubble-modif"
        >
          Modifier
        </NavLink>
        {/* <button
            className="btn custom-btn-action-bubble-download"
          >
            <MdFileDownload />
          </button> */}
      </div>
    );
  };
  const columns = [
    {
      dataField: "nom",
      formatter: (cell: string, row: BubbleZone) => nameFormatter(cell, row),
    },
    {
      dataField: "id",
      style: { textAlign: "center" },
      formatter: (cell: string, row: BubbleZone) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
  ];

  const handleClick = (row) => {
    navigate(`/proprietaire/donnees-client/${row.id}`, { state: row });
  };

  const rowEvents = {
    onClick: (e, row) => {
      if (!e.target.classList.contains("custom-btn-action-bubble-modif")) {
        handleClick(row);
      }
    },
  };
  const rowStyle = (row, rowIndex) => {
    return {
      cursor: "pointer",
    };
  };
  return (
    <>
      <BootstrapTable
        keyField="id"
        data={data}
        columns={columns}
        bordered={true}
        condensed={false}
        responsive={true}
        rowEvents={rowEvents}
        rowStyle={rowStyle}
        wrapperClasses="table-responsive admin-table"
        noDataIndication={() => "No data"}
        pagination={paginationFactory(DefaultPaginationOptions)}
      />
    </>
  );
}

export default BubbleZoneTableModifier;
