import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Auth } from "../../../types";
const AUTH_KEY = "bubble-zone_auth";
const AuthStorage = {
  get: () => {
    const data = localStorage.getItem(AUTH_KEY);
    if (data) return JSON.parse(data) as Auth;
    return { user: null, userInfos: null };
  },
  set: (data: Auth) => {
    localStorage.setItem(AUTH_KEY, JSON.stringify(data));
  },
  clear: () => {
    localStorage.removeItem(AUTH_KEY);
  },
};

const initialState: Auth = AuthStorage.get();

export const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    onSetAuth: (state, action: PayloadAction<Auth>) => {
      state = action.payload;
      AuthStorage.set(state);
      return state;
    },
    onSetUser: (state, action: PayloadAction<Auth["user"]>) => {
      state.user = action.payload;
      AuthStorage.set(state);
    },
    onSetUserInfos: (state, action: PayloadAction<Auth["userInfos"]>) => {
      state.userInfos = action.payload;
      AuthStorage.set(state);
    },
    onLogout: (state) => {
      state.user = null;
      state.userInfos = null;
      AuthStorage.clear();
    },
  },
});

export const { onLogout, onSetUser, onSetUserInfos, onSetAuth } =
  userSlice.actions;

export default userSlice.reducer;
