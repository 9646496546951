/* eslint-disable @typescript-eslint/no-use-before-define */
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import $ from "jquery";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import Geocode from "react-geocode";

import {
  AdminDisplay,
  BubbleZone,
  Chat,
  InToBulle,
  Mood,
  NoteAvis,
  Subject,
  UserInfos,
} from "../../types";
import { useAppDispatch, useAppSelector } from "../redux/root";
import { onScores } from "../redux/slices/scores.slice";
import {
  getAllChatsAdminAsync,
  getAllEyesContactAdminAsync,
  getAllIntoBulleAsync,
  getAllNoteAvisAsync,
  getChatsByBubbleZoneAsync,
  getIntoBulleAsync,
  getNoteAvisAsync,
} from "./api/user.api";
export type { AdminDisplay } from "../../types";

Geocode.setApiKey("AIzaSyDO5Ffu3pFMv-aqH3En3q_SF5bv_O35pWg");
Geocode.setLanguage("fr");
export function locationFromAdresse(location: string) {
  return Geocode.fromAddress(location).then(
    (response) => {
      const { lat, lng } = response.results[0].geometry.location;
      return { lat, lng };
    },
    (error) => {
      console.error(error);
      return null;
    }
  );
}

export function useGetSubject() {
  const subjects = useAppSelector((s) => s.subjects);
  const getSubject = useCallback(
    (subject?: string) => {
      const item = subjects.find((m) => m.id === subject);
      return item ?? null;
    },
    [subjects]
  );

  return getSubject;
}

export function useGetMood() {
  const moods = useAppSelector((s) => s.moods);
  const getMood = useCallback(
    (mood?: string) => {
      const item = moods.find((m) => m.id === mood);
      return item ?? null;
    },
    [moods]
  );

  return getMood;
}

export function closeModal(modalId: string) {
  // @ts-ignore
  $(`#${modalId}`).modal("hide");
}

export function useModalActions(modalId: string) {
  const [modal, setModal] = useState<{ show?: () => void; hide?: () => void }>(
    {}
  );
  useEffect(() => {
    var myModal = new Modal(document.getElementById(modalId), {});
    if (document.readyState === "complete") {
      setModal(myModal);
    }
    document.onreadystatechange = function () {
      setModal(myModal);
    };
  }, []);

  const show = () => {
    // $(".modal-backdrop").css({ display: "block" });
    // if (modal?.show) {
    //   modal.show();
    // }
    document.getElementById(modalId)?.classList.add("show");
    $(`#${modalId}`).show();
  };
  const hide = () => {
    document.getElementById(modalId)?.classList.remove("show");
    $(`#${modalId}`).hide();
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    $(`body`).css({ overflowY: "scroll" });
    // if (modal?.hide) modal.hide();
    // setTimeout(() => {
    //   $(".modal-backdrop").css({ display: "none" });
    //   $("body").css({ overflow: "scroll" });
    // }, 500);
  };

  return { show, hide };
}

export function getAvatar(user: UserInfos) {
  if (user.avatar) return user.avatar;
  return `https://ui-avatars.com/api/?name=${user.pseudo}`;
}

export type Display = "all" | "30" | "120";

export function useAvgMoods(inBulles: InToBulle[]) {
  const moods = useAppSelector((s) => s.moods);

  const [data, setData] = useState<{ count: number; mood: Mood }[]>([]);
  useEffect(() => {
    let filtred = [...inBulles];
    const { length } = filtred;
    setData(
      moods.map((d) => ({
        count: Math.round(
          (filtred.filter((f) => f.mood === d.id).length / (length || 1)) * 100
        ),
        mood: d,
      }))
    );
  }, [inBulles, moods]);

  return data;
}

export type MemberInBubble = {
  inBulle: InToBulle;
  user: UserInfos;
  note?: NoteAvis;
};
export type Connexion = { hours: string; count: number };

export function useMembers(
  itemId: string,
  display: Display,
  dateSelect?: Moment
) {
  const usersList = useAppSelector((s) => s.users);
  const [connexions, setConnexions] = useState<Connexion[]>([]);
  const [users, setUsers] = useState<MemberInBubble[]>([]);
  const [types, setTypes] = useState<GroupedAge[]>([]);
  const [inBulles, setInBulles] = useState<InToBulle[]>([]);

  useEffect(() => {
    getAllIntoBulleAsync(itemId, display, dateSelect).then((data) =>
      setInBulles(data)
    );
  }, [display, dateSelect?.format("L"), itemId]);

  useEffect(() => {
    let filtred = [...inBulles];
    const hours = [
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
    ];
    const obj: { [key: string]: number } = {};
    for (let h of hours) {
      obj[h] = 0;
    }
    for (let item of filtred) {
      let date = moment(item.date).format("HH");
      if (obj[date]) obj[date]++;
      else obj[date] = 1;
    }
    setConnexions(
      Object.keys(obj)
        .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
        .map((c) => ({
          count: obj[c],
          hours: c,
        }))
    );

    const obj2: { [key: string]: number } = {};
    for (let item of inBulles) {
      if (obj2[item.userId]) obj2[item.userId]++;
      else obj2[item.userId] = 1;
    }
    // console.log(inBulles);
    const keys = Object.keys(obj2);

    setTypes([
      {
        title: "Nouveaux",
        color: "#3867d6",
        count: keys.filter((f) => obj2[f] <= 2).length,
      },
      {
        title: "Habitués",
        color: "#45aaf2",
        count: keys.filter((f) => obj2[f] > 2).length,
      },
    ]);

    filtred = _.unionBy(filtred, (item) => item.userId);

    const ids = filtred.map((f) => f.userId);
    const users: MemberInBubble[] = [];
    for (const i of usersList) {
      if (ids.includes(i.uid)) {
        users.push({
          inBulle: filtred.find((f) => f.userId === i.uid) as InToBulle,
          user: i,
          note: undefined,
        });
      }
    }

    setUsers(users);
  }, [inBulles, usersList]);

  return { users, connexions, types, inBulles };
}

export const SWAL_DEFAULT_PROPS = {
  confirmButtonColor: "#531132",
  iconColor: "#531132",
  cancelButtonColor: "#DB3250",
};

function isTimeBetweenFor(actualDate: moment.Moment, date: moment.Moment) {
  const start = moment(actualDate.toDate().getTime()).set({
    hours: 8,
    minutes: 0,
  });
  const end = moment(actualDate.toDate().getTime())
    .add(1, "days")
    .set({ hours: 7, minutes: 59 });
  return start < date && end >= date;
}

type GroupedCi = { ci: Subject; count: number };
export function useGroupedSubject(members: MemberInBubble[]) {
  const allSubjects = useAppSelector((s) => s.subjects);
  const [groupedCi, setGroupedCi] = useState<GroupedCi[]>([]);
  useEffect(() => {
    const data: GroupedCi[] = [];

    for (let {
      user: { subjects = [] },
    } of members) {
      for (let subject of subjects) {
        const index = data.findIndex((i) => i.ci.id === subject);
        if (index === -1) {
          const obj = allSubjects.find((c) => c.id === subject);
          if (obj) {
            data.push({ ci: obj, count: 1 });
          }
        } else {
          data[index].count++;
        }
      }
    }
    setGroupedCi(data.sort((a, b) => b.count - a.count).slice(0, 4));
  }, [members]);

  return groupedCi;
}

export function useLatestCis(members: MemberInBubble[]) {
  const allSubjects = useAppSelector((s) => s.subjects);
  const [groupedCi, setGroupedCi] = useState<GroupedCi[]>([]);
  useEffect(() => {
    const data: GroupedCi[] = [];
    const obj: { [key: string]: number } = {};
    for (let s of allSubjects) {
      obj[s.id] = 0;
    }
    for (const {
      user: { subjects = [] },
    } of members) {
      for (let subject of subjects) {
        obj[subject]++;
      }
    }
    for (let [k, v] of Object.entries(obj)) {
      data.push({
        ci: allSubjects.find((s) => s.id === k) as Subject,
        count: v,
      });
    }
    setGroupedCi(data.sort((a, b) => a.count - b.count).slice(0, 5));
  }, [members]);

  return groupedCi;
}

export type GroupedAge = { count: number; title: string; color: string };
export function useGroupedAges(members: MemberInBubble[]) {
  const [grounpedAges, setGroupedAges] = useState<GroupedAge[]>([]);
  useEffect(() => {
    const data: GroupedAge[] = [
      {
        count: 0,
        title: "18-24 ans",
        color: "#FFBE15",
      },
      { title: "25-34 ans", count: 0, color: "#FFE450" },
      { title: "35 et +", count: 0, color: "#D7A63C" },
    ];
    for (let {
      user: { age },
    } of members) {
      const ag = parseInt(String(age || 18), 10);
      if (ag <= 24) data[0].count++;
      else if (ag <= 34) data[1].count++;
      else data[2].count++;
    }
    setGroupedAges(data);
  }, [members]);

  return grounpedAges;
}

export function useGroupedGenre(members: MemberInBubble[]) {
  const [grounpedAges, setGroupedAges] = useState<GroupedAge[]>([]);
  useEffect(() => {
    const data: GroupedAge[] = [
      {
        count: 0,
        title: "Homme",
        color: "#9EDCFF ",
      },
      { title: "Femme", count: 0, color: "#FFB8BC" },
      { title: "Non-binaire", count: 0, color: "#D7B7FF" },
    ];
    for (let {
      user: { sexe },
    } of members) {
      const finded = data.find((d) => d.title === sexe);
      if (finded) {
        finded.count++;
      }
    }
    setGroupedAges(data);
  }, [members]);

  return grounpedAges;
}

type LikeAvg = { liked: number; unliked: number };
type AVGAvis = {
  liked: number;
  unliked: number;
  ambiance: LikeAvg;
  prix: LikeAvg;
  service: LikeAvg;
  clientele: LikeAvg;
};
export function useAvgAvis(
  itemId: string,
  display: Display,
  dateSelect?: moment.Moment,
) {
  const [avis, setAvis] = useState<NoteAvis[]>([]);
  const usersList = useAppSelector((s) => s.users);
  const [avg, setAvg] = useState<AVGAvis>({
    ambiance: { liked: 0, unliked: 0 },
    clientele: { liked: 0, unliked: 0 },
    prix: { liked: 0, unliked: 0 },
    service: { liked: 0, unliked: 0 },
    liked: 0,
    unliked: 0,
  });
  const [notes, setNotes] = useState<NoteAvis[]>([]);
  useEffect(() => {
    getAllNoteAvisAsync(itemId, display, dateSelect).then((data) =>
      setNotes(data)
    );
  }, [display, dateSelect?.format("L")]);

  useEffect(() => {
    let filtredNotes = [...notes];
    setAvis(
      filtredNotes
        .filter((f) => f.avis)
        .map((c) => {
          c.userObj = usersList.find((u) => u.uid === c.userId);
          return c;
        })
    );
    const data = {
      ambiance: { liked: 0, unliked: 0 },
      prix: { liked: 0, unliked: 0 },
      service: { liked: 0, unliked: 0 },
      clientele: { liked: 0, unliked: 0 },
    };
    for (let i of filtredNotes) {
      if (i.ambiance === true) data.ambiance.liked++;
      if (i.ambiance === false) data.ambiance.unliked++;

      if (i.prix === true) data.prix.liked++;
      if (i.prix === false) data.prix.unliked++;

      if (i.service === true) data.service.liked++;
      if (i.service === false) data.service.unliked++;

      if (i.clientele === true) data.clientele.liked++;
      if (i.clientele === false) data.clientele.unliked++;
      const totalAmbiance = data.ambiance.liked + data.ambiance.unliked;
      const totalClientele = data.clientele.liked + data.clientele.unliked;
      const totalService = data.service.liked + data.service.unliked;
      const totalPrix = data.prix.liked + data.prix.unliked;
      const getPercent = (n1: number, n2: number) => {
        if (n2 === 0) return 0;
        return Math.ceil((n1 / n2) * 100);
      };
      const totalLiked =
        data.ambiance.liked +
        data.prix.liked +
        data.service.liked +
        data.clientele.liked;

      const totalUnliked =
        data.ambiance.unliked +
        data.prix.unliked +
        data.service.unliked +
        data.clientele.unliked;

      const total = totalLiked + totalUnliked;
      setAvg({
        liked: getPercent(totalLiked, total),
        unliked: getPercent(totalUnliked, total),
        ambiance: {
          liked: getPercent(data.ambiance.liked, totalAmbiance),
          unliked: getPercent(data.ambiance.unliked, totalAmbiance),
        },
        service: {
          liked: getPercent(data.service.liked, totalService),
          unliked: getPercent(data.service.unliked, totalService),
        },
        prix: {
          liked: getPercent(data.prix.liked, totalPrix),
          unliked: getPercent(data.prix.unliked, totalPrix),
        },
        clientele: {
          liked: getPercent(data.clientele.liked, totalClientele),
          unliked: getPercent(data.clientele.unliked, totalClientele),
        },
      });
    }
  }, [notes, usersList.length]);

  return {
    avg,
    avis,
  };
}

type SEXE_TYPE = {
  title: string;
};

export const SEXE: SEXE_TYPE[] = [
  { title: "Homme" },
  { title: "Femme" },
  { title: "Non-binaire" },
];

type ABN_TYPE = {
  title: string;
  value: UserInfos["abonnement"];
};

export const ABONNEMENT: ABN_TYPE[] = [
  { title: "Gratuit", value: "Basic" },
  { title: "Basic", value: "Professionnel" },
  { title: "Pro", value: "Pro" },
];

export function isBasic(user?: UserInfos) {
  if (user?.is_admin || user?.is_super_admin) return false;
  return !user?.abonnement || user?.abonnement === "Basic";
}

export const ORIENTATION_SEXUELLE = [
  "Hétérosexuel",
  "Homosexuel",
  "Asexuel",
  "Bisexuel",
  "Pansexuel",
  "Queer",
  "Ne pas renseigner",
];

//  admin

export type DashboardAdmin = {
  downloads: number;
  bubbleZones: number;
  messages: number;
  deleteds: number;
  eyesContact: number;
  pro: number;
};

export type DashboardAdminB2B = {
  basic: number;
  pro: number;
  pre: number;
  total: number;
};

export type Scores = {
  scores: BubbleZone[];
  ambiance?: BubbleZone;
  prix?: BubbleZone;
  service?: BubbleZone;
  clientele?: BubbleZone;
};

export function useScores() {
  const scores = useAppSelector((s) => s.scores);
  const dispatch = useAppDispatch();
  const bubbleZones = useAppSelector((s) => s.bubble_zone);

  useEffect(() => {
    if (scores.scores.length === 0) {
      getNoteAvisAsync().then((notes) => {
        type ObjVal = {
          ambiance: number;
          prix: number;
          service: number;
          clientele: number;
        };
        const obj: { [key: string]: ObjVal } = {};
        const fields: (keyof NoteAvis)[] = [
          "ambiance",
          "clientele",
          "prix",
          "service",
        ];
        for (let note of notes) {
          if (!obj[note.bubbleZoneId])
            obj[note.bubbleZoneId] = {
              ambiance: 0,
              clientele: 0,
              prix: 0,
              service: 0,
            };
          for (let k of fields) {
            if (note[k]) {
              obj[note.bubbleZoneId][k]++;
            }
          }
        }
        for (let b of bubbleZones) {
          if (!obj[b.id])
            obj[b.id] = {
              ambiance: 0,
              clientele: 0,
              prix: 0,
              service: 0,
            };
        }
        type Data = ObjVal & { bubbleZone: BubbleZone; total: number };
        const data: Data[] = [];
        for (let [k, val] of Object.entries(obj)) {
          const finded = bubbleZones.find((b) => b.id === k);
          if (finded) {
            data.push({
              ...val,
              bubbleZone: finded,
              total: val.ambiance + val.clientele + val.prix + val.service,
            });
          }
        }
        dispatch(
          onScores({
            scores: [...data]
              .sort((a, b) => b.total - a.total)
              .map((c) => c.bubbleZone),
            ambiance: _.maxBy(
              data.filter((d) => d.ambiance > 0),
              (i: Data) => i.ambiance
            )?.bubbleZone,
            clientele: _.maxBy(
              data.filter((d) => d.clientele > 0),
              (i: Data) => i.clientele
            )?.bubbleZone,
            prix: _.maxBy(
              data.filter((d) => d.prix > 0),
              (i: Data) => i.prix
            )?.bubbleZone,
            service: _.maxBy(
              data.filter((d) => d.service > 0),
              (i: Data) => i.service
            )?.bubbleZone,
          })
        );
      });
    }
  }, [bubbleZones.length]);

  return scores;
}

export function useDashboardAdminB2B() {
  const users = useAppSelector((s) => s.users);

  const [data, setData] = useState<DashboardAdminB2B>({
    basic: 0,
    pre: 0,
    pro: 0,
    total: 0,
  });
  useEffect(() => {
    const filtred = users.filter((u) => u.is_proprietaire);
    setData(() => ({
      basic: filtred.filter((u) => isBasic(u)).length,
      pre: filtred.filter((u) => isVersionPro(u.abonnement)).length,
      pro: filtred.filter((u) => u.abonnement === "Professionnel").length,
      total: filtred.length,
    }));
  }, [users.length]);

  return data;
}

export function useDashboardAdmin(display: AdminDisplay, date?: moment.Moment) {
  const NbBubbleZones = useAppSelector((s) => s.bubble_zone.length);
  const users = useAppSelector((s) => s.users);

  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState(0);
  const [eyesContact, setEyesContact] = useState(0);
  useEffect(() => {
    Promise.all([
      getAllChatsAdminAsync(display, date),
      getAllEyesContactAdminAsync(display, date),
    ]).then((res) => {
      setMessages(res[0]);
      setEyesContact(res[1]);
      setLoading(false);
    });
  }, [date?.format("L"), display]);

  const [data, setData] = useState<DashboardAdmin>({
    bubbleZones: 0,
    deleteds: 0,
    downloads: 0,
    messages: 0,
    eyesContact: 0,
    pro: 0,
  });
  useEffect(() => {
    setData((old) => ({
      ...old,
      bubbleZones: NbBubbleZones,
      messages: messages,
      eyesContact: eyesContact,
    }));
  }, [display, NbBubbleZones, messages, eyesContact]);

  useEffect(() => {
    setData((old) => ({
      ...old,
      downloads: users.length,
      pro: users.filter((u) => isVersionPro(u.abonnement)).length,
    }));
  }, [users.length]);

  return {
    data,
    loading,
  };
}

export type ConnexonBubbleZone = {
  uid: string;
  count: number;
  item?: BubbleZone;
};
export function useMembersAdmin(display: AdminDisplay, dateSelect?: Moment) {
  const usersList = useAppSelector((s) => s.users);

  const [connexions, setConnexions] = useState<Connexion[]>([]);

  const [users, setUsers] = useState<MemberInBubble[]>([]);
  const moods = useAppSelector((s) => s.moods);
  const [avgMoods, setAvgMoods] = useState<{ count: number; mood: Mood }[]>([]);
  const [inBulles, setInBulles] = useState<InToBulle[]>([]);

  useEffect(() => {
    Promise.all([getIntoBulleAsync(display, dateSelect)]).then((res) => {
      setInBulles(res[0]);
    });
  }, [display, dateSelect?.format("L")]);

  useEffect(() => {
    let filtred = [...inBulles];

    const obj: { [key: string]: number } = {};
    for (let item of filtred) {
      let date = moment(item.date).format("HH");
      if (obj[date]) obj[date]++;
      else obj[date] = 1;
    }
    setConnexions(
      Object.keys(obj)
        .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
        .map((c) => ({
          count: obj[c],
          hours: c,
        }))
    );

    const obj2: { [key: string]: number } = {};
    for (let item of inBulles) {
      if (obj2[item.userId]) obj2[item.userId]++;
      else obj2[item.userId] = 1;
    }

    filtred = _.unionBy(filtred, (item) => item.userId);

    const ids = filtred.map((f) => f.userId);
    const users: MemberInBubble[] = [];
    for (const i of usersList) {
      if (ids.includes(i.uid)) {
        users.push({
          inBulle: filtred.find((f) => f.userId === i.uid) as InToBulle,
          user: i,
          note: undefined,
        });
      }
    }
    const { length } = filtred;
    setAvgMoods(
      moods.map((d) => ({
        count: Math.round(
          (filtred.filter((f) => f.mood === d.id).length / (length || 1)) * 100
        ),
        mood: d,
      }))
    );

    setUsers(users);
  }, [inBulles, moods, usersList.length]);

  return { users, connexions, avgMoods };
}

export function useConnexionBubbleZOnes(initData: ConnexonBubbleZone[]) {
  const [data, setData] = useState<BubbleZone[]>([]);
  const bubbleZones = useAppSelector((s) => s.bubble_zone);
  useEffect(() => {
    const newData: BubbleZone[] = [];
    for (let item of initData) {
      if (!item.item) {
        item.item = bubbleZones.find((b) => b.id === item.uid);
      }
      if (item.item) {
        newData.push(item.item);
      }
    }
    setData(newData);
  }, [bubbleZones.length, initData.length]);

  return data;
}

export function getBubbleZoneCodePostal(item: BubbleZone) {
  const matched = item.address.match(/[0-9]{5,6}/gi);
  if (matched && matched.length > 0) {
    const code_postal = matched[0];
    return code_postal;
  }

  return "-";
}

export type ArchiveData = {
  item: BubbleZone;
  messages: Chat[];
  date: number;
};
export function useArchives(userId: string) {
  const bubbleZones = useProBubbleZones(userId);
  const [data, setData] = useState<ArchiveData[]>([]);

  useEffect(() => {
    for (let item of bubbleZones) {
      getChatsByBubbleZoneAsync(item.id).then((res) => {
        setData((old) => {
          const index = old.findIndex((i) => i.item.id === item.id);
          if (index === -1) {
            old.push({
              item,
              date: moment().toDate().getTime(),
              messages: res,
            });
          } else {
            old[index] = { ...old[index], messages: res };
          }
          return [...old];
        });
      });
    }
  }, [bubbleZones?.length]);

  return data;
}

export function useGetMessages() {
  const users = useAppSelector((s) => s.users);
  const getData = (data: Chat[]) => {
    const map = new Map(users.map((c) => [c.uid, c]));
    return data.map((c) => {
      const from = map.get(c.from);
      const to = map.get(c.to);
      return {
        De: from?.pseudo ?? "-",
        A: to?.pseudo ?? "-",
        date: moment(c.date).format("DD/MM/YYYY HH:mm"),
        Message: c.message,
      };
    });
  };

  return getData;
}

export function useProBubbleZones(userId?: string) {
  const [bubbleZones, setBubbleZones] = useState<BubbleZone[]>([]);

  const allBubbleZones = useAppSelector((s) => s.bubble_zone);
  useEffect(() => {
    setBubbleZones(allBubbleZones.filter((b) => b.userId === userId));
  }, [allBubbleZones, userId]);

  return bubbleZones;
}

export const DefaultPaginationOptions = {
  hidePageListOnlyOnePage: true,
  hideSizePerPage: true,
};

export function isVersionPro(type?: string) {
  return ["Premium", "Pro"].includes(type as string);
}

export function getOffre() {
  const data = localStorage.getItem("offre");

  if (data) {
    const it = JSON.parse(data) as {
      sessionID: string;
      offre: string;
    };

    if (it.sessionID && it.offre) return it;
  }
  return null;
}
