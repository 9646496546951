import React, { useState } from "react";
import Swal from "sweetalert2";
import { updatePasswordAsync } from "../../../utils/api/auth.api";
import { SWAL_DEFAULT_PROPS } from "../../../utils/data";
import { useFormData } from "../../../utils/form";
import { FormError, SubmitButton } from "../../auth/Login";
import CloseModalBtn from "../../shared/CloseModalBtn";
type UpdatePasswordFormData = {
  password: string;
  password_confirm: string;
};
const ChangePasswordModal = ({ onHide }: { onHide: () => void }) => {
  const [loading, setLoading] = useState(false);
  const { errors, onSubmitForm, onChange, register } =
    useFormData<UpdatePasswordFormData>(
      {
        password: "",
        password_confirm: "",
      },
      (Yup) => ({
        password: Yup.string().required().label("Mot de passe").min(6),
        password_confirm: Yup.string().oneOf(
          [Yup.ref("password")],
          "Les mots de passe ne correspondent pas"
        ),
      })
    );

  const onSubmit = React.useCallback(async (data: UpdatePasswordFormData) => {
    setLoading(true);
    try {
      await updatePasswordAsync(data.password);
      setTimeout(() => {
        onHide();
        setTimeout(() => {
          Swal.fire({
            title: "Succès",
            text: "Mot de passe modifié avec succès",
            icon: "success",
            ...SWAL_DEFAULT_PROPS,
          });
          setLoading(false);
          onChange({ password: "", password_confirm: "" });
        }, 600);
      }, 400);
    } catch (ex) {
      const err = ex as { code: string };
      if (err.code === "auth/requires-recent-login") {
        Swal.fire({
          title: "Erreur",
          text: "Veuillez vous déconnecter et vous reconnecter pour modifier votre mot de passe",
          icon: "error",
          ...SWAL_DEFAULT_PROPS,
        });
      } else {
        Swal.fire({
          title: "Erreur",
          text: "Une erreur est survenue lors de la modification du mot de passe",
          icon: "error",
          ...SWAL_DEFAULT_PROPS,
        });
      }
      setLoading(false);
    }
  }, []);

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="admin-modal-title text-start" id="changePasswordLabel">
            Changer mot de passe
          </h5>
          <CloseModalBtn />
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitForm(onSubmit);
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="password"
                className="form-label form-label-modal-custom"
              >
                Nouveau MOT DE PASSE
              </label>
              <input
                type="password"
                className="form-control form-control-modal-custom"
                {...register("password")}
              />
              <FormError error={errors.password} />
            </div>
            <div className="mb-3">
              <label
                htmlFor="password_confirm"
                className="form-label form-label-modal-custom"
              >
                Confirmer MOT DE PASSE
              </label>
              <input
                type="password"
                className="form-control form-control-modal-custom"
                {...register("password_confirm")}
              />
              <FormError error={errors.password_confirm} />
            </div>
            <div className="gap-3 flex-r align-center mt-4">
              <button
                type="button"
                className="btn btn-cancelled"
                onClick={onHide}
              >
                Annuler
              </button>
              <SubmitButton title="Modifier" loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ChangePasswordModal;
