/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { IoCalendar } from "react-icons/io5";
import Skeleton from "react-loading-skeleton";
import "./DashboardAdmin.css";

import "react-datepicker/dist/react-datepicker.css";
import { AdminDisplay, useDashboardAdmin } from "../../../../utils/data";
import StatistiqueGeneralesV2 from "./StepNewDashboardV2/StatistiqueGeneralesV2";

export const CustomInput = forwardRef<any>(({ onClick }: any, ref) => (
  <button className="example-custom-input" onClick={onClick} ref={ref}>
    <IoCalendar
      className="proprietaire-detail-bubblezone-btn-filter-icon"
      // onClick={onClick}
      // ref={ref}
    />
  </button>
));

function NewDashboardAdminV2() {
  const [type, setType] = useState<AdminDisplay>("all");
  const [date, setDate] = useState<string>();
  const { data, loading: isLoading } = useDashboardAdmin(
    type,
    date ? moment(date) : undefined
  );

  const handleChange = (e) => {
    setDate(e);
    setType("all");
  };

  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-none p-3 mb-3">
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="proprietaire-detail-bubblezone-btn-action-container mb-5">
            <div className="calendar-contenaire">
              <DatePicker
                selected={date}
                onChange={handleChange}
                customInput={<CustomInput />}
              />
            </div>

            <button
              className={`btn proprietaire-detail-bubblezone-btn-filter ${
                type === "all" ? "active" : ""
              }`}
              onClick={() => {
                setDate(undefined);
                setType("all");
              }}
            >
              Global
            </button>
            <button
              className={`btn proprietaire-detail-bubblezone-btn-filter ${
                type === "1" ? "active" : ""
              }`}
              onClick={() => {
                setDate(undefined);
                setType("1");
              }}
            >
              Aujourd’hui
            </button>
            <button
              className={`btn proprietaire-detail-bubblezone-btn-filter ${
                type === "7" ? "active" : ""
              }`}
              onClick={() => {
                setDate(undefined);
                setType("7");
              }}
            >
              Cette semaine
            </button>
            <button
              className={`btn proprietaire-detail-bubblezone-btn-filter ${
                type === "30" ? "active" : ""
              }`}
              onClick={() => {
                setDate(undefined);
                setType("30");
              }}
            >
              Ce mois-ci
            </button>
            <button
              className={`btn proprietaire-detail-bubblezone-btn-filter ${
                type === "365" ? "active" : ""
              }`}
              onClick={() => {
                setDate(undefined);
                setType("365");
              }}
            >
              Cette année
            </button>
          </div>
          <div className="bubblezone-dash-admin mt-3">
            <div
              className="tab-content bubblezone-dash-tab-content"
              id="pills-tabContent"
            >
              <StatistiqueGeneralesV2
                type={type}
                date={date}
                data={data}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewDashboardAdminV2;

export const CardSkeleton = () => {
  return (
    <>
      {[...Array(6)]?.map((el, i) => (
        <div className="col-md-4 mb-3 dis-flex px-md-2" key={i}>
          <div className="custom-item-stat-dashboad-view">
            <div className="content-stat-dashboard-view">
              <div className="content-text-item-stat-dashboad-view ms-3">
                <div className="content-item-stat-dashboad-view mt-2">
                  <Skeleton
                    className="titre-item-stat-dashboad-view mb-1"
                    width={200}
                  />
                </div>
                <div className="icon-item-stat-dashboad-view">
                  <Skeleton
                    className="chiffre-item-stat-dashboad-view mb-1"
                    width={150}
                  />
                </div>
              </div>
              <div className="content-item-icon-stat-dashboard-view">
                <Skeleton
                  className="icon-item-stat-dashboad-view"
                  width={65}
                  height={65}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export const PieSkeleton = () => {
  return (
    <div className="custom-graph-container text-center">
      <Skeleton width={200} height={200} circle />
    </div>
  );
};

export const PassionSkeleton = () => {
  return (
    <>
      {[...Array(6)]?.map((el, i) => (
        <div className="custom-centre-iteret-item-container mb-3" key={i}>
          <Skeleton
            className="custom-centre-iteret-item-number"
            width={20}
            height={30}
          />
          <Skeleton className="custom-centre-iteret-item" width={200} />
        </div>
      ))}
    </>
  );
};
