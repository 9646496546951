import { UserInfos } from "../../../../../../types";
import { useGetMood } from "../../../../../utils/data";

function InfosPersonnellesUser({ user }: { user: UserInfos }) {
  const getMood = useGetMood();
  return (
    <div className="bubblezone-dash-admin-section-edit-profil-container py-4">
      <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Nom
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            {user?.pseudo}
          </div>
        </div>
      </div>
      <hr />
      <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Age
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            {user?.age}
          </div>
        </div>
      </div>
      <hr />
      <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Mail
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            {user?.email}
          </div>
        </div>
      </div>
      <hr />
      <div className="bubblezone-dash-admin-section-edit-profil-item">
        <div className="bubblezone-dash-admin-section-edit-profil-prenom">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
            Abonnement
          </div>
        </div>
        <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
          <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
            {user?.subscribed ? "Gold" : "Gratuit"}
          </div>
        </div>
      </div>
      <hr />

      <>
        <div className="bubblezone-dash-admin-section-edit-profil-item">
          <div className="bubblezone-dash-admin-section-edit-profil-prenom">
            <div className="bubblezone-dash-admin-section-edit-profil-prenom-item-deux">
              Mood
            </div>
          </div>
          <div className="bubblezone-dash-admin-section-edit-profil-btn-container">
            <div className="bubblezone-dash-admin-section-edit-profil-btn-item-deux">
              {getMood(user.mood)?.title}
            </div>
          </div>
        </div>
        <hr />
      </>
    </div>
  );
}

export default InfosPersonnellesUser;
