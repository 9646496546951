import React from 'react'
import './SidebarAdmin.css'
import SidebarAdminContent from './SidebarAdminContent'

const SidebarAdmin = () => {
    return (
        <div className="navbar-vertical-customisation no-view-mobile">
            <SidebarAdminContent />
        </div>
    )
}

export default SidebarAdmin