import VolkenoSkeleton from "volkeno-react-skeleton";

function ListeAdminsTableSkeleton() {
  return (
    <div className="col-md-12">
      <table className="table table-striped">
        <thead>
          <tr>
            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#010235",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              Prénom & Nom
            </th>

            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#010235",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              Téléphone
            </th>

            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#010235",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              Email
            </th>

            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#010235",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <VolkenoSkeleton height={20} variant="rect" />
            </th>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <VolkenoSkeleton height={20} variant="rect" />
            </th>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <VolkenoSkeleton height={20} variant="rect" />
            </th>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <VolkenoSkeleton height={20} variant="rect" />
            </th>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={15} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ListeAdminsTableSkeleton;
