// import { IoMdAdd } from "react-icons/io";
// import { useModalActions } from "../../../../utils/data";
// import AddAdminModal from "../../../modal/admin/AddAdminModal";
// import ListeUsersTable from "../../../tables/admin/ListeUsersTable/ListeUsersTable";
// import "./ListeUsers.css";

// const ListeUsers = () => {
//   const ma = useModalActions("AddGerantModal");
//   return (
//     <div className="dashboard-admin-component">
//       <div className="container-dashboard-page bg-white p-3">
//         <div className="col-left-dashboard col-left-dashboard-responsive">
//           <div className="admin-page-title-header-container">
//             <ul
//               className="nav nav-tabs nav-tabs-setting gap-5"
//               id="myTab"
//               role="tablist"
//             >
//               <li className="nav-item nav-item-setting" role="presentation">
//                 <button
//                   className="nav-link nav-link-setting active"
//                   id="users-tab"
//                   data-bs-toggle="tab"
//                   data-bs-target="#users"
//                   type="button"
//                   role="tab"
//                   aria-controls="users"
//                   aria-selected="false"
//                 >
//                   Utilisateurs
//                 </button>
//               </li>
//               <li className="nav-item nav-item-setting" role="presentation">
//                 <button
//                   className="nav-link nav-link-setting"
//                   id="gerants-tab"
//                   data-bs-toggle="tab"
//                   data-bs-target="#gerants"
//                   type="button"
//                   role="tab"
//                   aria-controls="gerants"
//                   aria-selected="false"
//                 >
//                   Gérants
//                 </button>
//               </li>
//             </ul>
//           </div>
//           <div className="tab-content" id="myTabContent">
//             <div
//               className="tab-pane fade show active bg-white p-3"
//               id="users"
//               role="tabpanel"
//               aria-labelledby="users-tab"
//             >
//               <ListeUsersTable />
//             </div>
//             <div
//               className="tab-pane fade  bg-white p-3"
//               id="gerants"
//               role="tabpanel"
//               aria-labelledby="gerants-tab"
//             >
//               <div>
//                 <div className="content-btn-add-theme flex-r mb-4">
//                   <button
//                     className="btn btn-add-theme"
//                     data-bs-toggle="modal"
//                     data-bs-target="#AddGerantModal"
//                   >
//                     <IoMdAdd />
//                     <span className="ps-2">Ajouter un gérant</span>
//                   </button>
//                 </div>
//                 <ListeUsersTable user={false} />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div
//         className="modal fade"
//         id="AddGerantModal"
//         aria-labelledby="AddGerantModalLabel"
//         aria-hidden="true"
//       >
//         <AddAdminModal
//           admin={false}
//           onHide={() => {
//             ma.hide();
//           }}
//         />
//       </div>
//     </div>
//   );
// };

// export default ListeUsers;


import { IoMdAdd } from "react-icons/io";
import { useModalActions } from "../../../../utils/data";
import AddAdminModal from "../../../modal/admin/AddAdminModal";
import ListeUsersTable from "../../../tables/admin/ListeUsersTable/ListeUsersTable";
import "./ListeUsers.css";

const ListeUsers = () => {
  const ma = useModalActions("AddGerantModal");
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="admin-page-title-header-container">
            <ul
              className="nav nav-tabs nav-tabs-setting gap-5"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item nav-item-setting" role="presentation">
                <button
                  className="nav-link nav-link-setting active"
                  id="users-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#users"
                  type="button"
                  role="tab"
                  aria-controls="users"
                  aria-selected="false"
                >
                  Utilisateurs
                </button>
              </li>
              <li className="nav-item nav-item-setting" role="presentation">
                <button
                  className="nav-link nav-link-setting"
                  id="gerants-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#gerants"
                  type="button"
                  role="tab"
                  aria-controls="gerants"
                  aria-selected="false"
                >
                  Gérants
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active bg-white p-3"
              id="users"
              role="tabpanel"
              aria-labelledby="users-tab"
            >
              <ListeUsersTable />
            </div>
            <div
              className="tab-pane fade  bg-white p-3"
              id="gerants"
              role="tabpanel"
              aria-labelledby="gerants-tab"
            >
              <div>
                <div className="content-btn-add-theme flex-r mb-4">
                  <button
                    className="btn btn-add-theme"
                    data-bs-toggle="modal"
                    data-bs-target="#AddGerantModal"
                  >
                    <IoMdAdd />
                    <span className="ps-2">Ajouter un gérant</span>
                  </button>
                </div>
                <ListeUsersTable user={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddGerantModal"
        aria-labelledby="AddGerantModalLabel"
        aria-hidden="true"
      >
        <AddAdminModal
          admin={false}
          onHide={() => {
            ma.hide();
          }}
        />
      </div>
    </div>
  );
};

export default ListeUsers;