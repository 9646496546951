import React from "react";
import { AiFillHome, AiTwotoneSetting } from "react-icons/ai";
import { BsFillChatDotsFill, BsFillPersonPlusFill } from "react-icons/bs";
import { FaGlassMartiniAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoBarChart, IoDiamondSharp } from "react-icons/io5";
import { MdInsertChart } from "react-icons/md";
import { RiQuestionnaireFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { useAppSelector, useUser } from "../../../../redux/root";
import { isVersionPro } from "../../../../utils/data";

type TypeNavbar = {
  link: string;
  tooltip: string;
  icon: React.JSX.Element;
  title: string | React.JSX.Element;
  classLi: string;
  classNav: string;
}[];

export const NavbarData: TypeNavbar = [
  {
    link: "/proprietaire/bubblezone",
    tooltip: "Accueil",
    icon: <AiFillHome />,
    title: "Accueil",
    classLi: "nav-item nav-item-vertical-custom",
    classNav: "nav-link nav-link-vertival-custom with-tooltip-menu",
  },
  {
    link: "/proprietaire/modifier-bubblezone",
    tooltip: "BubbleZones",
    icon: <FaGlassMartiniAlt />,
    title: "BubbleZones",
    classLi: "nav-item nav-item-vertical-custom position-relative",
    classNav: "nav-link nav-link-vertival-custom with-tooltip-menu",
  },
  {
    link: "/proprietaire/donnees-client",
    tooltip: "Données clients",
    icon: <MdInsertChart />,
    title: "Données clients",
    classLi: "nav-item nav-item-vertical-custom position-relative",
    classNav: "nav-link nav-link-vertival-custom with-tooltip-menu",
  },
  {
    link: "/proprietaire/assistance",
    tooltip: "Assistance",
    icon: <RiQuestionnaireFill />,
    title: "Assistance",
    classLi: "nav-item nav-item-vertical-custom position-relative",
    classNav: "nav-link nav-link-vertival-custom with-tooltip-menu",
  },
  {
    link: "/proprietaire/version-pro",
    tooltip: "Version upgrade",
    icon: <IoDiamondSharp />,
    title: (
      <span className="hiddenable-navlink-label">{"Version upgrade"}</span>
    ),
    classLi: "nav-item nav-item-vertical-custom position-relative mt-5",
    classNav:
      "nav-link nav-link-vertival-custom custom-nav-item-vertical-custom with-tooltip-menu",
  },
];

export const NavbarDataAdmin: TypeNavbar = [
  {
    link: "/admin/dashboard",
    tooltip: "Dashboard",
    icon: <IoBarChart />,
    title: "Dashboard",
    classLi: "nav-item nav-item-vertical-custom",
    classNav: "nav-link nav-link-vertival-custom with-tooltip-menu",
  },
  {
    link: "/admin/utilisateurs",
    tooltip: "Membres",
    icon: <BsFillPersonPlusFill />,
    title: "Membres",
    classLi: "nav-item nav-item-vertical-custom position-relative",
    classNav: "nav-link nav-link-vertival-custom with-tooltip-menu",
  },
  {
    link: "/admin/bubblezone",
    tooltip: "BubbleZone",
    icon: <FaLocationDot />,
    title: "BubbleZone",
    classLi: "nav-item nav-item-vertical-custom",
    classNav: "nav-link nav-link-vertival-custom with-tooltip-menu",
  },
  {
    link: "/admin/annonce",
    tooltip: "Annonce",
    icon: <BsFillChatDotsFill />,
    title: "Annonce",
    classLi: "nav-item nav-item-vertical-custom",
    classNav: "nav-link nav-link-vertival-custom with-tooltip-menu",
  },
  {
    link: "/admin/message-contact",
    tooltip: "Messages",
    icon: <BsFillChatDotsFill />,
    title: "Messages",
    classLi: "nav-item nav-item-vertical-custom",
    classNav: "nav-link nav-link-vertival-custom with-tooltip-menu",
  },
  {
    link: "/admin/parametres",
    tooltip: "Paramètres",
    icon: <AiTwotoneSetting />,
    title: "Paramètres",
    classLi: "nav-item nav-item-vertical-custom",
    classNav: "nav-link nav-link-vertival-custom with-tooltip-menu",
  },
];

const SidebaragenceContent: React.FC = () => {
  const user = useUser();
  const count = useAppSelector(
    (s) =>
      s.messages_contact.filter((f) => !f.status || f.status === "Non traité")
        .length
  );
  const isAdmin = user.is_admin || user.is_super_admin;
  const pre = isVersionPro(user?.abonnement);
  const filteredNavbarData = pre
    ? NavbarData.filter((item) => item.link !== "/proprietaire/version-pro")
    : NavbarData;
  return (
    <div className="navbar-vertical-customisation-v2 no-view-mobile navbar-vertical-customisation-admin">
      <div className="hoverable mt-4" id="scrollbar">
        <div className="content">
          <div className="move-left-side-menu"></div>
          <ul className="nav nav-items-custom flex-column pt-4">
            {isAdmin &&
              NavbarDataAdmin.map((navbar, i) => (
                <li className={navbar.classLi} key={i}>
                  <NavLink
                    className={navbar.classNav}
                    to={navbar.link}
                    data-tooltip-content={navbar.tooltip}
                  >
                    {navbar.icon}
                    {navbar.link === "/admin/message-contact" ? (
                      <span className="hiddenable-navlink-label">
                        {navbar.title}{" "}
                        <span className="badge badge-primary">{count}</span>
                      </span>
                    ) : (
                      <span className="hiddenable-navlink-label">
                        {navbar.title}
                      </span>
                    )}
                  </NavLink>
                </li>
              ))}

            {/* Routes propriétaires  */}
            {!isAdmin &&
              filteredNavbarData.map((navbar, i) => (
                <li className={navbar.classLi} key={i}>
                  <NavLink
                    className={navbar.classNav}
                    to={navbar.link}
                    data-tooltip-content={navbar.tooltip}
                  >
                    {navbar.icon}
                    <span className="hiddenable-navlink-label">
                      {navbar.title}
                    </span>
                  </NavLink>
                </li>
              ))}
            {/* Routes propriétaires  */}
          </ul>
        </div>
      </div>
    </div>
    // ============================
  );
};

export default SidebaragenceContent;
