import React from "react";
import Swal from "sweetalert2";
import { UserFormData, UserInfos } from "../../../../types";
import {
  createProprietaireFunction,
  createUserFunction,
  generateUserCode,
  updateUserAsync,
} from "../../../utils/api/user.api";
import { ABONNEMENT, SWAL_DEFAULT_PROPS } from "../../../utils/data";
import { useFormData } from "../../../utils/form";
import { FormError, SubmitButton } from "../../auth/Login";
import GooglePlaceInput from "../../common/GooglePlaceInput";
import CloseModalBtn from "../../shared/CloseModalBtn";

const AddAdminModal = ({
  item,
  admin = true,
  onHide,
}: {
  item?: UserInfos;
  onHide: () => void;
  admin?: boolean;
}) => {
  const {
    errors,
    setErrors,
    onChange,
    onSubmitForm,
    register,
    registerSelect,
    data,
  } = useFormData<UserFormData>(
    {
      email: item?.email ?? "",
      pseudo: item?.pseudo ?? "",
      phone: item?.phone ?? "",
      abonnement: item?.abonnement ?? "Basic",
      ...(!admin
        ? {
            code: item?.code || generateUserCode(),
            raison_social: item?.raison_social || "",
            numero_siret: item?.numero_siret || "",
            address: item?.address || "",
          }
        : {}),
      ...(item
        ? {}
        : {
            age: "0",
            is_admin: admin,
            is_super_admin: false,
            is_proprietaire: !admin,
            jetons: 0,
            orientation_sexuelle: "Hétérosexuel",
            mood: "Date",
            subjects: [],
            sexe: "Homme",
          }),
    },
    (Yup) => ({
      email: Yup.string().email().required().label("Email"),
      pseudo: Yup.string().required().label("Prénom & Nom"),
      // abonnement: Yup.string().required().label("Abonnement"),
      phone: Yup.string().required().label("Téléphone"),
      raison_social: admin
        ? Yup.string().notRequired()
        : Yup.string().required().label("Raison sociale"),
      numero_siret: admin
        ? Yup.string().notRequired()
        : Yup.string().required().label("Numéro de siret"),
      address: admin
        ? Yup.string().notRequired()
        : Yup.string().required().label("Adresse postale"),
    })
  );
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(
    async (data: UserFormData) => {
      setLoading(true);
      try {
        if (item) {
          await updateUserAsync(item.uid, data);
          onHide();
          setTimeout(() => {
            Swal.fire({
              title: "Succès",
              text: "Informations modifiées avec succès",
              icon: "success",
              ...SWAL_DEFAULT_PROPS,
            });
          }, 400);
        } else {
          type Res = { data: { ok: boolean } } | null;
          let res: { data: { ok: boolean } } | null = null;
          if (admin) {
            res = (await createUserFunction(data)) as Res;
          } else {
            res = (await createProprietaireFunction(data)) as Res;
          }
          if (res?.data?.ok) {
            onHide();
            setTimeout(() => {
              Swal.fire({
                title: "Succès",
                text: `${admin ? "Administrateur" : "Gérant"} crée avec succès`,
                icon: "success",
                ...SWAL_DEFAULT_PROPS,
              });
              onChange({
                email: "",
                address: "",
                pseudo: "",
                phone: "",
              });
            }, 400);
          } else {
            if (res?.data?.ok === false) {
              setErrors({ email: "Adresse E-Mail déjà utilisée" });
            } else {
              setErrors({ email: "Une erreur est survenue lors de l'ajout" });
            }
          }
        }
      } catch (ex) {
        setErrors({ email: "Une erreur est survenue lors de l'ajout" });
        console.log("add error", ex);
      }
      setLoading(false);
    },
    [onHide]
  );
  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header py-2 flex-m">
          <h5 onClick={() => onHide()} className="admin-modal-title text-start">
            {item ? "Modifier" : "Ajouter"} un{" "}
            {admin ? "administrateur" : "Gérant"}
          </h5>
          <CloseModalBtn onClick={onHide} />
        </div>
        <div className="modal-body text-start">
          <div className="container-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm(onSubmit);
              }}
            >
              <div className="row">
                <div className={"col-md-12"}>
                  <div className="mb-3">
                    <label
                      htmlFor="prenom"
                      className="form-label form-label-modal-custom"
                    >
                      Prénom & Nom
                    </label>
                    <input
                      type="text"
                      placeholder="Prénom & Nom"
                      className="form-control form-control-modal-custom"
                      {...register("pseudo")}
                    />
                    <FormError error={errors.pseudo} />
                  </div>
                </div>
                {!item && (
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label form-label-modal-custom"
                      >
                        Adresse mail
                      </label>
                      <input
                        type="email"
                        placeholder="Adresse mail"
                        className="form-control form-control-modal-custom"
                        {...register("email")}
                      />
                      <FormError error={errors.email} />
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="phone"
                      className="form-label form-label-modal-custom"
                    >
                      Téléphone
                    </label>
                    <input
                      type="text"
                      placeholder="Téléphone"
                      className="form-control form-control-modal-custom"
                      {...register("phone")}
                    />
                    <FormError error={errors.phone} />
                  </div>
                </div>
                {!admin && (
                  <>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="raison_social"
                          className="form-label form-label-modal-custom"
                        >
                          Raison sociale
                        </label>
                        <input
                          type="text"
                          placeholder="Raison sociale"
                          className="form-control form-control-modal-custom"
                          {...register("raison_social")}
                        />
                        <FormError error={errors.raison_social} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="numero_siret"
                          className="form-label form-label-modal-custom"
                        >
                          Numéro de siret
                        </label>
                        <input
                          type="text"
                          placeholder="Numéro de siret"
                          className="form-control form-control-modal-custom"
                          {...register("numero_siret")}
                        />
                        <FormError error={errors.numero_siret} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="address"
                          className="form-label form-label-modal-custom"
                        >
                          Adresse postale
                        </label>
                        <GooglePlaceInput
                          onChange={(data) => {
                            onChange({
                              address: data.rue,
                            });
                          }}
                          value={data.address as string}
                          className="form-control form-control-modal-custom"
                          placeholder="D2 rue des bistrots, 33000 Bordeaux"
                        />
                        <FormError error={errors.address} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className={`mb-3 w-100`}>
                        <label
                          htmlFor={"sexe"}
                          className={`form-label form-label-modal-custom`}
                          aria-labelledby={"sexe"}
                        >
                          Abonnement
                        </label>
                        <select
                          className={`form-control form-control-modal-custom`}
                          {...registerSelect("abonnement")}
                          id={"sexe"}
                        >
                          {ABONNEMENT.map((s) => (
                            <option value={s?.value} key={s.value}>
                              {s.title}
                            </option>
                          ))}
                        </select>
                        {<FormError error={errors?.abonnement} />}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="gap-3 flex-r align-center mt-4">
                <div className="btn-actions">
                  <button
                    type="button"
                    className="btn btn-cancelled"
                    onClick={onHide}
                  >
                    Fermer
                  </button>
                </div>
                <div className="btn-actions">
                  <SubmitButton title="Terminer" loading={loading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdminModal;
