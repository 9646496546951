/* eslint-disable @typescript-eslint/no-use-before-define */
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ReactNode, useState } from "react";
import { Pie } from "react-chartjs-2";
import { DashboardStateGraph, Mood } from "../../../../../types";
import UpgradeVersionModal from "../../../modal/proprietaire/UpgradeVersionModal";
import "./OwnerListeBubbleZone.css";

ChartJS.register(ArcElement, Tooltip, Legend);
type Avg = {
  count: number;
  mood: Mood;
};
const GraphProprietaire = ({ avg = [] }: { avg: Avg[] }) => {
  return (
    <GenericGraphe
      data={avg.map((a) => ({
        title: a.mood.title,
        count: a.count,
        color: a.mood.color,
        image: a.mood.image,
      }))}
      renderLabels={(data) => {
        return (
          <div
            className="container-legend-mood-graph mt-4"
            style={{
              justifyContent: "center",
              alignItems: "center",
              gap: 45,
            }}
          >
            {data.map((item) => (
              <div key={item.title} className="content-mood-detail-dashboard">
                <img src={item.image} style={{ width: 40, height: 40 }} />
                <p className="pourcentage-mood-detail-dashboard mb-0">
                  {item.title}
                </p>
              </div>
            ))}
          </div>
        );
      }}
    />
  );
};

export default GraphProprietaire;

export const GenericGraphe = ({
  data = [],
  title = "Mood",
  renderLabels,
  Width,
  Heigth,
  customClass,
  disabled,
}: {
  data: DashboardStateGraph[];
  renderLabels: (data: DashboardStateGraph[]) => ReactNode;
  title?: string;
  Width?: number;
  Heigth?: number;
  customClass?: string;
  disabled?: boolean;
}) => {
  const total = data.reduce((ac, cur) => ac + cur.count, 0);
  const empty = total === 0 && data.length > 0;
  const config = {
    labels: data.map((a) => a.title),
    datasets: [
      {
        label: title,
        data: data.map((a) => a.count),
        backgroundColor: data.map((d) => d.color ?? "#FFF"),
        hoverBackgroundColor: data.map((d) => d.color ?? "#FFF"),
        borderColor: data.map((d) => d.color ?? "#FFF"),
        borderWidth: 1,
        datalabels: {
          color: "#fff",
          formatter: function (value) {
            if (value === 0 || total <= 0) return null;
            const percent = (value / total) * 100;
            return Math.round(percent) + "%";
          },
          anchor: "center",
          font: {
            weight: "bold",
          },
        },
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    ...(disabled ? { events: [] } : {}),
    plugins: {
      legend: {
        position: "bottom",
        display: false,
        itemWrap: true,
        offset: false,
      },
      datalabels: {
        color: "#fff",
      },
    },
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleMouseEnter = () => {
    if (disabled) {
      setShow(true);
    }
  };

  let classes = customClass ? customClass : "";
  if (disabled) {
    classes += " blured";
  }
  // console.log({ classes });
  return (
    <>
      {!empty && (
        <div
          style={{ width: Width ? Width : 400, height: Heigth ? Heigth : 400 }}
          className={classes}
          onMouseEnter={handleMouseEnter}
        >
          <Pie data={config} options={options} plugins={[ChartDataLabels]} />
        </div>
      )}
      {empty && <p className="text-center my-5">Pas de données disponible</p>}
      {!!renderLabels && renderLabels(data)}
      {disabled && <div></div>}

      {/* Modal */}
      <UpgradeVersionModal show={show} handleClose={handleClose} />
    </>
  );
};

function Disabled() {}
