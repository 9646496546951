import { useEffect, useState } from "react";
import { TbCloudUpload } from "react-icons/tb";
import Swal from "sweetalert2";
import { SocialPicture, TypeSocialPicture } from "../../../../../../types";
import { uploadSocialPictureAsync } from "../../../../../utils/api/storage.api";
import {
  getSocialPictures,
  updateSocialPictures,
} from "../../../../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS } from "../../../../../utils/data";

function RSInstagramTab({ type }: { type: TypeSocialPicture }) {
  const [pictures, setPictures] = useState<SocialPicture[]>([]);
  useEffect(() => {
    getSocialPictures(type).then((res) => {
      setPictures(res);
    });
  }, []);
  return (
    <div className="row">
      {pictures.length === 0 && <h4>Chargement...</h4>}
      {pictures.map((item, index) => (
        <DownloadableRS
          onUpdate={async (updated) => {
            const newPics = [...pictures];
            newPics[index] = updated;
            await updateSocialPictures(type, newPics);
            setPictures(newPics);
            return true;
          }}
          item={item}
          type={type}
          key={item.day}
        />
      ))}
    </div>
  );
}

export default RSInstagramTab;

type DownloadableRSProps = {
  item: SocialPicture;
  type: TypeSocialPicture;
  onUpdate: (item: SocialPicture) => Promise<boolean>;
};
export function DownloadableRS({ item, type, onUpdate }: DownloadableRSProps) {
  const [loading, setLoading] = useState(false);
  const [pic, setPic] = useState(item.picture);
  const inputId = `${type}_${item.day}`;

  const onSubmit = async (picture: File) => {
    setLoading(true);
    try {
      setPic(URL.createObjectURL(picture));
      const img = await uploadSocialPictureAsync(picture);
      const newItem: SocialPicture = {
        ...item,
        title: picture.name,
        size: picture.size,
        picture: img,
      };
      await onUpdate(newItem);

      Swal.fire({
        title: "Succès",
        text: "Image ajoutée avec succès",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex) {
      console.log("error", ex);
      setPic(item.picture);
      Swal.fire({
        title: "Erreur",
        text: "Une erreur est survenue",
        icon: "error",
        ...SWAL_DEFAULT_PROPS,
      });
    }
    setLoading(false);
  };
  return (
    <div className="col-md-6 mb-3">
      <div className="rs-downloadable-item">
        <div className="row">
          <div className="col-md-8 mb-3 mb-md-0">
            <div className="rs-downloadable-item-image-container">
              {!!pic && (
                <img
                  src={pic}
                  alt="downloadable social media"
                  className="rs-downloadable-item-image"
                />
              )}
              <div className="rs-downloadable-item-name-container">
                <div className="rs-downloadable-item-name">{item.title}</div>
                <div className="rs-downloadable-item-day">{item.day}</div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-md-0">
            <div className="d-flex justify-content-end w-100">
              <button
                onClick={() => {
                  document.getElementById(inputId)?.click();
                }}
                disabled={loading}
                className="btn btn-rs-downloadable-item"
              >
                {loading ? "Chargement..." : "Importer/Modifier"}{" "}
                <TbCloudUpload />
              </button>
              <input
                id={inputId}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const files = e.target.files;
                  if (files && files.length > 0) {
                    onSubmit(files[0]);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const data = [
  {
    id: 1,
    day: "Lundi",
  },
  {
    id: 2,
    day: "Mardi",
  },
  {
    id: 3,
    day: "Mercredi",
  },
  {
    id: 4,
    day: "Jeudi",
  },
  {
    id: 5,
    day: "Vendredi",
  },
  {
    id: 6,
    day: "Samedi",
  },
  {
    id: 7,
    day: "Dimanche",
  },
];
