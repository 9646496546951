import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { TiInfoLarge } from "react-icons/ti";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  BubbleZone,
  BubbleZoneFormData,
  Hour,
  UserInfos,
} from "../../../../../types";
import { useAppSelector, useUser } from "../../../../redux/root";
import {
  createBubbleZoneAsync,
  generateBubbleCode,
  getUserByCode,
  updateBubbleZoneAsync,
} from "../../../../utils/api/user.api";
import { isVersionPro, SWAL_DEFAULT_PROPS } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { FormError } from "../../../auth/Login";
import { BtnSubmit, GoBackButton } from "../../../common/Button";
import GooglePlaceInput from "../../../common/GooglePlaceInput";
import { Input } from "../../../common/Input";
import MyDropzone from "../../../common/MyDropzone";
import PreviewModal from "./PreviewModal";
const days: Hour["day"][] = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
const defaultOurs: Hour[] = days.map((day) => ({
  day,
  start_hour: "18:00",
  end_hour: "02:00",
  enabled: true,
}));

function AjouterBubblezoneOwner({ item }: { item?: BubbleZone }) {
  const user = useUser();
  const isAdmin = !user.is_proprietaire;
  const [gerant, setGerant] = useState<UserInfos>();
  const [image, setImage] = useState<string>();
  const [hours, setHours] = useState(item?.hours ?? defaultOurs);
  const listTypes = useAppSelector((s) => s.types);
  const typesEtablissements = useAppSelector((s) => s.typesEtablissements);
  const [types, setTypes] = useState<string[]>([]);
  const navigate = useNavigate();
  // G+YY559 pro221 G+CDL4I
  const defaultData: BubbleZoneFormData = isAdmin
    ? {
        code_gerant: item?.code_gerant ?? "G+17MSG",
      }
    : {};

  useEffect(() => {
    if (listTypes.length > 0 && item?.types) {
      const types = item.types.filter(
        (t) => listTypes.findIndex((l) => l.id === t) !== -1
      );
      setTypes(types);
    }
  }, [listTypes]);
  useEffect(() => {
    if (item?.image) {
      setImage(item.image);
    }
  }, [item]);

  const { errors, data, setErrors, onChange, onSubmitForm, register } =
    useFormData<BubbleZoneFormData>(
      {
        address: item?.address ?? "",
        description: item?.description ?? "",
        latitude: item?.latitude ?? "0",
        longitude: item?.longitude ?? "0",
        type: item?.type ?? "",
        phone: item?.phone ?? "",
        name: item?.name ?? "",
        web_site: item?.web_site ?? "",
        code_gerant: item?.code_gerant ?? defaultData.code_gerant,
        ...(item
          ? {}
          : {
              total_jeton: 0,
              image: undefined,
              average_rate: 3,
              enable_notification: true,
              code: generateBubbleCode(),
            }),
      },
      (Yup) => ({
        address: Yup.string().required().label("Adresse"),
        code_gerant:
          !item && isAdmin
            ? Yup.string().required()
            : Yup.string().notRequired(),
        // description: Yup.string().required().label("Description"),
        phone: Yup.string().required().label("Téléphone"),
        type: Yup.string().required().label("Types d’établissements"),
        name: Yup.string().required().label("Nom"),
        // latitude: Yup.number().required().label("Latitude"),
        // longitude: Yup.number().required().label("longitude"),
        web_site: Yup.string().required().label("Site web"),
        image: Yup.mixed()
          .label("Image")
          .test(
            "is-image",
            "Veuillez télécharger une image (PNG,JPEG,JPG,GIF)(900x500)",
            (value) => {
              if (item && !value) return true;
              if (!value || !(value instanceof File)) return false;
              return [
                "image/gif",
                "image/jpg",
                "image/jpeg",
                "image/png",
              ].includes(value.type.toLowerCase());
            }
          ),
      })
    );

  useEffect(() => {
    if (isAdmin) {
      getUserByCode(data.code_gerant as string).then((user) => {
        setGerant(user);
      });
    } else {
      setGerant(user);
    }
  }, [data.code_gerant, user, isAdmin]);

  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(
    async (data: BubbleZoneFormData) => {
      setLoading(true);
      try {
        if (isAdmin) {
          if (gerant) {
            data.userId = gerant.uid;
          } else if (data.code_gerant) {
            setErrors({
              code_gerant: "Le gérant avec ce code n'existe pas!",
            });
            setLoading(false);
            return;
          }
        } else {
          data.code_gerant = user.code;
          data.userId = user.uid;
        }
        data.hours = hours;
        data.types = types;
        if (item) {
          await updateBubbleZoneAsync(item.id, data);
        } else {
          await createBubbleZoneAsync(data);
        }
        Swal.fire({
          title: "Succès",
          text: item
            ? "BubbleZone modifié avec succès"
            : "BubbleZone crée avec succès",
          icon: "success",
          ...SWAL_DEFAULT_PROPS,
        });
        navigate(-1);
      } catch (ex) {
        setErrors({ name: "Une erreur est survenue lors de l'ajout" });
        console.log("add error", ex);
      }
      setLoading(false);
    },
    [types, hours, gerant]
  );
  let limit = 0;
  if (gerant?.abonnement === "Professionnel") {
    limit = 3;
  }
  if (isVersionPro(gerant?.abonnement)) {
    limit = 9;
  }
  const limitReached = types.length >= limit;
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="container-dashboard-page bg-white p-3">
            <div className="bubblezone-page-title-container">
              <button
                className="bubblezone-page-title-btn-back"
                onClick={() => navigate(-1)}
              >
                <BsArrowLeft className="bubblezone-page-title-btn-back-icon" />
              </button>
              <div className="bubblezone-page-title">
                {item ? "Modifier une BubbleZone" : "Ajouter une BubbleZone"}
              </div>
            </div>
            <div className="container-form">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmitForm(onSubmit);
                }}
                className="mt-5 bubblezone-ajout-bubble-form"
              >
                <div className="row">
                  <div className="col-md-4">
                    <div className="bubblezone-ajout-bubble-form-label">
                      Nom de l’établissement
                    </div>
                  </div>
                  <div className="col-md-8 mb-3">
                    <Input
                      type="text"
                      {...register("name")}
                      placeholder="Nom de l’établissement"
                    />
                  </div>
                  {!!errors.name && (
                    <div className="col-md-12">
                      <FormError error={errors.name} />
                    </div>
                  )}
                  <div className="col-md-4">
                    <div className="bubblezone-ajout-bubble-form-label">
                      Téléphone
                    </div>
                  </div>
                  <div className="col-md-8 mb-3">
                    <Input
                      type="text"
                      {...register("phone")}
                      placeholder="Téléphone"
                    />
                  </div>
                  {!!errors.phone && (
                    <div className="col-md-12">
                      <FormError error={errors.phone} />
                    </div>
                  )}
                  <div className="col-md-4">
                    <div className="bubblezone-ajout-bubble-form-label">
                      Adresse de l’établissement
                    </div>
                  </div>
                  <div className="col-md-8 mb-3">
                    <GooglePlaceInput
                      onChange={(data) => {
                        onChange({
                          address: data.rue,
                          latitude: data.latitude,
                          longitude: data.longitude,
                        });
                      }}
                      value={data.address as string}
                      className="form-control form-control-modal-custom"
                      placeholder="Adresse"
                    />
                  </div>
                  {!!errors.address && (
                    <div className="col-md-12">
                      <FormError error={errors.address} />
                    </div>
                  )}

                  <div className="col-md-4">
                    <div className="bubblezone-ajout-bubble-form-label">
                      URL du réseau social de votre choix
                    </div>
                  </div>
                  <div className="col-md-8 mb-3">
                    <div className="input-infos-container">
                      <Input
                        type="text"
                        {...register("web_site")}
                        placeholder="Facebook/lorem"
                        disabled={user.abonnement === "Basic"}
                        customClassInput={
                          user.abonnement === "Basic"
                            ? "custom-blur-effect custom-cursor-disabled"
                            : ""
                        }
                      />
                      <div className="input-infos-icon-container">
                        <TiInfoLarge className="input-infos-icon" />
                      </div>
                      {user.abonnement === "Basic" && (
                        <div className="custom-brn-debloque-when-disable w-75">
                          <NavLink
                            to="/proprietaire/version-pro"
                            className="custom-btn-debloquer-maintenant mb-3"
                          >
                            Débloquer maintenant
                          </NavLink>
                          <div className="custom-text-debloquer-maintenant">
                            En passant à la version MyBubbleZone PRO
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {!!errors.web_site && (
                    <div className="col-md-12">
                      <FormError error={errors.web_site} />
                    </div>
                  )}

                  <div className="col-md-12">
                    <div className="bubblezone-ajout-bubble-form-label">
                      Heure d’ouverture et de fermeture
                    </div>
                  </div>
                  <div className="col-md-12 mb-5">
                    <div className="bubblezone-heure-ouverture-container">
                      <div>
                        <div className="mb-2 bubblezone-heure-ouverture-label mb-1">
                          Ouverture
                        </div>
                        <div className="mb-1 bubblezone-heure-ouverture-label mb-1">
                          Fermeture
                        </div>
                      </div>
                      {hours.map((item) => (
                        <div className="" key={item.day}>
                          <div className="mb-1 form-check">
                            <input
                              type="checkbox"
                              className="form-check-input add-bubble-custom-form-check-input"
                              checked={item.enabled}
                              onChange={(e) => {
                                setHours((old) => {
                                  const index = old.indexOf(item);
                                  old[index] = {
                                    ...old[index],
                                    enabled: e.target.checked,
                                  };
                                  return [...old];
                                });
                              }}
                              id={`exampleCheck${item.day}`}
                            />
                            <label
                              className="form-check-label add-bubble-custom-form-check-label"
                              htmlFor={`exampleCheck${item.day}`}
                            >
                              {item.day}
                            </label>
                          </div>
                          <div className="mb-1 add-bubble-heure-dispo-container">
                            <input
                              type="time"
                              placeholder="Ouverture"
                              value={item.start_hour}
                              onChange={(e) => {
                                setHours((old) => {
                                  const index = old.indexOf(item);
                                  old[index] = {
                                    ...old[index],
                                    start_hour: e.target.value,
                                  };
                                  return [...old];
                                });
                              }}
                            />
                          </div>
                          <div className="mb-1 add-bubble-heure-dispo-container">
                            <input
                              type="time"
                              placeholder="Fermeture"
                              value={item.end_hour}
                              onChange={(e) => {
                                setHours((old) => {
                                  const index = old.indexOf(item);
                                  old[index] = {
                                    ...old[index],
                                    end_hour: e.target.value,
                                  };
                                  return [...old];
                                });
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="bubblezone-ajout-bubble-form-label mb-2">
                      Photo du bar dans l’application (900x300)
                    </div>
                  </div>
                  <div className="col-md-12 mb-4">
                    <MyDropzone
                      setImages={(image) => onChange("image", image)}
                    />
                    {data.image instanceof File ? (
                      <img
                        style={{ width: 100, height: "auto" }}
                        src={URL.createObjectURL(data.image)}
                      />
                    ) : (
                      image && (
                        <img
                          style={{ width: 100, height: "auto" }}
                          src={image}
                        />
                      )
                    )}
                    <FormError error={errors.image as string} />
                  </div>
                  <div className="col-md-12">
                    <div className="bubblezone-ajout-bubble-form-label mb-3">
                      Type d’établissement
                    </div>
                  </div>
                  <div className="col-md-12 mb-5">
                    <div className="bubblezone-add-bubblezone-type-container">
                      {typesEtablissements.map((type) => {
                        const selected = type.id === data.type;
                        return (
                          <div
                            key={type.id}
                            onClick={() => {
                              onChange("type", type.id);
                            }}
                            className={`bubblezone-add-bubblezone-type ${
                              selected ? "active" : ""
                            }`}
                          >
                            {type.title}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {isAdmin && (
                    <>
                      <div className="col-md-4">
                        <div className="bubblezone-ajout-bubble-form-label">
                          Code du gérant
                        </div>
                      </div>
                      <div className="col-md-8 my-3">
                        <Input
                          type="text"
                          placeholder="G+"
                          {...register("code_gerant")}
                        />
                      </div>

                      {!!errors.code_gerant && (
                        <div className="col-md-12">
                          <FormError error={errors.code_gerant} />
                        </div>
                      )}
                    </>
                  )}
                  <div className="col-md-12">
                    <div className="bubblezone-ajout-bubble-form-label mb-3">
                      Tags {types.length} / {limit}
                    </div>
                  </div>
                  <div className="col-md-12 mb-5">
                    <div className={` position-relative`}>
                      <div
                        className={`bubblezone-add-bubblezone-type-container ${
                          limitReached && !types.length
                            ? "custom-blur-effect"
                            : ""
                        }`}
                      >
                        {listTypes.map((type) => {
                          const selected = types.includes(type.id);
                          const disabled = !selected && limitReached;
                          return (
                            <div
                              key={type.id}
                              onClick={() => {
                                if (!disabled) {
                                  setTypes((old) => {
                                    if (old.includes(type.id))
                                      return old.filter((f) => f !== type.id);

                                    return [...old, type.id];
                                  });
                                }
                              }}
                              className={`bubblezone-add-bubblezone-type ${
                                types.includes(type.id) ? "active" : ""
                              } ${disabled ? "disabled" : ""}`}
                            >
                              {type.title}
                            </div>
                          );
                        })}
                      </div>
                      {limitReached && !types.length && (
                        <div className="custom-brn-debloque-when-disable">
                          <NavLink
                            to="/proprietaire/version-pro"
                            className="custom-btn-debloquer-maintenant mb-3"
                          >
                            Débloquer maintenant
                          </NavLink>
                          <div className="custom-text-debloquer-maintenant">
                            En passant à la version MyBubbleZone PRO
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="flex-r pt-5 gap-2 custom-modal-btn-action-container">
                      <GoBackButton label="Annuler" />
                      <button
                        type="button"
                        className={`btn auth-submit-btn-preview-short `}
                        onClick={() => setModalShow(true)}
                      >
                        Préview
                      </button>
                      <BtnSubmit
                        label={item ? "Mofifier" : "Ajouter"}
                        isLoading={loading}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <PreviewModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        image={data.image ? URL.createObjectURL(data.image as File) : image}
      />
    </div>
  );
}

export default AjouterBubblezoneOwner;
