import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { AiFillEye } from "react-icons/ai";
import {
  getAvatar,
  MemberInBubble,
  useGetMood,
  useModalActions,
} from "../../../../utils/data";
import DetailParticipantModal from "../../../modal/admin/DetailParticipantModal";
import { useLocation, useNavigate } from "react-router-dom";

export function ShowButton({
  item,
  newPage,
  addPath
}: {
  item: MemberInBubble;
  newPage?: Boolean;
  addPath?: string;
}) {
  const modalId = `DetailParticipantModal${item.user.uid}`;
  const ma = useModalActions(modalId);
  let location = useLocation();
  let navigate = useNavigate();
  // console.log("location?.pathname", location,item)
  return (
    <>
      <button
        className="btn btn-action-modal-icon with-tooltip"
        data-tooltip-content="Voir"
        onClick={() =>
          !newPage
            ? ma.show()
            : navigate(location.pathname + `/${addPath ? addPath + "/" : ""}` + item.user.uid, {
                state: item?.user,
              })
        }
      >
        <AiFillEye />
      </button>
      <div
        className="modal fade"
        id={modalId}
        aria-labelledby="DetailParticipantModalLabel"
        aria-hidden="true"
      >
        <DetailParticipantModal item={item} />
      </div>
    </>
  );
}

function ListeParticipantTable({ data }: { data: MemberInBubble[] }) {
  const getMood = useGetMood();
  const nameFormatter = (cell: string, row: MemberInBubble) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <img
          src={getAvatar(row.user)}
          alt="type document"
          className="photo-bubblezone-table"
        />{" "}
        <span className="ps-2">{row.user.pseudo}</span>
      </div>
    );
  };

  const actionFormatter = (cell: string, row: MemberInBubble) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes">
          <div className="container-btn-action-icon-modal">
            <ShowButton item={row} />
          </div>
        </div>
      </>
    );
  };

  const options = {
    pageStartIndex: 1,
    sizePerPage: 5,
    hideSizePerPage: true,
  };

  const columns = [
    {
      dataField: "nom",
      text: "Pseudo",
      formatter: nameFormatter,
    },
    {
      dataField: "jetons",
      formatter: (cell: string, row: MemberInBubble) => 0,
      text: "Jetons",
    },
    {
      dataField: "mood",
      text: "Mood",
      formatter: (cell: string, row: MemberInBubble) => {
        const mood = getMood(row.inBulle.mood);
        if (mood) {
          return <img src={mood.image} style={{ width: 40, height: 40 }} />;
        }
        return null;
      },
    },
    {
      dataField: "date",
      formatter: (cell: string, row: MemberInBubble) =>
        moment(row.inBulle.date).format("DD/MM/YYYY"),
      text: "Date",
    },
    {
      dataField: "message",
      formatter: (cell: string, row: MemberInBubble) => 0,
      text: "Messages",
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={data}
        columns={columns}
        bordered={true}
        condensed={false}
        responsive={true}
        wrapperClasses="table-responsive admin-table"
        noDataIndication={() => "No data"}
        pagination={paginationFactory(options)}
      />
    </>
  );
}

export default ListeParticipantTable;
