// import { useCallback, useEffect, useState } from "react";
// import { FileWithPath, useDropzone } from "react-dropzone";
// import { MdAddAPhoto } from "react-icons/md";

// function MyDropzone({ setValue, setImages }) {
//   const [maxFileError, setMaxFileError] = useState<string>("");
//   const onDrop = useCallback((acceptedFiles, fileRejections) => {
//     if (acceptedFiles) {
//       setImages(acceptedFiles);
//       setValue("images", acceptedFiles);
//       console.log(acceptedFiles);
//     }
//     fileRejections.forEach((file) => {
//       file.errors.forEach((err) => {
//         if (err.code === "too-many-files") {
//           setMaxFileError("Maximum 4 images");
//         }
//         if (err.code === "file-invalid-type") {
//           setMaxFileError("Types de fichiers non prises en charge");
//         }
//       });
//     });
//   }, []);

//   const { getRootProps, getInputProps, fileRejections } = useDropzone({
//     onDrop,
//     maxFiles: 4,
//     multiple: true,
//     maxSize: 209715200,
//     // maxSize: 1000,
//   });

//   const fileRejectionItems = fileRejections.map(({ file, errors }, index) => {
//     return (
//       <div key={index}>
//         {/* {file.size} bytes */}
//         <ul className="p-0">
//           {errors.map((e) => (
//             <li
//               key={e.code}
//               style={{ listStyle: "none" }}
//               className="text-danger"
//             >
//               {e.code === "file-too-large" &&
//                 `L'image ${index + 1} est trop grande`}
//             </li>
//           ))}
//         </ul>
//       </div>
//     );
//   });

//   useEffect(() => {
//     setTimeout(() => setMaxFileError(""), 3000);
//   });
//   return (
//     <section className="dropzone">
//       {/* <label htmlFor="annonces-images" className="espace-client-form-label">
// 				label
// 			</label> */}
//       <div
//         {...getRootProps()}
//         style={{
//           border: ".2px solid #959595",
//           overflow: "hidden",
//           opacity: 1,
//           borderRadius: "7px",
//           display: "flex",
//           justifyContent: "center",
//           padding: "3rem 15px",
//           background: "rgb(243, 243, 243)",
//         }}
//       >
//         <input
//           {...getInputProps()}
//           type="file"
//           id="annonces-images"
//           name="annonces-images"
//           accept="image/*"
//         />
//         <div className="start-label-input-choose-file">
//           <div className="flex-c">
//             <MdAddAPhoto style={{ color: "#666666", fontSize: "36px" }} />
//           </div>
//           <span className="first-label-input-choose-file">
//             Déposez une image ici ou sélectionnez un fichier.
//           </span>
//           <span className="second-label-input-choose-file">
//             Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
//           </span>
//         </div>
//       </div>
//       {/* {maxFileError && <ErrorMessage message={maxFileError} />} */}
//       <div className="row pt-4">
//         {/* {acceptedFileItems} */}
//         {!maxFileError && fileRejectionItems}
//       </div>
//     </section>
//   );
// }

// export default MyDropzone;

// type TImageGrid = {
//   images: File[] | [];
// };

// export const ImageGrid = ({ images }: TImageGrid) => {
//   return (
//     <>
//       {images.length
//         ? images.map((file: FileWithPath) => (
//             <div className="col-md-3 mb-1" key={file.path}>
//               <div className="image-item">
//                 <img
//                   src={URL.createObjectURL(file)}
//                   alt={file.path}
//                   loading="lazy"
//                   className="img-thumbnail"
//                   id="overlay"
//                 />
//               </div>
//             </div>
//           ))
//         : ""}
//     </>
//   );
// };

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone, FileWithPath } from "react-dropzone";
import { BiTrash } from "react-icons/bi";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
// import { getImage } from "../../utils/Utils";

function MyDropzone({ setImages }: { setImages: any }) {
  const [maxFileError, setMaxFileError] = useState<string>("");
  const onDrop = useCallback((acceptedFiles: any, fileRejections: any[]) => {
    if (acceptedFiles) {
      setImages(acceptedFiles[0]);
      console.log(acceptedFiles[0]);
    }
    fileRejections.forEach((file: { errors: any[] }) => {
      file.errors.forEach((err: { code: string }) => {
        if (err.code === "too-many-files") {
          setMaxFileError("Maximum 4 images");
        }
        if (err.code === "file-invalid-type") {
          setMaxFileError("Types de fichiers non prises en charge");
        }
      });
    });
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    maxSize: 209715200,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    // maxSize: 1000,
  });
  // let acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
  // 	<div className="col-3" key={file.path}>
  // 		<div className="image-item">
  // 			<img src={URL.createObjectURL(file)} alt={file.path} loading="lazy" />
  // 		</div>
  // 	</div>
  // ));
  const fileRejectionItems = fileRejections.map(
    ({ errors }: { errors: any }, index: any) => {
      return (
        <div key={index}>
          {/* {file.size} bytes */}
          <ul className="p-0">
            {errors.map((e: any) => (
              <li
                key={e.code}
                style={{ listStyle: "none" }}
                className="text-danger"
              >
                {e.code === "file-too-large" &&
                  `L'image ${index + 1} est trop grande`}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  );

  useEffect(() => {
    setTimeout(() => setMaxFileError(""), 3000);
  });
  return (
    <section className="dropzone">
      {/* <label htmlFor="pro-images" className="espace-client-form-label">
				label
			</label> */}
      <label
        {...getRootProps()}
        style={{
          overflow: "hidden",
          opacity: 1,
          display: "flex",
          justifyContent: "center",
          padding: "3rem 15px",
          background: "#EEF0F4",
          border: "1px dashed #DB3251",
          borderRadius: "12px",
          fontFamily: "'Poppins'",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "18px",
          textAlign: "center",
          color: "#939393",
        }}
        htmlFor="pro-images"
      >
        <input
          {...getInputProps()}
          type="file"
          id="pro-images"
          name="pro-images"
          accept="image/*"
        />
        <div className="start-label-input-choose-file">
          <div className="flex-c">
            <MdOutlineAddPhotoAlternate
              style={{ color: "#DB3251", fontSize: "30px" }}
            />
          </div>
          <span className="first-label-input-choose-file">
            Déposez votre photo ici ou cliquez pour télécharger
          </span>
          <span className="second-label-input-choose-file">
            Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
          </span>
        </div>
      </label>
      {/* {maxFileError && <ErrorMessage message={maxFileError} />} */}
      <div className="row pt-4">
        {/* {acceptedFileItems} */}
        {!maxFileError && fileRejectionItems}
      </div>
    </section>
  );
}

export default MyDropzone;

type TImageGrid = {
  images: File[] | [];
};

export const ImageGrid = ({ images }: TImageGrid) => {
  return (
    <>
      {images.length
        ? images.map((file: any) => (
            <div className="col-md-3 mb-1" key={file.path}>
              <div className="image-item">
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.path}
                  loading="lazy"
                  className="img-thumbnail"
                  id="overlay"
                />
              </div>
            </div>
          ))
        : ""}
    </>
  );
};

export const ImagesView = ({
  images,
  setImages,
}: {
  images: any;
  setImages: any;
}) => {
  //   const [deleteImage] = useDeletePictureMutation();
  const deleteFile = (index: number, item: any) => {
    const newArr = [...images];
    newArr.splice(index, 1);
    setImages(newArr);

    // if (item && item?.image) {
    //   //   deleteImage(item?.slug);
    // }
  };
  return (
    <div className="row">
      {images.length
        ? images.map((file: any, i: number) => (
            <div className="col-md-3 mb-1" key={file.slug || i}>
              <div className="image-item properti-img-card">
                <button
                  className="no-style-btn del-product-img"
                  title="Supprimer"
                  type="button"
                  onClick={() => deleteFile(i, file)}
                >
                  <BiTrash />
                </button>
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.slug}
                  loading="lazy"
                  className="img-proprety"
                  //   id="overlay"
                  //   style={{
                  //     width: "100%",
                  //     height: "15vw",
                  //     objectFit: "fill",
                  //   }}
                />
              </div>
            </div>
          ))
        : ""}
    </div>
  );
};
