import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { AiFillEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { RiEdit2Fill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { BubbleZone, UserInfos } from "../../../../../types";
import { useAppSelector, useUser } from "../../../../redux/root";
import {
  deleteBubbleZoneAsync,
  toggleUserStatus,
  updateBubbleZoneAsync,
  updateUserAsync,
} from "../../../../utils/api/user.api";
import {
  ABONNEMENT,
  DefaultPaginationOptions,
  SWAL_DEFAULT_PROPS,
  isBasic,
} from "../../../../utils/data";
function BubbleZoneTable() {
  const isPro = useUser().is_proprietaire;
  const userId = useUser().uid;
  const [data, setData] = useState<BubbleZone[]>([]);
  const allData = useAppSelector((s) => s.bubble_zone);
  useEffect(() => {
    if (!isPro) {
      setTimeout(() => setData(allData), 500);
    } else {
      setTimeout(
        () => setData(allData.filter((f) => f.userId === userId)),
        500
      );
    }
  }, [allData]);
  const onDelete = async (id: string) => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce BubbleZone ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await deleteBubbleZoneAsync(id);
        Swal.fire({
          icon: "success",
          title: "BubbleZone supprimé avec succés",
          showConfirmButton: false,
          timer: 5000,
          ...SWAL_DEFAULT_PROPS,
        });
      },
      ...SWAL_DEFAULT_PROPS,
    });
  };

  const nameFormatter = (cell: string, row: BubbleZone) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <img
          src={row.image}
          alt="type document"
          className="photo-bubblezone-table"
        />{" "}
        <span className="ps-2">{row.name}</span>
      </div>
    );
  };

  const actionFormatter = (cell: string, row: BubbleZone) => {
    const prefix = isPro ? "proprietaire" : "admin";
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          {!isPro && (
            <div className="container-btn-action-icon-modal">
              <NavLink
                to={`/${prefix}/modifier-bubblezone`}
                state={row}
                className="btn btn-action-icon-edit with-tooltip"
                data-tooltip-content="Modifier"
              >
                <RiEdit2Fill />
              </NavLink>
            </div>
          )}
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/${prefix}/bubblezone/${row.id}`}
              state={row}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div>
          {!isPro && (
            <div className="container-btn-action-icon-modal">
              <button
                className="btn with-tooltip btn-action-icon-delete"
                data-tooltip-content="Supprimer"
                onClick={() => onDelete(row.id)}
                style={{ color: "#E53E3E" }}
              >
                <BsTrashFill />
              </button>
            </div>
          )}
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: "Nom",
      filter: textFilter(),
      formatter: (cell: string, row: BubbleZone) => nameFormatter(cell, row),
    },
    {
      dataField: "code",
      text: "N°",
      filter: textFilter(),
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: BubbleZone) => actionFormatter(cell, row),
    },
  ];

  if (!isPro) {
    columns.splice(2, 0, {
      dataField: "code_gerant",
      text: "N° Gérant",
      filter: textFilter(),
    });
  }

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={data}
        columns={columns}
        bordered={true}
        condensed={false}
        responsive={true}
        wrapperClasses="table-responsive admin-table"
        noDataIndication={() => "No data"}
        pagination={paginationFactory(DefaultPaginationOptions)}
        filter={filterFactory()}
      />
    </>
  );
}

export default BubbleZoneTable;

export function SwitchButton({ item }: { item: BubbleZone }) {
  if (item.enable_notification) {
    return (
      <div className="switch2">
        <label
          onClick={() => {
            updateBubbleZoneAsync(item.id, { enable_notification: false });
          }}
          className="switch"
        >
          <input type="checkbox" />
          <span className="slider"></span>
        </label>
      </div>
    );
  }
  return (
    <div className="switch2">
      <label
        onClick={() =>
          updateBubbleZoneAsync(item.id, { enable_notification: true })
        }
        className="switch"
      >
        <input type="checkbox" checked />
        <span className="slider"></span>
      </label>
    </div>
  );
}

export function Switch({
  onPress,
  active,
}: {
  onPress: () => void;
  active: boolean;
}) {
  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onPress();
  };
  return (
    <div className="switch2">
      <label onClick={onClick} className="switch">
        <input type="checkbox" checked={!active} />
        <span className="slider"></span>
      </label>
    </div>
  );
}

export function ToggleUserActive({ item }: { item: UserInfos }) {
  return (
    <Switch active={item.disabled} onPress={() => toggleUserStatus(item.uid)} />
  );
}

export function ToggleUserIsPro({ item }: { item: UserInfos }) {
  const basic = isBasic(item);
  return (
    <select
      className={
        !basic ? "custom-v-pro form-select" : `custom-v-basic form-select`
      }
      aria-label="Select version type"
      name={item.uid}
      id={item.uid}
      value={item.abonnement ?? "Basic"}
      onChange={(e) => {
        updateUserAsync(item.uid, {
          abonnement: e.target.value as UserInfos["abonnement"],
        });
      }}
    >
      {ABONNEMENT.map((s) => (
        <option value={s?.value} key={s.value}>
          {s.title}
        </option>
      ))}
    </select>
  );
}

export function TogglebubbleZoneActive({ item }: { item: BubbleZone }) {
  return (
    <Switch
      active={item.active}
      onPress={() => {
        updateBubbleZoneAsync(item.id, { active: !item.active });
      }}
    />
  );
}
