import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DashboardData } from "../../../types";

const initialState: DashboardData = {
  data: [],
  total_bubble_zone_count: 0,
  total_message_count: 0,
  total_user_count: 0,
  total_profil_visited: 0,
  total_user_deleted: 0,
  abonnements: [],
  arguments: [],
  boutique: [],
  moods: [],
  rangers:[],
  bubbleZones: []
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    onDashboard: (state, action: PayloadAction<DashboardData>) => {
      state = action.payload;
      return state;
    },
    onDashboardByType: (state, action: PayloadAction<DashboardData>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { onDashboard, onDashboardByType } = DashboardSlice.actions;

export default DashboardSlice.reducer;
