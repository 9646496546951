import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ActionState = { action: string | null };

const initialState: ActionState = { action: null };

export const actionSlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    onAction: (state, action: PayloadAction<ActionState>) => {
      state.action = action.payload.action;
    },
  },
});

export const { onAction } = actionSlice.actions;

export default actionSlice.reducer;
