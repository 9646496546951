import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { UserFormData } from "../../../../../types";
import { useUser } from "../../../../redux/root";
import { updateUserEmail } from "../../../../utils/api/auth.api";
import { uploadUserAvatarAsync } from "../../../../utils/api/storage.api";
import { updateUserAsync } from "../../../../utils/api/user.api";
import {
  SWAL_DEFAULT_PROPS,
  getAvatar,
  useModalActions,
} from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { BtnSubmit, GoBackButton } from "../../../common/Button";
import GooglePlaceInput from "../../../common/GooglePlaceInput";
import { FormError, Input, InputPropsType } from "../../../common/Input";
import ChangePasswordModal from "../../../modal/admin/ChangePasswordModal";
import "./ProfilAdmin.css";

function UserInfo(props: InputPropsType) {
  return (
    <>
      <div className="col-md-4">
        <div className="bubblezone-ajout-bubble-form-label">
          {props.placeholder}
        </div>
      </div>
      <div className="col-md-8 mb-3">
        <Input {...props} />
      </div>
    </>
  );
}

const ProfilAdmin = () => {
  const user = useUser();
  const ma = useModalActions("changePassword");
  const navigate = useNavigate();
  const admin = !user.is_proprietaire;
  const [loading, setLoading] = React.useState(false);
  const [avatar, setAvatar] = useState(getAvatar(user));
  const {
    data,
    errors,
    onSubmitForm,
    setErrors,
    onChange,
    register,
    registerSelect,
  } = useFormData<UserFormData>(
    {
      email: user?.email ?? "",
      pseudo: user?.pseudo ?? "",
      phone: user?.phone ?? "",
      abonnement: user?.abonnement || "Basic",
      ...(!admin
        ? {
            raison_social: user?.raison_social || "",
            numero_siret: user?.numero_siret || "",
            address: user?.address || "",
          }
        : {}),
    },
    (Yup) => ({
      email: Yup.string().email().required().label("Email"),
      pseudo: Yup.string().required().label("Prénom & Nom"),
      abonnement: Yup.string().required().label("Abonnement"),
      phone: Yup.string().required().label("Téléphone"),
      raison_social: admin
        ? Yup.string().notRequired()
        : Yup.string().required().label("Raison sociale"),
      numero_siret: admin
        ? Yup.string().notRequired()
        : Yup.string().required().label("Numéro de siret"),
      address: admin
        ? Yup.string().notRequired()
        : Yup.string().required().label("Adresse postale"),
    })
  );

  const onSubmit = React.useCallback(async (data: UserFormData) => {
    setLoading(true);
    try {
      await updateUserEmail(user.uid, data.email);
      await updateUserAsync(user.uid, data);
      Swal.fire({
        title: "Succès",
        text: "Profil modifié avec succès",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (ex: any) {
      if (ex.code === "auth/requires-recent-login") {
        setErrors({
          email:
            "Veuillez vous déconnecter et vous reconnecter pour modifier email!",
        });
      } else if (
        [
          "auth/credential-already-in-use",
          "auth/email-already-in-use",
          "auth/account-exists-with-different-credential",
          "auth/provider-already-linked",
        ].includes(ex.code)
      ) {
        setErrors({ email: "Cet e-mail existe déjà" });
      } else {
        setErrors({ email: "Une erreur est survenue lors de la modification" });
      }

      console.log("add error", ex);
    }
    setLoading(false);
  }, []);

  return (
    <div className="profil-page-component">
      <div className="bg-white p-3 mt-4 filter-and-table-container">
        <div className="bubble-zone-dash-locataire-requetes-header">
          <h4 className="bubble-zone-dash-header-titre">Mon profil</h4>
        </div>
        <section className="mb-3">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column align-items-center py-4 mb-4 bubble-zone-user-avatar-container">
                <div className="mb-auto profile-image-admin">
                  <div className="profile-avatar-container">
                    <img src={avatar} alt="avatar" />
                    <span>
                      <i className="fas fa-camera"></i>
                      <p>Changer</p>
                      <input
                        onChange={async (e) => {
                          if (e.target.files?.length) {
                            const file = e.target.files[0];
                            setAvatar(URL.createObjectURL(file));
                            try {
                              const avatarUrl = await uploadUserAvatarAsync(
                                file,
                                user.uid
                              );
                              await updateUserAsync(user.uid, {
                                avatar: avatarUrl,
                              });
                            } catch (ex) {}
                          }
                        }}
                        type="file"
                        name="avatar"
                        id="file_up"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <UserInfo
              {...register("pseudo")}
              placeholder="Prénom & Nom"
              type="text"
            />
            <UserInfo {...register("email")} placeholder="Email" type="email" />
            <UserInfo
              {...register("phone")}
              placeholder="Téléphone"
              type="tel"
            />
            {!admin && (
              <>
                <UserInfo
                  {...register("raison_social")}
                  placeholder="Raison sociale"
                  type="text"
                />
                <UserInfo
                  {...register("numero_siret")}
                  placeholder="Numéro de siret"
                  type="text"
                />
                <div className="col-md-4">
                  <div className="bubblezone-ajout-bubble-form-label">
                    Adresse postale
                  </div>
                </div>
                <div className="col-md-8 mb-3">
                  <GooglePlaceInput
                    onChange={(data) => {
                      onChange({
                        address: data.rue,
                      });
                    }}
                    value={data.address as string}
                    className="form-control form-control-modal-custom"
                    placeholder="D2 rue des bistrots, 33000 Bordeaux"
                  />
                  <FormError error={errors.address} />
                </div>
                <div className="col-md-4">
                  <div className="bubblezone-ajout-bubble-form-label">
                    Abonnement
                  </div>
                </div>
                <div className="col-md-8 mb-3">
                  <div className="input-infos-container">
                    <Input
                      type="text"
                      placeholder="Abonnement"
                      value={user?.abonnement}
                      readOnly
                      customClassInput="custom-abonnement-pro"
                    />
                    <div
                      onClick={() => {
                        navigate("/proprietaire/version-pro");
                      }}
                      style={{ cursor: "pointer" }}
                      className="custom-btn-change"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <circle
                          opacity="0.1"
                          cx="11.6031"
                          cy="12.4439"
                          r="11.6031"
                          fill="#28A2E7"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.91111 17.6195L15.575 10.2951C15.8828 9.90012 15.9922 9.44347 15.8896 8.97851C15.8007 8.55581 15.5408 8.1539 15.1509 7.84901L14.2 7.09369C13.3724 6.4354 12.3463 6.50469 11.758 7.26L11.1219 8.0853C11.0398 8.18855 11.0603 8.341 11.1629 8.42415C11.1629 8.42415 12.7704 9.71303 12.8046 9.74075C12.914 9.84469 12.9961 9.98328 13.0167 10.1496C13.0509 10.4753 12.8251 10.7802 12.4899 10.8217C12.3326 10.8425 12.1821 10.794 12.0727 10.7039L10.3831 9.35963C10.301 9.29795 10.1779 9.31112 10.1095 9.39427L6.09417 14.5914C5.83424 14.917 5.74531 15.3397 5.83424 15.7486L6.34727 17.9729C6.37463 18.0907 6.47723 18.1739 6.60036 18.1739L8.85769 18.1462C9.26812 18.1392 9.65118 17.9522 9.91111 17.6195ZM13.0718 16.9277H16.7526C17.1117 16.9277 17.4038 17.2236 17.4038 17.5874C17.4038 17.9519 17.1117 18.2471 16.7526 18.2471H13.0718C12.7127 18.2471 12.4206 17.9519 12.4206 17.5874C12.4206 17.2236 12.7127 16.9277 13.0718 16.9277Z"
                          fill="#28A2E7"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="custom-text-resilier-abonnement-container">
                    <NavLink
                      to={"/proprietaire/assistance?type=Résiliation"}
                      className="custom-text-resilier-abonnement"
                    >
                      Résilier mon abonnement
                    </NavLink>
                  </div>
                </div>
              </>
            )}
            <div className="col-md-12">
              <div className="flex-r pt-5">
                <GoBackButton label="Annuler" />
                <BtnSubmit
                  onClick={() => {
                    onSubmitForm(onSubmit);
                  }}
                  label="Mofifier"
                  isLoading={loading}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bg-white p-3 my-4 filter-and-table-container">
        <div className="row">
          <div className="col-md-4">
            <div className="password-btn-container">
              <h6 className="text-info-profil-admin-libelle mb-3">
                Mot de Passe
              </h6>
              <button className="btn auth-submit-btn" onClick={() => ma.show()}>
                Changer votre mot de passe
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="changePassword"
        aria-labelledby="changePasswordLabel"
        aria-hidden="true"
      >
        <ChangePasswordModal onHide={() => ma.hide()} />
      </div>
    </div>
  );
};

export default ProfilAdmin;
