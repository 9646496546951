import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { AiFillEye } from "react-icons/ai";
import { IoMdDownload } from "react-icons/io";
import { NavLink } from "react-router-dom";
import {
  ArchiveData,
  DefaultPaginationOptions,
  useGetMessages,
} from "../../../../../utils/data";
import { exportToExcel } from "../../../../../utils/export";

function ListeArchiveTable({ data }: { data: ArchiveData[] }) {
  const getMessages = useGetMessages();
  const pseudoFormatter = (cell, row: ArchiveData) => {
    return (
      <div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
        <img
          src={
            row.item.image ||
            `https://ui-avatars.com/api/?name=${row.item.name}`
          }
          alt="avatar"
          className="profil-user-table rounded"
        />{" "}
        <div className="d-flex flex-column gap-1">
          <span className="ps-2">{row.item.name}</span>
          <span className="ps-2 edit-bubble-code-span">{row.item.code}</span>
        </div>
      </div>
    );
  };

  const actionFormatter = (cell, row: ArchiveData) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <NavLink
            data-tooltip-content="Voir"
            className={"btn btn-action-modal-icon with-tooltip"}
            state={row}
            to={`/admin/bubblezone/${row.item.id}`}
          >
            <AiFillEye />
          </NavLink>
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon-download with-tooltip"
              onClick={() =>
                exportToExcel(() => getMessages(row.messages), "messages")
              }
              data-tooltip-content="Télécharger"
            >
              <IoMdDownload />
            </button>
          </div>
        </div>
      </>
    );
  };

  const columns: unknown[] = [
    {
      dataField: "item",
      text: "BubbleZone",
      formatter: pseudoFormatter,
    },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell: string, row: ArchiveData) =>
        moment(row.date).format("DD/MM/YYYY"),
    },
    {
      dataField: "message",
      text: "Message",
      formatter: (cell: string, row: ArchiveData) => row.messages.length,
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: actionFormatter,
    },
  ];
  return (
    <>
      {/* {isLoading && <ListeUsersTableSkeleton />} */}
      {/* {!isLoading && ( */}
      <>
        <BootstrapTable
          keyField="uid"
          data={data}
          columns={columns}
          bordered={true}
          condensed={false}
          responsive={true}
          wrapperClasses="table-responsive admin-table"
          noDataIndication={() => "No data"}
          pagination={paginationFactory(DefaultPaginationOptions)}
          filter={filterFactory()}
        />
      </>
      {/* )} */}
    </>
  );
}

export default ListeArchiveTable;
