import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NoteAvis } from "../../../types";

export type State = NoteAvis[];

const initialState: State = [];

export const NoteAvisSlice = createSlice({
  name: "note_avis",
  initialState,
  reducers: {
    onNoteAvis: (state, action: PayloadAction<State>) => {
      const newState = [...state];
      for (const i of action.payload) {
        const index = newState.findIndex((o) => o.id === i.id);
        if (index === -1) {
          newState.push(i);
        } else {
          newState[index] = i;
        }
      }
      return newState.sort((a, b) => b.date - a.date);
    },
  },
});

export const { onNoteAvis } = NoteAvisSlice.actions;

export default NoteAvisSlice.reducer;
