import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getCgu, getPtc } from "../../../../utils/api/user.api";

const TermesConditions = () => {
  const [content, setContent] = useState("");
  useEffect(() => {
    getCgu().then((res) => {
      setContent(res);
    });
  }, []);
  return (
    <div className="container-termes-conditions-component">
      <div className="content-title-termes-conditions mb-4">
        <div className="row flex-r">
          <div className="col-md-12">
            <h4 className="title-termes-conditions">
              Conditions générales d’utilisation
            </h4>
          </div>
          {/* <div className="col-md-4">
            <NavLink
              to="/admin/termes-et-conditions"
              className="btn auth-submit-btn"
              state={content}
            >
              Modifier
            </NavLink>
          </div> */}
        </div>
      </div>

      <div className="content-text-conditions-termes">
        <p
          className="text-conditions-termes"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      <div className="row flex-r mt-5">
        <div className="col-md-4">
          <NavLink
            to="/admin/termes-et-conditions"
            className="btn auth-submit-btn"
          >
            Modifier
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default TermesConditions;

export const PolitiqueDeConfidentialite = () => {
  const [content, setContent] = useState("");
  useEffect(() => {
    getPtc().then((res) => {
      setContent(res);
    });
  }, []);
  return (
    <div className="container-termes-conditions-component">
      <div className="content-title-termes-conditions mb-4">
        <div className="row flex-r">
          <div className="col-md-12">
            <h4 className="title-termes-conditions">
              Politique de confidentialité
            </h4>
          </div>
          {/* <div className="col-md-4">
            <NavLink
              to="/admin/politique-de-confidentialite"
              className="btn auth-submit-btn"
              state={content}
            >
              Modifier
            </NavLink>
          </div> */}
        </div>
      </div>

      <div className="content-text-conditions-termes">
        <p
          className="text-conditions-termes"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      <div className="row flex-r">
        <div className="col-md-4">
          <NavLink
            to="/admin/politique-de-confidentialite"
            className="btn auth-submit-btn"
            state={content}
          >
            Modifier
          </NavLink>
        </div>
      </div>
    </div>
  );
};
