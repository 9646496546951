import React from "react";
import { BsTrashFill } from "react-icons/bs";
import { IoAddOutline } from "react-icons/io5";
import { RiEdit2Fill } from "react-icons/ri";

function DiscussionItem() {
  return (
    <div className="discussion-item-component">
      <div className="mb-3">
        <div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
          <div className="row w-100 align-items-center">
            <div className="col-md-7">
              <div
                className="admin-message-user-card-description"
                style={{
                  textOverflow: "ellipsis",
                }}
              >
                Augue tristique dictum augue turpis et.
              </div>
            </div>
            <div className="col-md-2">
              <p className="admin-message-contact-card-description mb-0">
                5{" "}
                <span
                  style={{ fontSize: 12, color: "#5E5B5B", fontWeight: 300 }}
                >
                  Utilisateurs
                </span>
              </p>
            </div>
            <div className="col-md-3 flex-r">
              <div className="d-flex gap-3">
                <div>
                  <button
                    className="btn btn-action-icon-edit with-tooltip"
                    data-tooltip-content="Modifier"
                  >
                    <RiEdit2Fill />
                  </button>
                </div>
                <div>
                  <button
                    className="btn with-tooltip btn-action-icon-delete"
                    data-tooltip-content="Supprimer"
                    style={{ color: "#E53E3E" }}
                  >
                    <BsTrashFill />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
          <div className="row w-100 align-items-center">
            <div className="col-md-7">
              <div
                className="admin-message-user-card-description"
                style={{
                  textOverflow: "ellipsis",
                }}
              >
                Augue tristique dictum augue turpis et.
              </div>
            </div>
            <div className="col-md-2">
              <p className="admin-message-contact-card-description mb-0">
                5{" "}
                <span
                  style={{ fontSize: 12, color: "#5E5B5B", fontWeight: 300 }}
                >
                  Utilisateurs
                </span>
              </p>
            </div>
            <div className="col-md-3 flex-r">
              <div className="d-flex gap-3">
                <div>
                  <button
                    className="btn btn-action-icon-edit with-tooltip"
                    data-tooltip-content="Modifier"
                  >
                    <RiEdit2Fill />
                  </button>
                </div>
                <div>
                  <button
                    className="btn with-tooltip btn-action-icon-delete"
                    data-tooltip-content="Supprimer"
                    style={{ color: "#E53E3E" }}
                  >
                    <BsTrashFill />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
          <div className="row w-100 align-items-center">
            <div className="col-md-7">
              <div
                className="admin-message-user-card-description"
                style={{
                  textOverflow: "ellipsis",
                }}
              >
                Augue tristique dictum augue turpis et.
              </div>
            </div>
            <div className="col-md-2">
              <p className="admin-message-contact-card-description mb-0">
                5{" "}
                <span
                  style={{ fontSize: 12, color: "#5E5B5B", fontWeight: 300 }}
                >
                  Utilisateurs
                </span>
              </p>
            </div>
            <div className="col-md-3 flex-r">
              <div className="d-flex gap-3">
                <div>
                  <button
                    className="btn btn-action-icon-edit with-tooltip"
                    data-tooltip-content="Modifier"
                  >
                    <RiEdit2Fill />
                  </button>
                </div>
                <div>
                  <button
                    className="btn with-tooltip btn-action-icon-delete"
                    data-tooltip-content="Supprimer"
                    style={{ color: "#E53E3E" }}
                  >
                    <BsTrashFill />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-4 offset-md-4">
          <button className="btn btn-add-response">
            <IoAddOutline />
            <span className="ps-2">Ajouter un sujet</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DiscussionItem;
