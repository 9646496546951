import { UserInfos } from "../../../../../../types";
import { useArchives } from "../../../../../utils/data";
import ListeArchiveTable from "../Tables/ListeArchiveTable";

function Archive({ user }: { user: UserInfos }) {
  const data = useArchives(user.uid);
  return (
    <div className="col-left-dashboard col-left-dashboard-responsive">
      <div className="">
        <ListeArchiveTable data={data} />
      </div>
    </div>
  );
}

export default Archive;
