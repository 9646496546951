import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaThumbsDown, FaThumbsUp, FaTrash } from "react-icons/fa";
import { MdFileDownload } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BubbleZone } from "../../../../../types";
import Avatar from "../../../../assets/appImages/avata.png";
import { useUser } from "../../../../redux/root";
import { deleteAvis } from "../../../../utils/api/user.api";
import {
  Display,
  SWAL_DEFAULT_PROPS,
  isBasic,
  useAvgAvis,
  useAvgMoods,
  useGroupedAges,
  useGroupedGenre,
  useGroupedSubject,
  useMembers,
} from "../../../../utils/data";
import UpgradeVersionModal from "../../../modal/proprietaire/UpgradeVersionModal";
import { CustomInput } from "../../Admin/Dashboard/DashboardAdminV2";
import { GenericGraphe } from "../../Owner/ListeBubbleZone/GraphProprietaire";
import EmptyComments from "../DonneesClient/EmptyComments";
import ConnexionChartProprietaire from "./Charts/ConnexionChart";
import Pagination from "../../../common/Pagination";

function DisplayAvgNote({
  title,
  liked,
  unliked,
  disabled,
  setShow,
}: {
  title: string;
  liked: number;
  unliked: number;
  disabled: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const handleMouseEnter = () => {
    if (disabled) {
      setShow(true);
    }
  };
  return (
    <div className="proprietaire-detail-bubblezone-card-avis-item-container mb-3">
      <div
        className={`proprietaire-detail-bubblezone-card-item-thumbs-container ${
          disabled && "blured"
        }`}
        onMouseEnter={handleMouseEnter}
      >
        {liked >= unliked && (
          <FaThumbsUp className="proprietaire-detail-bubblezone-card-item-thumbs-up" />
        )}
        {liked < unliked && (
          <FaThumbsDown className="proprietaire-detail-bubblezone-card-thumbs-down" />
        )}
        {liked >= unliked ? liked : unliked}%
      </div>
      <div className="proprietaire-detail-bubblezone-card-item-thumbs-container">
        {title}
      </div>
    </div>
  );
}

function FilterItem({
  active,
  onClick,
  title,
}: {
  active: boolean;
  onClick: () => void;
  title: string;
}) {
  return (
    <button
      onClick={onClick}
      className={`btn proprietaire-detail-bubblezone-btn-filter ${
        active ? "active" : ""
      }`}
    >
      {title}
    </button>
  );
}

const OneHeader = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  return (
    <div className="admin-page-title-header-container">
      <div className="bubble-zone-dash-locataire-requetes-header">
        <h4 className="bubble-zone-dash-header-titre linkable">
          <span
            style={{ color: "#DB3251", marginRight: 10 }}
            onClick={() => navigate(-1)}
          >
            <AiOutlineArrowLeft />
          </span>
          {title}
        </h4>
      </div>
    </div>
  );
};

function useBubbleZoneHeader(
  initItem: BubbleZone,
  items?: BubbleZone[],
  multi?: boolean
) {
  const navigate = useNavigate();
  const user = useUser();
  const [item, setItem] = useState(initItem);

  const render = useCallback(() => {
    if (!multi) {
      return <OneHeader title={item.name} />;
    }

    if (items?.length === 1) return <OneHeader title={item.name} />;

    return (
      <div className="admin-page-title-header-container">
        <div className="bubble-zone-dash-locataire-requetes-header">
          <div className="bubblezone-page-title-container">
            <select
              className="form-select bubble-zone-dash-header-titre linkable border-0"
              value={item.id}
              onChange={(e) => {
                const finded = items?.find((u) => u.id === e.target.value);
                if (finded) setItem(finded);
              }}
            >
              {items?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }, [items, item]);

  return {
    render,
    item,
  };
}

export function DownloadRapportButton({
  bubbleZoneId,
}: {
  bubbleZoneId: string;
}) {
  const onDownload = async () => {
    const date = moment().format("DD/MM/YYYY");
    window.open(
      `https://us-central1-bubblezone-c8cf5.cloudfunctions.net/widgets/generate-pdf?bubbleZoneId=${bubbleZoneId}&date=${date}`,
      "blank"
    );
  };
  return (
    <button
      onClick={() => {
        onDownload();
      }}
      className="btn custom-btn-upload ml-2"
      type="button"
    >
      <MdFileDownload />
      <span className="ps-2">{"Télécharger le rapport mensuel"}</span>
    </button>
  );
}

export function BubbleZoneDetailsOwner({
  item: initItem,
  multi,
  items,
}: {
  item: BubbleZone;
  multi?: boolean;
  items?: BubbleZone[];
}) {
  const [page, setPage] = useState(1);
  const perPage = 10;
  const { item, render } = useBubbleZoneHeader(initItem, items, multi);
  const [display, setDisplay] = useState<Display>("all");
  const user = useUser();
  // @paul si basic est true basic il faut afficher le message de blocage
  const basic = isBasic(user);
  // const basic = true;
  const [date, setDate] = useState<string>();

  const {
    users: members,
    connexions,
    types,
    inBulles,
  } = useMembers(item.id || item.uid, display, date ? moment(date) : undefined);

  const avgMoods = useAvgMoods(inBulles);

  const [selected, setSelected] = useState<string[]>([]);

  const grounpedAges = useGroupedAges(members);
  const groupedCis = useGroupedSubject(members);
  const groupedGenres = useGroupedGenre(members);

  const { avg, avis } = useAvgAvis(
    item.id || item.uid,
    display,
    date ? moment(date) : undefined
  );

  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;
  const commentsToShow = avis.slice(startIndex, endIndex);

  const handleChange = (e) => {
    setDate(e);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleMouseEnter = () => {
    if (basic) {
      setShow(true);
    }
  };

  const onDelete = async () => {
    if (selected.length <= 0) return;
    Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer  ${
        selected.length > 1 ? "ces avis" : "cet avis"
      }?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      ...SWAL_DEFAULT_PROPS,
      preConfirm: async () => {
        await deleteAvis(selected);
        Swal.fire({
          icon: "success",
          title: "Avis supprimés avec succés",
          showConfirmButton: false,
          timer: 5000,
          ...SWAL_DEFAULT_PROPS,
        });
      },
    });
  };

  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page p-3 mb-3">
        {render()}
        <div className="col-left-dashboard col-left-dashboard-responsive">
          <div className="proprietaire-detail-bubblezone-btn-action-container">
            {/* <IoCalendar className="proprietaire-detail-bubblezone-btn-filter-icon" /> */}
            <div className="calendar-contenaire">
              <DatePicker
                selected={date}
                onChange={handleChange}
                customInput={<CustomInput />}
              />
            </div>

            <FilterItem
              title="Vue d’ensemble"
              active={display === "all"}
              onClick={() => {
                setDisplay("all");
                setDate(undefined);
              }}
            />
            <FilterItem
              title="Vue mensuelle"
              active={display === "30"}
              onClick={() => {
                setDisplay("30");
                setDate(undefined);
              }}
            />
            <FilterItem
              title="Vue trimestrielle"
              active={display === "120"}
              onClick={() => {
                setDisplay("120");
                setDate(undefined);
              }}
            />
          </div>
          <section className="proprietaire-detail-bubblezone-donnee-section">
            <div className="mt-5 mb-3">
              <h4 className="bubble-zone-dash-header-titre custom-bubble-zone-dash-header-titre linkable">
                Données
              </h4>
            </div>
            <div className="d-flex justify-content-end mb-3">
              <DownloadRapportButton bubbleZoneId={item.id} />
            </div>
            <div className="row">
              <div className={"col-md-4 mb-3"}>
                <div className="proprietaire-detail-bubblezone-card-graph-container">
                  <div className="proprietaire-detail-bubblezone-card-graph-title mb-2">
                    Mood
                  </div>
                  {/* moods */}
                  <div className="">
                    <GenericGraphe
                      disabled={basic}
                      renderLabels={(data) => {
                        return (
                          <div className="container-legend-mood-graph mt-4">
                            {data.map((item) => (
                              <div
                                key={item.title}
                                className="content-mood-detail-dashboard"
                              >
                                <img
                                  src={item.image}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    borderBottom: `3px solid ${item.color}`,
                                  }}
                                  alt={item.title}
                                />
                              </div>
                            ))}
                          </div>
                        );
                      }}
                      data={avgMoods.map((c) => ({
                        title: c.mood.title,
                        color: c.mood.color,
                        image: c.mood.image,
                        count: c.count,
                      }))}
                      Width={200}
                      Heigth={200}
                      customClass={"custom-graph-container"}
                    />
                  </div>
                </div>
              </div>
              <div className={"col-md-4 mb-3"}>
                <div className="proprietaire-detail-bubblezone-card-graph-container">
                  <div className="proprietaire-detail-bubblezone-card-graph-title mb-2">
                    Tranche d’âge
                  </div>

                  <div className="">
                    <GenericGraphe
                      disabled={basic}
                      renderLabels={(data) => {
                        return (
                          <div className="container-legend-mood-graph mt-4">
                            {data.map((item) => (
                              <div
                                key={item.title}
                                className="content-mood-detail-dashboard"
                              >
                                <p
                                  className="pourcentage-mood-detail-dashboard mb-0 pb-2"
                                  style={{
                                    borderBottom: `3px solid ${item.color}`,
                                  }}
                                >
                                  {item.title}
                                </p>
                              </div>
                            ))}
                          </div>
                        );
                      }}
                      data={grounpedAges.map((c) => ({
                        title: c.title,
                        color: c.color,
                        image: "",
                        count: c.count,
                      }))}
                      Width={200}
                      Heigth={200}
                      customClass={"custom-graph-container"}
                    />
                  </div>
                </div>
              </div>
              <div className={"col-md-4 mb-3"}>
                <div className="proprietaire-detail-bubblezone-card-graph-container">
                  <div className="proprietaire-detail-bubblezone-card-graph-title mb-2">
                    Passions
                  </div>

                  <div className="">
                    <GenericGraphe
                      disabled={basic}
                      renderLabels={(data) => {
                        return (
                          <div
                            className="container-legend-mood-graph mt-4 mx-1"
                            style={{ flexWrap: "wrap" }}
                          >
                            {data.map((item) => (
                              <div
                                key={item.title}
                                className="content-mood-detail-dashboard"
                              >
                                <p
                                  className="pourcentage-mood-detail-dashboard mb-0 pb-2"
                                  style={{
                                    borderBottom: `3px solid ${item.color}`,
                                  }}
                                >
                                  {item.title}
                                </p>
                              </div>
                            ))}
                          </div>
                        );
                      }}
                      data={groupedCis.map((c) => ({
                        title: c.ci.title,
                        color: c.ci.color,
                        image: "",
                        count: c.count,
                      }))}
                      Width={200}
                      Heigth={200}
                      customClass={"custom-graph-container"}
                    />
                  </div>
                </div>
              </div>
              <div className={"col-md-4 mb-3"}>
                <div className="proprietaire-detail-bubblezone-card-graph-container">
                  <div className="proprietaire-detail-bubblezone-card-graph-title mb-2">
                    Genre
                  </div>

                  <div className="">
                    <GenericGraphe
                      disabled={basic}
                      renderLabels={(data) => {
                        return (
                          <div className="container-legend-mood-graph mt-4">
                            {data.map((item) => (
                              <div
                                key={item.title}
                                className="content-mood-detail-dashboard"
                              >
                                <p
                                  className="pourcentage-mood-detail-dashboard mb-0 pb-2"
                                  style={{
                                    borderBottom: `3px solid ${item.color}`,
                                  }}
                                >
                                  {item.title}
                                </p>
                              </div>
                            ))}
                          </div>
                        );
                      }}
                      data={groupedGenres.map((c) => ({
                        title: c.title,
                        color: c.color,
                        image: "",
                        count: c.count,
                      }))}
                      Width={200}
                      Heigth={200}
                      customClass={"custom-graph-container"}
                    />
                  </div>
                </div>
              </div>
              <div className={"col-md-4 mb-3"}>
                <div className="proprietaire-detail-bubblezone-card-graph-container">
                  <div className="proprietaire-detail-bubblezone-card-graph-title mb-3">
                    Avis général
                  </div>

                  <div
                    className={`proprietaire-detail-bubblezone-card-avis-item-container mb-4 ${
                      basic && "blured"
                    }`}
                    onMouseEnter={handleMouseEnter}
                  >
                    <div className="proprietaire-detail-bubblezone-card-thumbs-container">
                      <FaThumbsUp className="proprietaire-detail-bubblezone-card-thumbs-up" />
                      <span>{avg.liked}%</span>
                    </div>
                    <div className="proprietaire-detail-bubblezone-card-thumbs-container">
                      <FaThumbsDown className="proprietaire-detail-bubblezone-card-thumbs-down" />
                      <span>{avg.unliked}%</span>
                    </div>
                  </div>
                  <DisplayAvgNote
                    title="Ambiance"
                    {...avg.ambiance}
                    disabled={basic}
                    setShow={setShow}
                  />
                  <DisplayAvgNote
                    title="Prix"
                    {...avg.prix}
                    disabled={basic}
                    setShow={setShow}
                  />
                  <DisplayAvgNote
                    title="Service"
                    {...avg.service}
                    disabled={basic}
                    setShow={setShow}
                  />
                  <DisplayAvgNote
                    title="Clientèle"
                    {...avg.clientele}
                    disabled={basic}
                    setShow={setShow}
                  />
                </div>
                <UpgradeVersionModal show={show} handleClose={handleClose} />
              </div>
              <div className={"col-md-4 mb-3"}>
                <div className="proprietaire-detail-bubblezone-card-graph-container">
                  <div className="proprietaire-detail-bubblezone-card-graph-title mb-2">
                  Nouveaux venus
                  </div>
                  <div className="">
                    <GenericGraphe
                      disabled={basic}
                      renderLabels={(data) => {
                        return (
                          <div className="container-legend-mood-graph mt-4">
                            {data.map((item) => (
                              <div
                                key={item.title}
                                className="content-mood-detail-dashboard"
                              >
                                <p
                                  className="pourcentage-mood-detail-dashboard mb-0 pb-2"
                                  style={{
                                    borderBottom: `3px solid ${item.color}`,
                                  }}
                                >
                                  {item.title}
                                </p>
                              </div>
                            ))}
                          </div>
                        );
                      }}
                      data={types.map((c) => ({
                        title: c.title,
                        color: c.color,
                        image: "",
                        count: c.count,
                      }))}
                      Width={200}
                      Heigth={200}
                      customClass="custom-graph-container"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="proprietaire-detail-bubblezone-nbr-connexion-section bg-white">
            <div className="my-5 mx-2">
              <h4 className="bubble-zone-dash-header-titre custom-bubble-zone-dash-header-titre linkable">
                Nombre de connexion
              </h4>
            </div>
            <div>
              <div className={""}>
                <ConnexionChartProprietaire
                  disabled={basic}
                  data={connexions}
                />
              </div>
            </div>
          </section>
          {avis?.length > 0 ? (
            <section className="proprietaire-detail-bubblezone-nbr-connexion-section mt-4">
              <div className="mt-5">
                <h4 className="bubble-zone-dash-header-titre custom-bubble-zone-dash-header-titre linkable">
                  Commentaires
                </h4>
              </div>
              <div>
                <ul className="list-group">
                  <div className="list-group-btn-supp-container">
                    <div className="d-flex gap-1">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        id="all"
                        checked={selected.length === avis.length}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelected(avis.map((c) => c.id));
                          } else {
                            setSelected([]);
                          }
                        }}
                        aria-label="..."
                      />
                      Tout sélectionner
                    </div>
                    <FaTrash
                      onClick={onDelete}
                      className="list-group-btn-supp"
                    />
                  </div>
                  {commentsToShow.map((commentaire) => (
                    <li
                      className="list-group-item custom-commentaire-list-group-item"
                      key={commentaire.id}
                    >
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        id={commentaire.id}
                        checked={selected.includes(commentaire.id)}
                        onChange={(e) => {
                          if (selected.includes(commentaire.id)) {
                            setSelected((old) =>
                              old.filter((f) => f !== commentaire.id)
                            );
                          } else {
                            setSelected((old) => [...old, commentaire.id]);
                          }
                        }}
                        aria-label="..."
                      />
                      <div className="w-100">
                        <div className="proprietaire-detail-bubblezone-username-commentaire-container">
                          <div className="proprietaire-detail-bubblezone-username-commentaire">
                            <img
                              alt="user avatar"
                              className="proprietaire-detail-bubblezone-useravatar"
                              src={commentaire?.userObj?.avatar || Avatar}
                            />
                            {commentaire?.userObj?.pseudo}
                          </div>
                          <div className="proprietaire-detail-bubblezone-date-commentaire">
                            {moment(commentaire.date).format(
                              "ddd DD MMM [à] HH:mm"
                            )}
                          </div>
                        </div>
                        <div className="proprietaire-detail-bubblezone-commentaire-text">
                          {commentaire.avis}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex-r mt-4">
                <Pagination
                  page={page}
                  onPageChange={(newPage) => setPage(newPage)}
                  total={avis?.length}
                  perPage={perPage}
                />
              </div>
            </section>
          ) : (
            <EmptyComments />
          )}
        </div>
      </div>
    </div>
  );
}

export default function () {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location.state) {
      navigate("/admin/bubblezone");
    }
  }, [location.state]);
  if (!location.state) return null;
  return <BubbleZoneDetailsOwner item={location.state} />;
}
