import {
  User,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import { getUserInfos } from "./user.api";

const auth = getAuth();

export function authListener(cb: (user: User | null) => void) {
  return onAuthStateChanged(auth, cb);
}
export function logoutAsync() {
  return signOut(auth);
}

export function updateUserEmail(uid?: string, email?: string) {
  if (auth.currentUser) {
    if (
      uid === auth.currentUser.uid &&
      email &&
      email !== auth.currentUser.email
    )
      return updateEmail(auth.currentUser, email);
  }
  return true;
}

export async function loginAsync(
  email: string,
  password: string,
  remember: boolean
) {
  console.log({ remember });
  if (!remember) {
    await auth.setPersistence(browserSessionPersistence);
  }
  const { user } = await signInWithEmailAndPassword(auth, email, password);
  if (user) {
    const userInfos = await getUserInfos(user);
    if (userInfos) {
      return {
        user,
        userInfos,
      };
    } else {
      await logoutAsync();
    }
  }
  return null;
}
export async function registerAsync(email: string, password: string) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function sendPasswordResetLinkAsync(email: string) {
  return sendPasswordResetEmail(auth, email);
}

export async function updatePasswordAsync(password: string) {
  if (auth.currentUser) {
    return updatePassword(auth.currentUser, password);
  }
  return null;
}

export default auth;
