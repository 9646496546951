import React from "react";
import { BsTrashFill } from "react-icons/bs";
import { IoAddOutline } from "react-icons/io5";
import { RiEdit2Fill } from "react-icons/ri";
import AddQuestionModal from "../../../modal/admin/AddQuestionModal";
import AddResponseModal from "../../../modal/admin/AddResponseModal";

const data = [
  {
    id: 1,
    question:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ridiculus nisl tempor et sapien ?",
    reponse: "Lore dolor sit amet, consectetur adipiscing elit.",
    nombreReponse: 2,
  },
  {
    id: 2,
    question:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ridiculus nisl tempor et sapien ?",
    reponse: "Lore dolor sit amet, consectetur adipiscing elit.",
    nombreReponse: 0,
  },
  {
    id: 3,
    question:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ridiculus nisl tempor et sapien ?",
    reponse: "Lore dolor sit amet, consectetur adipiscing elit.",
    nombreReponse: 1,
  },
  {
    id: 4,
    question:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ridiculus nisl tempor et sapien ?",
    reponse: "Lore dolor sit amet, consectetur adipiscing elit.",
    nombreReponse: 2,
  },
  {
    id: 5,
    question:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ridiculus nisl tempor et sapien ?",
    reponse: "Lore dolor sit amet, consectetur adipiscing elit.",
    nombreReponse: 2,
  },
  {
    id: 6,
    question:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ridiculus nisl tempor et sapien ?",
    reponse: "Lore dolor sit amet, consectetur adipiscing elit.",
    nombreReponse: 2,
  },
];
function QuestionItem() {
  return (
    <div className="questions-reponses-component">
      <div className="container-questions-reponses">
        <div className="row b-b-1">
          <div className="col-md-4">
            <div>
              <p className="title-page-questions-reponses">Questions</p>
            </div>
          </div>
          <div className="col-md-8">
            <div>
              <p className="title-page-questions-reponses">Réponses</p>
            </div>
          </div>
        </div>
        {data.map((item, index) => (
          <div className="row b-b-1 py-3" key={index}>
            <div className="col-md-4">
              <div className="content-col-question">
                <div>
                  <p className="text-question-item">{item.question}</p>
                </div>
                <div>
                  <button
                    className="btn btn-action-icon-edit with-tooltip"
                    data-tooltip-content="Modifier"
                    data-bs-toggle="modal"
                    data-bs-target="#AddQuestionModal"
                  >
                    <RiEdit2Fill />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="content-reponse-bloc">
                {item.nombreReponse === 2 ? (
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="reponse-item">
                        <div>
                          <p className="text-reponse-item">{item.reponse}</p>
                        </div>
                        <div className="d-flex gap-3">
                          <div>
                            <button
                              className="btn btn-action-icon-edit with-tooltip"
                              data-tooltip-content="Modifier"
                              data-bs-toggle="modal"
                              data-bs-target="#AddResponseModal"
                            >
                              <RiEdit2Fill />
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn with-tooltip btn-action-icon-delete"
                              data-tooltip-content="Supprimer"
                              style={{ color: "#E53E3E" }}
                            >
                              <BsTrashFill />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="reponse-item">
                        <div>
                          <p className="text-reponse-item">{item.reponse}</p>
                        </div>
                        <div className="d-flex gap-3">
                          <div>
                            <button
                              className="btn btn-action-icon-edit with-tooltip"
                              data-tooltip-content="Modifier"
                              data-bs-toggle="modal"
                              data-bs-target="#AddResponseModal"
                            >
                              <RiEdit2Fill />
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn with-tooltip btn-action-icon-delete"
                              data-tooltip-content="Supprimer"
                              style={{ color: "#E53E3E" }}
                            >
                              <BsTrashFill />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 offset-md-6">
                      <div className="content-btn-add-response">
                        <button
                          className="btn btn-add-response"
                          data-bs-toggle="modal"
                          data-bs-target="#AddResponseModal"
                        >
                          <IoAddOutline />
                          <span className="ps-2">Ajouter une réponse</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : item.nombreReponse === 1 ? (
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="reponse-item">
                        <div>
                          <p className="text-reponse-item">{item.reponse}</p>
                        </div>
                        <div className="d-flex gap-3">
                          <div>
                            <button
                              className="btn btn-action-icon-edit with-tooltip"
                              data-tooltip-content="Modifier"
                              data-bs-toggle="modal"
                              data-bs-target="#AddResponseModal"
                            >
                              <RiEdit2Fill />
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn with-tooltip btn-action-icon-delete"
                              data-tooltip-content="Supprimer"
                              style={{ color: "#E53E3E" }}
                            >
                              <BsTrashFill />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="content-btn-add-response">
                        <button
                          className="btn btn-add-response"
                          data-bs-toggle="modal"
                          data-bs-target="#AddResponseModal"
                        >
                          <IoAddOutline />
                          <span className="ps-2">Ajouter une réponse</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : item.nombreReponse < 1 ? (
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="content-btn-add-response">
                        <button
                          className="btn btn-add-response"
                          data-bs-toggle="modal"
                          data-bs-target="#AddResponseModal"
                        >
                          <IoAddOutline />
                          <span className="ps-2">Ajouter une réponse</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className="modal fade"
        id="AddQuestionModal"
        aria-labelledby="AddQuestionModalLabel"
        aria-hidden="true"
      >
        <AddQuestionModal />
      </div>
      <div
        className="modal fade"
        id="AddResponseModal"
        aria-labelledby="AddResponseModalLabel"
        aria-hidden="true"
      >
        <AddResponseModal />
      </div>
    </div>
  );
}

export default QuestionItem;
