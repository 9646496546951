import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";

const ArticleComponent = () => {
  return (
    <div className="bubblezone-section-item">
      <div className="classement-bubblezone-card-graph-title mb-2">
        ACTUALITÉ :
      </div>
      <div className="article-from-google-container">
          <ActuFromGoogle />
      </div>
    </div>
  );
};

export default ArticleComponent;

function ActuFromGoogle() {
  return (
    <div className="actu-bubblezone-from-google">
      <div className="actu-bubblezone-from-google-title-container">
        <div className="actu-bubblezone-from-google-title mb-2">
          Plongée dans l'atmosphère envoûtante du 'Bar Éphémère': Un sanctuaire
          de convivialité et de découvertes gustatives
        </div>
        <div className="actu-bubblezone-from-google-description">
          Lorem ipsum dolor sit amet consectetur. Sed consectetur lorem lectus
          purus ut aliquam. Donec dictum amet ut tempor cursus aliquam magna.
          Aliquam vulputate nisl dui tempor ipsum. Nullam non varius sit natoque
          eget tellus ultricies.
        </div>
        <div>
          <button className="btn actu-bubblezone-from-google-btn-plus pb-0">
            Lire plus <FaLongArrowAltRight />
          </button>
        </div>
      </div>
      {/* <div className="actu-bubblezone-from-google-image-container">
        <img
          src={actu}
          alt="actualité bubblezone"
          className="actu-bubblezone-from-google-image"
        />
      </div> */}
    </div>
  );
}
