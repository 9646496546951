/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from "moment";
import { BiSearchAlt2 } from "react-icons/bi";
import { FiFolderMinus } from "react-icons/fi";
import Swal from "sweetalert2";
import {
  MessageContact as McT,
  MessageContact as MessageContactType,
} from "../../../../../types";
import { useAppSelector } from "../../../../redux/root";
import { updateMessageContact } from "../../../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS, useModalActions } from "../../../../utils/data";
import DetailsMessageModal from "../../../modal/admin/DetailMessageModal";
import "./MessageContact.css";

import { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";

const MessageContact = () => {
  const messages = useAppSelector((s) => s.messages_contact);
  const [data, setData] = useState<McT[]>([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    const s = search.trim().toLowerCase();
    if (s.length > 0) {
      setData(
        messages.filter((m) =>
          `${m.email} ${m.pseudo} ${m.message} ${m.sujet}`
            .toLowerCase()
            .includes(s)
        )
      );
    } else {
      setData(messages);
    }
  }, [messages, search]);
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="admin-page-title-header-container">
          <div className="bubble-zone-dash-locataire-requetes-header">
            <h4 className="bubble-zone-dash-header-titre">Messages contact</h4>
          </div>
          <div className="custom-proprietaire-filter-input-container">
            <input
              type="text"
              className="form-control custom-proprietaire-filter-input  w-100"
              id="filtreLogement"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Chercher un message"
            />
            <span className="custom-proprietaire-filter-input-icon-container">
              <BiSearchAlt2 className="custom-proprietaire-filter-input-icon" />
            </span>
          </div>
        </div>
        <div className="admin-page-title-header-container mt-3">
          <ul
            className="nav nav-tabs nav-tabs-setting gap-3"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link nav-link-setting active"
                id="users-tab"
                data-bs-toggle="tab"
                data-bs-target="#users"
                type="button"
                role="tab"
                aria-controls="users"
                aria-selected="false"
              >
                Utilisateurs
              </button>
            </li>
            <li className="nav-item nav-item-setting" role="presentation">
              <button
                className="nav-link nav-link-setting"
                id="gerants-tab"
                data-bs-toggle="tab"
                data-bs-target="#gerants"
                type="button"
                role="tab"
                aria-controls="gerants"
                aria-selected="false"
              >
                Gérants
              </button>
            </li>
          </ul>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mt-0">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active bg-white p-md-3"
              id="users"
              role="tabpanel"
              aria-labelledby="users-tab"
            >
              <MessageContactList data={data} owner={false} />
            </div>
            <div
              className="tab-pane fade  bg-white p-3"
              id="gerants"
              role="tabpanel"
              aria-labelledby="gerants-tab"
            >
              <MessageContactList data={data} owner />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageContact;

function MessageContactList({ data, owner }: { data: McT[]; owner: boolean }) {
  const [messages, setMessages] = useState<McT[]>([]);
  useEffect(() => {
    if (owner) {
      setMessages(data.filter((f) => f.owner));
    } else {
      setMessages(data.filter((f) => !f.owner));
    }
  }, [data, owner]);

  return (
    <div>
      {messages.map((item) => (
        <MessageContactItem item={item} key={item.id} />
      ))}
      {messages.length === 0 && <p>Pas de message</p>}
    </div>
  );
}

function MessageContactItem({ item }: { item: MessageContactType }) {
  const modalId = `MessageContactItem${item.id}`;
  // const collapseId = `MessageContactCollapse${item.id}`;
  const ma = useModalActions(modalId);
  const onChangeStatus = () => {
    Swal.fire({
      title: "Changer le statut",
      input: "select",
      inputValue: item.status ?? "Nouveau",
      inputOptions: {
        Nouveau: "Nouveau",
        Traité: "Traité",
        "Non traité": "Non traité",
      },
      inputPlaceholder: "Statut",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: async (value) => {
        if (value) {
          await updateMessageContact(item.id, { status: value });
        }
      },
      ...SWAL_DEFAULT_PROPS,
    });
  };

  const [openCollapse, setOpenCollapse] = useState({});
  const toggleCollapse = (itemId) => {
    setOpenCollapse((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };
  return (
    <>
      <div className="mb-3">
        <div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
          <div className="row w-100 align-items-center">
            <div className="col-md-7">
              <div className="d-flex align-items-start gap-2">
                {/* <input
                  className="form-check-input me-1"
                  type="checkbox"
                  id={""}
                  aria-label="..."
                /> */}
                <div className="message-item-without-input-container">
                  <div
                    className="admin-message-contact-card-description d-flex btn p-0"
                    style={{
                      textOverflow: "ellipsis",
                    }}
                    onClick={() => ma.show()}
                    role="button"
                  >
                    {item.sujet ?? item.message}
                  </div>
                  <p className="admin-message-contact-card-frequence fz-14 mb-0">
                    {moment(item.created_at).format("DD/MM/YYYY [à] HH:mm")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <button
                onClick={() => onChangeStatus()}
                className={`btn message-btn ${
                  !item.status || item.status === "Nouveau"
                    ? "bg-status"
                    : item.status === "Traité"
                    ? "bg-status-success"
                    : ""
                }`}
                aria-expanded="false"
              >
                {item.status ?? "Nouveau"}
              </button>
            </div>
            <div className="col-md-3 flex-l">
              <p className="admin-message-contact-card-description mb-0">
                {item.pseudo}
              </p>
            </div>
          </div>
          <button
            className="no-style-btn accordion-button"
            type="button"
            onClick={() => toggleCollapse(item.id)}
            aria-expanded={openCollapse[item.id]}
            aria-controls={`collapse-${item.id}`}
          >
            <FiFolderMinus />
          </button>
        </div>
        <Collapse in={openCollapse[item.id]}>
          <div className="card card-body admin-content-message mb-1">
            {item.message}
          </div>
        </Collapse>
      </div>
      <div
        className="modal fade"
        id={modalId}
        aria-labelledby="DetailsMessageModalLabel"
        aria-hidden="true"
      >
        <DetailsMessageModal item={item} onHide={() => ma.hide()} />
      </div>
    </>
  );
}
