import React from "react";
import Swal from "sweetalert2";
import { Mood, MoodFormData } from "../../../../types";
import { createMoodAsync, updateMoodAsync } from "../../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS } from "../../../utils/data";
import { useFormData } from "../../../utils/form";
import { FormError, SubmitButton } from "../../auth/Login";
import CloseModalBtn from "../../shared/CloseModalBtn";
import "./AdminModal.css";

const AddMoodModal = ({
  item,
  onHide,
}: {
  item?: Mood;
  onHide: () => void;
}) => {
  const { errors, setErrors, onChange, onSubmitForm, register } =
    useFormData<MoodFormData>(
      {
        title: item?.title ?? "",
        color: item?.color ?? "",
        description: item?.description ?? "",
      },
      (Yup) => ({
        title: Yup.string().required().label("Titre"),
        color: Yup.string().required().label("Couleur"),
        description: Yup.string().required().label("Description"),
        image: Yup.mixed()
          .label("Image")
          .test(
            "is-image",
            "Veuillez télécharger une  image (PNG,JPEG,JPG,GIF)",
            (value) => {
              if (item && !value) return true;
              if (!value || !(value instanceof File)) return false;
              return [
                "image/gif",
                "image/jpg",
                "image/jpeg",
                "image/png",
              ].includes(value.type.toLowerCase());
            }
          ),
      })
    );
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(
    async (data: MoodFormData) => {
      setLoading(true);
      try {
        if (item) {
          await updateMoodAsync(item.id, data);
          onHide();
          setTimeout(() => {
            Swal.fire({
              title: "Succès",
              text: "Tag modifié avec succès",
              icon: "success",
              ...SWAL_DEFAULT_PROPS,
            });
          }, 400);
        } else {
          await createMoodAsync(data);
          onHide();
          setTimeout(() => {
            Swal.fire({
              title: "Succès",
              text: `Tag ajouté avec succès`,
              icon: "success",
              ...SWAL_DEFAULT_PROPS,
            });
            onChange({
              title: "",
              image: undefined,
            });
          }, 400);
        }
      } catch (ex) {
        setErrors({ title: "Une erreur est survenue lors de l'ajout" });
        console.log("add error", ex);
      }
      setLoading(false);
    },
    [onHide]
  );
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header py-2 flex-m">
          <h5 className="admin-modal-title text-start">
            {item ? "Modifier" : "Ajouter"} un mood
          </h5>
          <CloseModalBtn />
        </div>
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitForm(onSubmit);
            }}
          >
            <div className="container-form">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="image"
                      className="form-label form-label-modal-custom"
                    >
                      Image
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control form-control-modal-custom"
                      {...register("image", true)}
                      id="image"
                    />
                    <FormError error={errors.image as string} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="title"
                      className="form-label form-label-modal-custom"
                    >
                      Titre
                    </label>
                    <input
                      type="text"
                      placeholder="Titre"
                      className="form-control form-control-modal-custom"
                      {...register("title")}
                    />
                    <FormError error={errors.title} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="title"
                      className="form-label form-label-modal-custom"
                    >
                      Description
                    </label>
                    <input
                      type="text"
                      placeholder="Description"
                      className="form-control form-control-modal-custom"
                      {...register("description")}
                    />
                    <FormError error={errors.description} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="color"
                      className="form-label form-label-modal-custom"
                    >
                      Couleur
                    </label>
                    <input
                      type="color"
                      placeholder="Couleur"
                      className="form-control form-control-modal-custom"
                      {...register("color")}
                    />
                    <FormError error={errors.color} />
                  </div>
                </div>
              </div>
              <div className="gap-3 flex-r align-center row mt-4">
                <div className="col-md-3">
                  <button
                    type="button"
                    className="btn btn-cancelled"
                    onClick={onHide}
                  >
                    Fermer
                  </button>
                </div>
                <div className="col-md-3">
                  <SubmitButton title="Terminer" loading={loading} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddMoodModal;
