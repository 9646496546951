import * as React from "react";
import { BsTrashFill } from "react-icons/bs";
import { IoAddOutline } from "react-icons/io5";
import { RiEdit2Fill } from "react-icons/ri";
import Swal from "sweetalert2";
import {
  AppConfig,
  AppConfigFormData,
  Subject,
  Type,
  TypeEtablissement,
} from "../../../../../types";
import { useAppSelector } from "../../../../redux/root";
import {
  deletePrefAsync,
  deleteSubjectAsync,
  deleteTypeEtablissementAsync,
  majAppConfig,
} from "../../../../utils/api/user.api";
import { SWAL_DEFAULT_PROPS, useModalActions } from "../../../../utils/data";
import { useFormData } from "../../../../utils/form";
import { FormError, SubmitButton } from "../../../auth/Login";
import AddArgumentModal from "../../../modal/admin/AddArgumentModal";
import AddPrefModal from "../../../modal/admin/AddPrefModal";
import AddTypeEtablissementModal from "../../../modal/admin/AddTypeEtablissement";
import { Switch } from "../../../tables/admin/BubbleZoneTable/BubbleZoneTable";

function MajTypeEtablissement({
  modalId,
  item,
}: {
  modalId: string;
  item?: TypeEtablissement;
}) {
  const ma = useModalActions(modalId);
  return (
    <div
      className="modal fade"
      id={modalId}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <AddTypeEtablissementModal onHide={ma.hide} item={item} />
    </div>
  );
}

function MajPref({ modalId, item }: { modalId: string; item?: Type }) {
  const ma = useModalActions(modalId);
  return (
    <div
      className="modal fade"
      id={modalId}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <AddPrefModal onHide={ma.hide} item={item} />
    </div>
  );
}

export function MajModal({
  modalId,
  item,
}: {
  modalId: string;
  item?: Subject;
}) {
  const ma = useModalActions(modalId);
  return (
    <div
      className="modal fade"
      id={modalId}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <AddArgumentModal onHide={ma.hide} item={item} />
    </div>
  );
}
export function SubjectItem({ item }: { item: Subject }) {
  const modalId = `AddArgumentModal_${item.id}`;
  const onDelete = async () => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette passion ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      ...SWAL_DEFAULT_PROPS,
      preConfirm: async () => {
        await deleteSubjectAsync(item.id);
        Swal.fire({
          icon: "success",
          title: "Passion supprimée avec succés",
          showConfirmButton: false,
          timer: 5000,
          ...SWAL_DEFAULT_PROPS,
        });
      },
    });
  };
  return (
    <div className="col-md-4 mb-3">
      <div className="reponse-item">
        <div>
          <p className="text-reponse-item">
            <span
              className="display-color"
              style={{
                backgroundColor: item.color ?? "#FFF",
              }}
            />
            {item.title}
          </p>
        </div>
        <div className="d-flex gap-3">
          <div>
            <button
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              data-bs-toggle="modal"
              data-bs-target={`#${modalId}`}
            >
              <RiEdit2Fill />
            </button>
          </div>
          <div>
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
              onClick={onDelete}
            >
              <BsTrashFill />
            </button>
          </div>
        </div>
      </div>
      <MajModal modalId={modalId} item={item} />
    </div>
  );
}

function Hobbies() {
  const subjects = useAppSelector((s) => s.subjects);
  return (
    <div className="questions-reponses-component">
      <div className="container-questions-reponses">
        <div className="mb-3 py-3">
          <div className="row">
            {subjects.map((subject) => (
              <SubjectItem item={subject} key={subject.id} />
            ))}
          </div>
          <div className="row mt-3">
            <div className="col-md-3">
              <div className="content-btn-add-response">
                <button
                  className="btn btn-add-response"
                  data-bs-toggle="modal"
                  data-bs-target="#AddArgumentModal"
                >
                  <IoAddOutline />
                  <span className="ps-2">Ajouter</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MajModal modalId="AddArgumentModal" />
    </div>
  );
}

function PrefItem({ item }: { item: Type }) {
  const modalId = `AddPrefModal_${item.id}`;
  const onDelete = async () => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette préférence ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      ...SWAL_DEFAULT_PROPS,
      preConfirm: async () => {
        await deletePrefAsync(item.id);
        Swal.fire({
          icon: "success",
          title: "Tag supprimée avec succés",
          showConfirmButton: false,
          timer: 5000,
          ...SWAL_DEFAULT_PROPS,
        });
      },
    });
  };
  return (
    <div className="col-md-4 mb-3">
      <div className="reponse-item">
        <div>
          <p className="text-reponse-item">{item.title}</p>
        </div>
        <div className="d-flex gap-3">
          <div>
            <button
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              data-bs-toggle="modal"
              data-bs-target={`#${modalId}`}
            >
              <RiEdit2Fill />
            </button>
          </div>
          <div>
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
              onClick={onDelete}
            >
              <BsTrashFill />
            </button>
          </div>
        </div>
      </div>
      <MajPref modalId={modalId} item={item} />
    </div>
  );
}

export function Preferences() {
  const typesEtablissements = useAppSelector((s) => s.typesEtablissements);
  const types = useAppSelector((s) => s.types);
  return (
    <div className="bg-none">
      <div className="questions-reponses-component mb-3 bg-white p-3">
        <div className="container-questions-reponses">
          <div className="mb-3 py-3">
            <div className="param-titre-preference mb-3">
              Types d’établissements
            </div>
            <div className="row">
              {typesEtablissements.map((type) => (
                <TypeEtablissementItem item={type} key={type.id} />
              ))}
            </div>
            <div className="row mt-3">
              <div className="col-md-3">
                <div className="content-btn-add-response">
                  <button
                    className="btn btn-add-response"
                    data-bs-toggle="modal"
                    data-bs-target="#AddTypeEtaModal"
                  >
                    <IoAddOutline />
                    <span className="ps-2">Ajouter</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="questions-reponses-component mb-3 bg-white p-3">
        <div className="container-questions-reponses">
          <div className="mb-3 py-3">
            <div className="param-titre-preference mb-3">Tags</div>
            <div className="row">
              {types.map((type) => (
                <PrefItem item={type} key={type.id} />
              ))}
            </div>
            <div className="row mt-3">
              <div className="col-md-3">
                <div className="content-btn-add-response">
                  <button
                    className="btn btn-add-response"
                    data-bs-toggle="modal"
                    data-bs-target="#AddPrefModal"
                  >
                    <IoAddOutline />
                    <span className="ps-2">Ajouter</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MajPref modalId="AddPrefModal" />
        <MajTypeEtablissement modalId={"AddTypeEtaModal"} />
      </div>
    </div>
  );
}

function TypeEtablissementItem({ item }: { item: TypeEtablissement }) {
  const modalId = `AddTypeEtaModal_${item.id}`;
  const onDelete = async () => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet type d’établissement?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      ...SWAL_DEFAULT_PROPS,
      preConfirm: async () => {
        await deleteTypeEtablissementAsync(item.id);
        Swal.fire({
          icon: "success",
          title: "type d’établissement supprimé avec succés",
          showConfirmButton: false,
          timer: 5000,
          ...SWAL_DEFAULT_PROPS,
        });
      },
    });
  };
  return (
    <div className="col-md-4 mb-3">
      <div className="reponse-item">
        <div>
          <p className="text-reponse-item">{item.title}</p>
        </div>
        <div className="d-flex gap-3">
          <div>
            <button
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              data-bs-toggle="modal"
              data-bs-target={`#${modalId}`}
            >
              <RiEdit2Fill />
            </button>
          </div>
          <div>
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
              onClick={onDelete}
            >
              <BsTrashFill />
            </button>
          </div>
        </div>
      </div>
      <MajTypeEtablissement modalId={modalId} item={item} />
    </div>
  );
}

export function SettingsApp({ app }: { app?: AppConfig }) {
  const { errors, data, setErrors, onChange, onSubmitForm, register } =
    useFormData<AppConfigFormData>(
      {
        start_hour: "",
        end_hour: "",
        block_enabled: false,
      },
      (Yup) => ({
        start_hour: Yup.string().required().label("Ouverture"),
        end_hour: Yup.string().required().label("Fermeture"),
      })
    );

  React.useEffect(() => {
    if (app) {
      onChange({
        start_hour: app.start_hour,
        end_hour: app.end_hour,
        block_enabled: app.block_enabled,
      });
    }
  }, [app]);
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(async (data: AppConfigFormData) => {
    setLoading(true);
    try {
      await majAppConfig(data);
      Swal.fire({
        title: "Succès",
        text: "Données modifié avec succès",
        icon: "success",
        ...SWAL_DEFAULT_PROPS,
      });
    } catch (ex) {
      setErrors({ start_hour: "Une erreur est survenue lors de l'ajout" });
      console.log("add error", ex);
    }
    setLoading(false);
  }, []);

  return (
    <div className="questions-reponses-component">
      <div className="container-questions-reponses">
        <div className="mb-3 py-3">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (data.block_enabled) {
                onSubmitForm(onSubmit);
              } else {
                onSubmit(data);
              }
            }}
          >
            <div className="row">
              <div className="col-md-12 mb-2">
                <label className="form-label form-label-modal-custom">
                  Activer le blocage par distance ?
                </label>
                <Switch
                  active={data.block_enabled}
                  onPress={() => {
                    console.log("ibL");
                    // @ts-ignore
                    onChange("block_enabled", !data.block_enabled);
                  }}
                />
              </div>
              {data?.block_enabled && (
                <div className="col-md-6 mb-2">
                  <div className="mb-3">
                    <div className="d-flex gap-3">
                      <div className="position-relative content-horaire-div">
                        <label
                          htmlFor="start_hour"
                          className="form-label form-label-modal-custom"
                        >
                          Debut
                        </label>
                        <input
                          type="time"
                          placeholder="Debut"
                          className="form-control form-control-modal-custom"
                          {...register("start_hour")}
                        />
                      </div>
                      <div className="position-relative content-horaire-div">
                        <label
                          htmlFor="end_hour"
                          className="form-label form-label-modal-custom"
                        >
                          Fin
                        </label>
                        <input
                          type="time"
                          placeholder="Fin"
                          className="form-control form-control-modal-custom"
                          {...register("end_hour")}
                        />
                      </div>
                    </div>
                    <FormError error={errors.end_hour || errors.start_hour} />
                  </div>
                </div>
              )}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <SubmitButton title="Envoyer" loading={loading} />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Hobbies;
